import React, { useEffect, useState } from 'react';
import './ReturnList.css';
import thousandSeparator from '../../utils/thousandSeparator';

function ReturnList({
  products, scrolled, amounts, setAmounts,
}) {
  const [prevInputValue, setPrevInputValue] = useState('');

  useEffect(() => {
    const prodAmounts = {};
    products.forEach(async (prod) => {
      prodAmounts[prod.product_id] = prod.amount;
    });
    setAmounts(prodAmounts);
  }, [products, setAmounts]);

  const handleChange = (e) => {
    const {
      value, min, max,
    } = e.target;
    let finalValue = value;
    if (!Number(prevInputValue) && value.length > 1) {
      [finalValue] = value;
      if (finalValue === '0') {
        // eslint-disable-next-line prefer-destructuring
        finalValue = value[1];
      }
    }
    setPrevInputValue(value);
    const amount = Math.max(Number(min), Math.min(Number(max), Number(finalValue)));
    setAmounts({ ...amounts, [e.target.id]: amount.toString() });
  };

  const createRows = () => products.map((p) => {
    const amount = amounts[p.product_id];
    return (
      [
        <div>
          <div className="info">
            <p>{p.Product.name}</p>
          </div>
          <div className="amount-cell-cart">
            <div>
              <input className="amount-input" type="number" min="0" max={p.amount} id={p.product_id} value={amount} onChange={(e) => handleChange(e)} />
            </div>
          </div>
          <div className="price">
            <p>
              $
              {thousandSeparator(p.total_unit_price * amounts[p.Product.id], '.')}
            </p>
          </div>
        </div>,
      ]
    );
  });

  return (
    <div className="return-list-table">
      <div className={scrolled ? 'fixed' : 'scrollable'}>
        <div className="info no-border">
          <p>Producto</p>
        </div>
        <div className="amount-cell-cart no-border">
          <p>Cantidad</p>
        </div>
        <div className="price no-border">
          <p>Precio Total</p>
        </div>
      </div>
      <hr className={scrolled ? 'fixed-cart-return-hr' : 'scrollable-cart-hr'} />
      {createRows()}
    </div>
  );
}

export default ReturnList;
