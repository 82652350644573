/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  SET_ORDER_ID,
} from '../actions/deliveryActions';

const initialState = {
  orderId: null,
};

const deliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_ID: {
      const { orderId } = action.payload;

      return produce(state, (draft) => {
        draft.orderId = orderId;
      });
    }
    default: {
      return state;
    }
  }
};

export default deliveryReducer;
