import React, { useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

// import InfiniteScroll from 'react-infinite-scroll-component';

import {
  BUY_TYPE_NO_SOCIO,
  BUY_TYPE_SOCIO,
  BUY_TYPE_RAPPI,
  BUY_TYPE_CORNERSHOP,
  BUY_TYPE_UBEREATS,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_RAPPI_TURBO,
  BUY_TYPE_GOODMEAL,
  BUY_TYPE_RAPPI_PAYLESS,
} from '../../utils/const';

import thousandSeparator from '../../utils/thousandSeparator';
import { addToCart } from '../../actions/customerActions';
import { getPrice, getSalePrices } from '../../utils/getPrice';
import ProductItem from './ProductItem';
import './ProductList.css';
import track from '../../utils/analytics';
import getStock from '../../utils/getStock';

function ProductList({
  products, scrolled, enqueueSnackbar, closeSnackbar, getMoreProducts, loading, hasMore,
}) {
  const dispatch = useDispatch();
  const [amounts, setAmounts] = useState({});
  const store = useSelector((state) => state.account.user.Store);

  const {
    cart,
    type: customerType,
    user,
    recommended_product: recommendedProductId,
  } = useSelector((state) => state.customer);

  let priceList = store.price_list_id;

  if (customerType === BUY_TYPE_RAPPI) {
    priceList = store.rappi_price_list_id;
  } else if (customerType === BUY_TYPE_RAPPI_PAYLESS) {
    priceList = store.rappi_price_list_id;
  } else if (customerType === BUY_TYPE_RAPPI_TURBO) {
    priceList = store.rappi_turbo_price_list_id;
  } else if (customerType === BUY_TYPE_PEDIDOSYA) {
    priceList = store.pedidosya_price_list_id;
  } else if (customerType === BUY_TYPE_UBEREATS) {
    priceList = store.ubereats_price_list_id;
  } else if (customerType === BUY_TYPE_CORNERSHOP) {
    priceList = store.ubereats_price_list_id;
  } else if (customerType === BUY_TYPE_GOODMEAL) {
    priceList = store.goodmeal_price_list_id;
  }

  const addToProduct = (productId, amount) => {
    const newAmount = amounts[productId] ? amounts[productId] + amount : 2;
    setAmounts({ ...amounts, [productId]: newAmount });
  };

  const subtractFromProduct = (productId, amount) => {
    const newAmount = amounts[productId] ? amounts[productId] - amount : 1;
    setAmounts({ ...amounts, [productId]: newAmount });
  };

  const addProductToCart = (product, amount) => {
    // if (!checkStock(product, amount)) {
    if (!(getStock(product, cart) >= amount)) {
      const key = enqueueSnackbar(`${product.name} no tiene stock suficiente!`, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      // Get prices
      const prices = getSalePrices(product, store, customerType);
      // Get max quantities
      const maxQuantities = {
        clubGeneral: {
          min: 0,
          max: product.customer_limit_discount,
          disc: product.customer_discount,
        },
        bulk: {
          min: product.bulk_amount,
          max: 99999,
        },
        sellout: {
          min: 0,
          max: product.sellout.max_quantity,
        },
        recommended: {
          min: 0,
          max: product.max_recommended_quantity,
        },
        offers: {
          min: 0,
          max: 0,
        },
      };
      dispatch(addToCart(
        product,
        amount,
        prices,
        maxQuantities,
      ));
      setAmounts({ ...amounts, [product.id]: 1 });

      if (product.id === recommendedProductId) {
        // GA
        track.event('add_to_cart', {
          items: [{
            item_id: product.sku,
            item_name: product.name,
            creative_name: `Producto Recomendado ${product.sku}`,
            creative_slot: `Usuario ${user.rut}`,
            quantity: amount,
          }],
        });
      }

      const key = enqueueSnackbar(`${amount} ${product.name} en carro!`, {
        variant: 'success',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  };

  const getMoreProductsLoading = async () => {
    await getMoreProducts();
  };

  const goToCart = () => {
    window.location.href = '/carro';
  };

  const createRows = () => (
    <>
      {products.map((product) => {
        const amount = amounts[product.id] ? amounts[product.id] : 1;
        return (
          <ProductItem
            subtractFromProduct={subtractFromProduct}
            addProductToCart={addProductToCart}
            addToProduct={addToProduct}
            priceList={priceList}
            product={product}
            store={store}
            amount={amount}
            recommendedProduct={recommendedProductId}
            buyType={customerType}
            getStock={getStock}
          />
        );
      })}
      {hasMore ? (
        <div>
          <button
            type="button"
            onClick={getMoreProductsLoading}
            className="yellow-button"
            style={{ margin: '0 auto', marginTop: '15px' }}
            disabled={loading}
          >
            {loading ? 'Cargando...' : 'Cargar más productos'}
          </button>
        </div>
      ) : null}
    </>
  );

  const getTotalPrice = () => {
    let total = 0;
    Object.keys(cart).forEach((key) => {
      if (
        cart[key].product.sellout?.id
        && cart[key].amount <= cart[key].product.sellout?.stock
        && (customerType === BUY_TYPE_SOCIO || customerType === BUY_TYPE_NO_SOCIO)
      ) {
        if (
          recommendedProductId
          && recommendedProductId === cart[key].product.id
          && cart[key].product.recommended_price
        ) {
          total += cart[key].amount
            * Math.min(
              cart[key].product.sellout.price,
              getPrice(cart[key].product.prices, priceList),
              cart[key].product.recommended_price,
            );
        } else {
          total += cart[key].amount
            * Math.min(
              cart[key].product.sellout.price,
              getPrice(cart[key].product.prices, priceList),
            );
        }
      } else if (
        recommendedProductId
        && recommendedProductId === cart[key].product.id
        && cart[key].product.recommended_price
        && (customerType === BUY_TYPE_SOCIO || customerType === BUY_TYPE_NO_SOCIO)
      ) {
        total += cart[key].amount
          * Math.min(
            getPrice(cart[key].product.prices, priceList),
            cart[key].product.recommended_price,
          );
      } else {
        total += cart[key].amount * getPrice(cart[key].product.prices, priceList);
      }
    });
    return total;
  };

  return (
    <div className="product-list-table">
      <div className={scrolled ? 'fixed' : 'scrollable'}>
        <h3>Productos</h3>
        <h3>
          Total: $
          {thousandSeparator(getTotalPrice(), '.')}
        </h3>
        <button type="button" className="cart-icon" onClick={() => goToCart()}>
          <img src="img/cart.png" alt="carrito" />
          {Object.keys(cart).length > 0 ? <span>{Object.keys(cart).length}</span> : null}
        </button>
      </div>
      <hr className={scrolled ? 'fixed-hr' : 'scrollable-hr'} />
      {loading && (
        <p style={{ textAlign: 'center' }}>
          <b>Cargando...</b>
        </p>
      )}
      <div
        className="infinite-scroll"
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '300px',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        {createRows()}
      </div>
      {/* <InfiniteScroll
        initialScrollY={0}
        dataLength={products.length}
        className="infinite-scroll"
        next={getMoreProducts}
        hasMore={hasMore}
        height={300}
        endMessage={
          (
            <p style={{ textAlign: 'center' }}>
              {products.length === 0
                ? <b>No se encontraron productos</b> : <b>No hay más productos</b>}
            </p>
          )
        }
      >
        {createRows()}
      </InfiniteScroll> */}
    </div>
  );
}

export default ProductList;
