import {
  BUY_TYPE_CORNERSHOP,
  BUY_TYPE_GOODMEAL,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_RAPPI,
  BUY_TYPE_RAPPI_PAYLESS,
  BUY_TYPE_RAPPI_TURBO,
  BUY_TYPE_SOCIO,
  BUY_TYPE_UBEREATS,
} from './const';

const getSaleQuantities = (
  id,
  amount,
  prices,
  maxQuantities,
  prevQtyPurchased,
  buyType,
  bulk,
  applyBulk,
  recommendedProducts,
  recommendedProductPrice,
  priceLists,
  isDay,
) => {
  let newAmount = amount;
  // normal
  // club
  // bulk
  // sellout
  // recommended

  if (!prices) return {};

  const {
    rappiPriceList,
    rappiTurboPriceList,
    cornershopPriceList,
    ubereatsPriceList,
    pedidosyaPriceList,
    goodmealPriceList,
  } = priceLists;

  const recommendedProductIds = recommendedProducts.map((rp) => rp.id);

  const response = {
    normal: 0,
    clubGeneral: 0,
    club: 0,
    bulk: 0,
    sellout: 0,
    recommended: 0,
  };

  if (buyType === BUY_TYPE_RAPPI && rappiPriceList) {
    response.rappi = newAmount;
    return response;
  }

  if (buyType === BUY_TYPE_RAPPI_PAYLESS && rappiPriceList) {
    response.rappi = newAmount;
    return response;
  }

  if (buyType === BUY_TYPE_RAPPI_TURBO && rappiTurboPriceList) {
    response.rappiTurbo = newAmount;
    return response;
  }

  if (buyType === BUY_TYPE_CORNERSHOP && cornershopPriceList) {
    response.cornershop = newAmount;
    return response;
  }

  if (buyType === BUY_TYPE_UBEREATS && ubereatsPriceList) {
    response.ubereats = newAmount;
    return response;
  }

  if (buyType === BUY_TYPE_PEDIDOSYA && pedidosyaPriceList) {
    response.pedidosya = newAmount;
    return response;
  }

  if (buyType === BUY_TYPE_GOODMEAL && goodmealPriceList) {
    response.goodmeal = newAmount;
    return response;
  }

  // Lowest prices
  // recommended price is the lowest between normal, club, sellout and recommended and bulk
  // bulk price is the lowest between normal, club, sellout and bulk
  // sellout price is the lowest between normal, club and sellout
  // club is the lowest between normal and club

  // Check the max quantities
  Object.keys(prices).forEach((p) => {
    let argument = p;
    if (p === 'club') argument = 'clubGeneral';

    const maxQ = maxQuantities[argument];
    if (maxQ && buyType === BUY_TYPE_SOCIO) {
      if (p === 'bulk' && applyBulk && (bulk || newAmount >= maxQ.min)) {
        // Check if the amount is bigger than the max quantity
        response.bulk = maxQ.max !== null && newAmount > maxQ.max ? maxQ.max : newAmount;
        // Set the newAmount the difference between amount and max quantity or to zero
        newAmount = maxQ.max !== null && newAmount > maxQ.max ? newAmount - maxQ.max : 0;
      }
      if (p === 'recommended'
      && recommendedProductIds.includes(Number(id))
      && newAmount >= maxQ.min
      && prices[p].price
      && recommendedProductPrice
      && recommendedProductPrice < (prices.sellout.price || Infinity)) {
        // Check if the amount is bigger than the max quantity
        response.recommended = maxQ.max !== null && newAmount > maxQ.max ? maxQ.max : newAmount;
        // Set the newAmount the difference between amount and max quantity or to zero
        newAmount = maxQ.max !== null && newAmount > maxQ.max ? newAmount - maxQ.max : 0;
      }
      if (p === 'sellout' && newAmount >= maxQ.min && prices[p].price) {
        // Check if the amount is bigger than the max quantity
        response.sellout = maxQ.max !== null && newAmount > maxQ.max ? maxQ.max : newAmount;
        // Set the newAmount the difference between amount and max quantity or to zero
        newAmount = maxQ.max !== null && newAmount > maxQ.max ? newAmount - maxQ.max : 0;
      }
      if (p === 'club' && newAmount >= maxQ.min) {
        if (isDay) {
          let maxAmount = maxQ.max !== null ? maxQ.max - prevQtyPurchased : 0;
          if (maxAmount < 0) maxAmount = 0;
          // Check if the amount is bigger than the max quantity
          response.clubGeneral = maxQ.max !== null && newAmount > maxAmount ? maxAmount : newAmount;
          // Set the newAmount the difference between amount and max quantity or to zero
          newAmount = maxQ.max !== null && newAmount > maxAmount ? newAmount - maxAmount : 0;
        }
        // Set the rest of the amount to the club quantity
        response.club = newAmount;
        newAmount = 0;
      }
      if (p === 'normal') {
        response.normal = newAmount;
      }
    } else {
      if (p === 'bulk' && applyBulk && (bulk || newAmount >= maxQ.min)) {
        // Check if the amount is bigger than the max quantity
        response.bulk = maxQ.max !== null && newAmount > maxQ.max ? maxQ.max : newAmount;
        // Set the newAmount the difference between amount and max quantity or to zero
        newAmount = maxQ.max !== null && newAmount > maxQ.max ? newAmount - maxQ.max : 0;
      }
      if (p === 'sellout' && newAmount >= maxQ.min && prices[p].price) {
        // Check if the amount is bigger than the max quantity
        response.sellout = maxQ.max !== null && newAmount > maxQ.max ? maxQ.max : newAmount;
        // Set the newAmount the difference between amount and max quantity or to zero
        newAmount = maxQ.max !== null && newAmount > maxQ.max ? newAmount - maxQ.max : 0;
      }
      response.normal = newAmount;
    }
  });
  return response;
};

export default getSaleQuantities;
