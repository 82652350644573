import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import Page from '../../components/Layout/Page';
import PaymentSequence from '../../components/PaymentSequence';
import './FinalPay.css';

import {
  DOCUMENT_TYPE_FACTURA,
} from '../../utils/const';

function FinalPay() {
  const documentType = useSelector((state) => state.customer.document_type);
  const orderId = useSelector((state) => state.customer.order_id);
  const { machineNumber } = useSelector((state) => state.session);

  if (!machineNumber && !window.AppPagoSDK) {
    return (
      <Redirect to="maquina" />
    );
  }

  return (
    (!orderId) ? (<Redirect to="inicio" />)
      : (
        <Page
          title="Club Líquidos | POS Pago"
          progressStep="pago"
          backRef={documentType === DOCUMENT_TYPE_FACTURA ? '/factura' : '/carro'}
        >
          <div className="final-pay-container">
            <PaymentSequence />
          </div>
        </Page>
      )
  );
}

export default FinalPay;
