import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useDispatch } from 'react-redux';
import { setEANFromCamera } from '../../actions/sessionActions';

function SmartPOSCamera() {
  const dispatch = useDispatch();

  const handleCameraClick = () => {
    dispatch(setEANFromCamera(''));
    if (window.SmartPOS?.openCamera && window.AppPagoSDK) {
      window.SmartPOS.openCamera();
    }

    // In A920 PRO exist an sdk that can scan qr codes
    // if (window.HARDWARESDK) {
    //   window.HARDWARESDK.requestForScannerQR();
    // }
  };

  if (!window.SmartPOS?.openCamera || !window.AppPagoSDK) return null;

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre',
      }}
      onClick={() => handleCameraClick()}
    >
      <Typography style={{ fontSize: '14px', marginRight: '3px' }}>Código Barra</Typography>
      <CameraAltIcon />
    </Box>
  );
}

export default SmartPOSCamera;
