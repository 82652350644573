/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import axios from '../../utils/axios';
import thousandSeparator from '../../utils/thousandSeparator';
import { STATUS_APROBADO } from '../../utils/const';
import ChangePayMethod from './changeMethod/ChangePayMethod';
import CancelPay from './cancelPay/CancelPay';
import './Payment.css';

function WiringPayment({
  handlePayed, payed, total, payment, paids, step, payments, setPayments, totalSteps,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [depositCode, setDepositCode] = useState('');
  const [depositCodeCheck, setDepositCodeCheck] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (payed) {
      handlePayed(null);
    }
  }, [payed, handlePayed]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (depositCode !== depositCodeCheck) {
      const key = enqueueSnackbar('Los códigos no son iguales', {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }

    setLoading(true);

    const resp = await axios.patch(`/api/payments/deposit/${payment.id}`, {
      data: depositCode,
      status: STATUS_APROBADO,
    });

    if (resp.data.code.status === 200) {
      handlePayed(null);
    } else if (resp.data) {
      const { errorAction, errorMessage } = resp.data?.data || {};
      if (errorAction === 'reset') {
        handlePayed(errorMessage, errorAction);
      } else {
        handlePayed(errorMessage);
      }
    } else {
      handlePayed('Algo salió mal, intentelo nuevamente');
    }
  };

  return (
    <div>
      <h1>Pago Transferencia</h1>
      <h2>
        $
        {thousandSeparator(total, '.')}
      </h2>
      <div className="rappi-payment-inputs">
        <form onSubmit={handleSubmit} className="transbank-form">
          <input
            className="transbank-input"
            type="text"
            placeholder="Código Transferencia"
            value={depositCode}
            onChange={(e) => setDepositCode(e.target.value)}
            required
          />
          <input
            className="transbank-input"
            type="text"
            placeholder="Vuelva a ingresar el código"
            value={depositCodeCheck}
            onChange={(e) => setDepositCodeCheck(e.target.value)}
            required
          />
          <button disabled={loading} type="submit" className="pay-button">
            Continuar
          </button>
        </form>
        <div className="tabs-payment">
          <ChangePayMethod step={step} payments={payments} setPayments={setPayments} />
          <CancelPay paids={paids} />
        </div>
        <div className="tabs-payment">
          {`Paso ${step + 1} de ${totalSteps}`}
        </div>
      </div>
    </div>
  );
}

export default WiringPayment;
