import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@material-ui/core';
import { validate, clean } from 'rut.js';
import { setBillingInfo } from '../../actions/customerActions';
import axios from '../../utils/axios';
import Page from '../../components/Layout/Page';
import './Billing.css';

function Billing() {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const billingInfo = useSelector((state) => state.customer.billing) || {};
  const history = useHistory();

  const [rutLoading, setRutLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [inputValues, setInputValues] = useState({
    company: '',
    rut: '',
    activity: '',
    address: '',
    commune: '',
    city: '',
  });

  // useEffect(() => {
  //   setInputValues(billingInfo);
  // }, [billingInfo]);

  const billingSubmit = () => {
    let validInputs = true;
    Object.keys(inputValues).forEach((k) => {
      if (inputValues[k] === '') {
        validInputs = false;
      }
    });

    if (!validInputs) {
      setErrorMessage('Debe rellenar todos los campos');
    } else if (validate(inputValues.rut)) {
      const newInputValues = { ...inputValues };
      newInputValues.rut = clean(inputValues.rut);
      dispatch(setBillingInfo(newInputValues));
      setErrorMessage(null);
      history.push('/pago-final');
    } else {
      setErrorMessage('Debe ingresar un rut válido');
    }
  };

  const handleChange = (event) => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
  };

  const searchRut = async () => {
    const rut = clean(inputValues.rut);
    setRutLoading(true);
    const resp = await axios.get(`/api/billing/${rut}`);
    setRutLoading(false);

    const { success, data } = resp.data;

    if (!success) {
      const key = enqueueSnackbar('Ocurrió un error. Por favor complete los datos', {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }

    const { billing } = data;
    if (!billing) {
      const key = enqueueSnackbar('No existe el RUT. Por favor complete los datos', {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }

    setInputValues({
      ...inputValues,
      ...{
        company: billing.company ? billing.company : '',
        activity: billing.activity ? billing.activity : '',
        address: billing.address ? billing.address : '',
        commune: billing.commune ? billing.commune : '',
        city: billing.city ? billing.city : '',
      },
    });
  };

  const displayBilling = () => (
    <div className="personal-data">
      <h2>Datos de factura</h2>
      <div className="billing-data">
        <div className="billing-rut">
          <input
            type="text"
            name="rut"
            placeholder="RUT"
            value={inputValues.rut}
            onChange={(e) => handleChange(e)}
          />
          <button type="button" onClick={() => searchRut()}>
            {rutLoading ? (
              <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
            ) : (
              <img src="/img/search-icon.png" alt="Buscar" height="40" />
            )}
          </button>
        </div>
        <div>
          <input
            type="text"
            name="company"
            placeholder="Razón Social"
            value={inputValues.company}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div>
          <input
            type="text"
            name="activity"
            placeholder="Giro"
            value={inputValues.activity}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div>
          <input
            type="text"
            name="city"
            placeholder="Ciudad"
            value={inputValues.city}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div>
          <input
            type="text"
            name="commune"
            placeholder="Comuna"
            value={inputValues.commune}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div>
          <input
            type="text"
            name="address"
            placeholder="Dirección"
            value={inputValues.address}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Page
      title="Club Líquidos | Factura"
      progressStep="compra"
      backRef="/carro"
    >
      <div className="billing-container">
        {displayBilling()}
        <button type="button" className="yellow-button" onClick={billingSubmit}>
          Continuar
        </button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </Page>
  );
}

export default Billing;
