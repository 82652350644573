import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import { validate, clean, format } from 'rut.js';
import { Redirect } from 'react-router';
import Page from '../../components/Layout/Page';
import './Entry.css';
import axios from '../../utils/axios';
import {
  setCustomerData,
  resetCustomer,
  setCustomerType,
  setCoupon,
  setRecommendedProductsLoaded,
  resetRecommendedProduct,
} from '../../actions/customerActions';
import SplashScreen from '../../components/Loading/SplashScreen';
import {
  BUY_TYPE_SOCIO,
  BUY_TYPE_RAPPI,
  BUY_TYPE_CORNERSHOP,
  BUY_TYPE_UBEREATS,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_RAPPI_PAYLESS,
  BUY_TYPE_RAPPI_TURBO,
  BUY_TYPE_GOODMEAL,
  STATUS_BILLING_PROCESSING,
} from '../../utils/const';

function Entry() {
  const dispatch = useDispatch();
  const [rut, setRut] = useState('');
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [member, setMember] = useState(false);
  const [validRut, setValidRut] = useState(false);
  const [rutCustomer, setRutCustomer] = useState(null);
  const customer = useSelector((state) => state.customer);
  const user = useSelector((state) => state.account.user);
  const [loading, setLoading] = useState(false);
  const { machineNumber } = useSelector((state) => state.session);

  useEffect(() => {
    if (customer.order_status !== STATUS_BILLING_PROCESSING) {
      dispatch(resetCustomer());
      dispatch(setCoupon(null));
    }
    dispatch(setRecommendedProductsLoaded(false));
    dispatch(resetRecommendedProduct());
  }, [dispatch]);

  const handleChange = (event) => {
    setRut(event.target.value);
  };

  const submitRut = async () => {
    let isMember = false;
    let rutCust = null;
    await setError('');
    await setLoading(true);
    if (validate(rut)) {
      await setValidRut(true);
      const response = await axios.get(`/api/customer-by-rut/${clean(rut)}?type=${customer.type}`);
      if (response.data.code === 200) {
        rutCust = response.data.data.customer;
        await setRutCustomer(rutCust);
        isMember = response.data.data.customer.subscription;
        await setMember(response.data.data.customer.subscription);
        if (!response.data.data.customer.subscription) {
          await setError('Cliente no es socio');
        }
      } else {
        await setError('Cliente no es socio');
        await setMember(false);
      }
    } else {
      await setError('Rut inválido');
      await setMember(false);
      await setValidRut(false);
    }

    // Check if is member or not
    if (customer.type === 'no-socio') {
      setOpen(true);
    } else if (customer.type === 'socio' && isMember) {
      dispatch(setCustomerData(rutCust || { rut }));
      dispatch(setCustomerType('socio'));
      setLoading(false);
      window.location.href = '/perfil';
    }
    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !loading) {
      submitRut();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEntry = async (memberEntry) => {
    setLoading(true);
    let entryCustomer = rutCustomer;
    if (!rutCustomer) {
      // Create customer
      const customerResponse = await axios.post('/api/customers/new', { rut: clean(rut), registerAdmin: user.id });
      entryCustomer = customerResponse.data.data.customer;
    }

    dispatch(setCustomerData(entryCustomer || { rut }));
    dispatch(setCustomerType(memberEntry ? 'socio' : 'no-socio'));
    setLoading(false);
    if (memberEntry) {
      window.location.href = '/perfil';
    } else {
      window.location.href = '/compra';
    }
  };

  const popoverContent = () => {
    if (member) {
      return (
        <div className="entry-popover-container">
          <div className="popover-info-message">
            <p>Ingresará como socio</p>
            <p>{`RUT ${format(rut)}`}</p>
          </div>
          <div className="entry-confirmation-buttons">
            <button disabled={loading} className="accept-entry-button" type="submit" onClick={() => handleEntry(member)}>Aceptar</button>
            {/* <button disabled={loading} className="notmember-entry-button"
            type="submit" onClick={() => { handleEntry(false); }}>No Socio</button> */}
          </div>
        </div>
      );
    } if (validRut) {
      return (
        <div className="entry-popover-container">
          <div className="popover-info-message">
            <p>Ingresará como no socio</p>
            <p>{`RUT ${format(rut)} no pertenece al club`}</p>
          </div>
          <div className="entry-confirmation-buttons">
            <button disabled={loading} className="accept-entry-button" type="submit" onClick={() => handleEntry(member)}>Aceptar</button>
            <button className="cancel-entry-button" type="submit" onClick={() => handleClose()}>Cancelar</button>
          </div>
        </div>
      );
    }

    return (
      <div className="entry-popover-container">
        <div className="popover-info-message">
          <p>Ingresará como no socio</p>
          <p>{`RUT ${format(rut)} no existe`}</p>
        </div>
        <div className="entry-confirmation-buttons">
          <button disabled={loading} className="accept-entry-button" type="submit" onClick={() => handleEntry(member)}>Aceptar</button>
          <button className="cancel-entry-button" type="submit" onClick={() => handleClose()}>Cancelar</button>
        </div>
      </div>
    );
  };

  if (!customer.type) {
    window.location.href = '/inicio';
    return <SplashScreen />;
  }

  // if (customer.type === BUY_TYPE_RAPPI_PAYLESS) {
  //   window.location.href = '/rappi-payless';
  //   return <SplashScreen />;
  // }

  if (customer.type === BUY_TYPE_RAPPI
    || customer.type === BUY_TYPE_RAPPI_TURBO
    || customer.type === BUY_TYPE_RAPPI_PAYLESS
    || customer.type === BUY_TYPE_UBEREATS
    || customer.type === BUY_TYPE_CORNERSHOP
    || customer.type === BUY_TYPE_PEDIDOSYA
    || customer.type === BUY_TYPE_GOODMEAL) {
    window.location.href = '/compra';
    return <SplashScreen />;
  }

  if (!machineNumber && !window.AppPagoSDK) {
    return (
      <Redirect to="maquina" />
    );
  }

  if (customer.order_id
    && (customer.order_status === STATUS_BILLING_PROCESSING)) {
    return (<Redirect to="pago-final" />);
  }

  return (
    <Page
      title="Club Líquidos | POS Ingreso"
      progressStep="ingreso"
      backRef="/inicio"
    >
      <div className="entry-container">
        <img src="img/logo-liquidos-blanco.png" alt="logo" />
        <h1>Ingrese su Rut</h1>
        <div className="form-field">
          <label htmlFor="rut">
            Rut
            <input id="rut" type="text" name="rut" value={rut} onChange={(e) => handleChange(e)} onKeyDown={handleKeyDown} />
          </label>
        </div>
        <button disabled={loading} type="submit" className="yellow-button" onClick={() => submitRut()}>
          Ingresar
        </button>
        <Popover
          open={open}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        >
          {popoverContent()}
        </Popover>
        <p
          className="error-message"
          style={{ visibility: (customer.type === BUY_TYPE_SOCIO && (error !== '')) ? 'visible' : 'hidden' }}
        >
          {error}
        </p>
        <a
          className="back-link"
          href="/inicio"
          style={{ visibility: (customer.type === BUY_TYPE_SOCIO && (error !== '')) ? 'visible' : 'hidden' }}
        >
          Volver
        </a>
      </div>
    </Page>
  );
}

export default Entry;
