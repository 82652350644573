import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import Page from '../../components/Layout/Page';

function Error404View() {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page
      title="404: No encontrado"
    >
      <div className="error-page-container">
        <Container maxWidth="lg">
          <Typography
            align="center"
            variant={mobileDevice ? 'h4' : 'h1'}
            color="textPrimary"
          >
            404: Página no encontrada
          </Typography>
          <Typography
            align="center"
            variant="subtitle2"
            color="textSecondary"
          >
            Quizás intentase ver algo prohibido o llegaste aquí por
            error, intenta navegar normalmente por la página
          </Typography>
          <Box
            mt={6}
            display="flex"
            justifyContent="center"
          >
            <img
              alt="En desarrollo"
              src="img/under-construction.png"
            />
          </Box>
          <Box
            mt={6}
            display="flex"
            justifyContent="center"
          >
            <Button
              component={RouterLink}
              to="/"
              variant="outlined"
            >
              Volver al inicio
            </Button>
          </Box>
        </Container>
      </div>
    </Page>
  );
}

export default Error404View;
