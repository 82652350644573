import React from 'react';
import Page from '../../components/Layout/Page';
import './SmartPOS.css';

function SmartPOSOptions() {
  // window.AppPagoSDK.requestForTotals()
  // window.AppPagoSDK.requestForRePrint()
  // window.AppPagoSDK.requestForCloseTerminal()
  // window.AppPagoSDK.requestForAnnulment(transactionNumber)
  // window.AppPagoSDK.requestForLoadKeys()
  // window.AppPagoSDK.requestForSales(amount)
  // window.AppPagoSDK.callPrinterWith(printerLines)
  return (
    <Page
      title="Club Líquidos | POS Perfil"
      progressStep="perfil"
      backRef="/ingreso"
    >
      <div className="smartpos-container">
        <h1>Opciones SmartPOS</h1>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="row-button-box">
            <button
              type="button"
              value="normal"
              className="yellow-button"
              onClick={() => window.AppPagoSDK.requestForTotals()}
            >
              Totales
            </button>
          </div>
          {/* <div className="row-button-box">
            <button
              type="button"
              value="normal"
              className="yellow-button"
              onClick={() => window.AppPagoSDK.requestForReprintLastVoucher()}
            >
              Re imprimir último voucher
            </button>
          </div> */}
          <div className="row-button-box">
            <button
              type="button"
              value="normal"
              className="yellow-button"
              onClick={() => window.AppPagoSDK.requestForCloseTerminal()}
            >
              Cierre
            </button>
          </div>
          {/* <div className="row-button-box">
            <button
              type="button"
              value="normal"
              className="yellow-button"
              onClick={() => window.AppPagoSDK.requestForAnnulment('19')}
            >
              Anular venta smartPOS
            </button>
          </div> */}
          <div className="row-button-box">
            <button
              type="button"
              value="normal"
              className="yellow-button"
              onClick={() => window.AppPagoSDK.requestForLoadKeys()}
            >
              Carga de llaves
            </button>
          </div>
          {/* <div className="row-button-box">
            <button
              className="yellow-button"
              style={{ backgroundColor: 'red' }}
              type="button"
              onClick={() => window.AppPagoSDK.requestForSales('10000')}
            >
              requestForSales
            </button>
          </div> */}
          {/* <div className="row-button-box">
            <button
              className="yellow-button"
              style={{ backgroundColor: 'red' }}
              type="button"
              onClick={() => {
                if (window.HARDWARESDK) {
                  const extraLines = [];
                  while (extraLines.length < 38) {
                    extraLines.push('1 12X CANADA DRY TONIC 6990 6990');
                  }
                  window.HARDWARESDK.requestForPrinter(
                    JSON.stringify([
                      'Boleta N° 1783',
                      'Pedido N° 6176639',
                      '--------------------------',
                      'Fecha: 2024-08-02 15:38:59',
                      '--------------------------',
                      'Productos:',
                      '--------------------------',
                      'CANT. ITEM. VALOR U. SUBTOTAL',
                      '1 12X CANADA DRY TONIC 6990 6990',
                      ...extraLines,
                      '--------------------------',
                      'Total: 6990',
                      '--------------------------',
                    ]),
                  );
                }
              }}
            >
              requestForPrinter
            </button>
          </div> */}
          {/* <div className="row-button-box">
            <button
              className="yellow-button"
              style={{ backgroundColor: 'red' }}
              type="button"
              onClick={() => {
                window.AppPagoSDK.callPrinterWith(JSON.stringify({
                  lines: [
                    {
                      content: `
                    ***************

                    TEXTO DE PRUEBA

                    ***************

                  `,
                      fontType: 1,
                      alignment: 0,
                      textStyle: 1,
                      fontSize: 1,
                    },
                  ],
                }));
              }}
            >
              callPrinterWith
            </button>
          </div> */}
        </div>
      </div>
    </Page>
  );
}

export default SmartPOSOptions;
