import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CircularProgress,
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import axios from '../../../utils/axios';
// import calculateCouponDiscount from './CartCouponRules';
// import { calculateGeneralDiscount } from '../../../utils/generalDiscount';
// import { calculateDiscounts } from '../../../utils/getDiscounts';

import {
  setCoupon,
  setCouponDiscount,
  // resetCoupon,
  // resetCouponDiscount,
  // updateCartProduct,
  // setGeneralDiscount,
  // setAllDiscounts,
} from '../../../actions/customerActions';

function CartCoupon({
  customer,
  coupon,
  cart,
  payments,
}) {
  // const recommendedProduct = useSelector((state) => state.customer.recommended_product);
  const storeId = useSelector((state) => state.account.user.store_id);
  // const store = useSelector((state) => state.account.user.Store);
  const customerData = useSelector((state) => state.customer);
  const { subtotal } = customerData;
  // const applyBulk = customer.apply_bulk;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponSuccess, setCouponSuccess] = useState(null);
  const [couponError, setCouponError] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const onCouponClick = () => {
    setOpen(true);
  };

  const handleCouponSubmit = async () => {
    if (!couponCode) {
      setCouponError('Por favor ingrese un código');
      setCouponSuccess('');
      return;
    }

    const customerId = (customer && customer.user) ? customer.user.id : null;
    const products = Object.keys(cart).map((item) => cart[item].product.id).join(',');
    const paymentMethod = Object.keys(payments).map((item) => payments[item].payment_type).join();

    try {
      setLoading(true);
      const response = await axios.get(
        '/api/coupon/validate', {
          params: {
            code: couponCode, customerId, products, storeId, paymentMethod,
          },
        },
      );

      const { data } = response;
      if (data && data.code === 200) {
        const couponData = data.data.coupon;
        const newCoupon = {
          coupon: couponData,
          products: data.data.products,
        };

        if (couponData.min_cart_amount > subtotal) {
          setCouponDiscount(0);
          setCouponError(
            `Cupón válido para compras igual o superiores a ${couponData.min_cart_amount}`,
          );
          setCouponSuccess('');
          return;
        }
        dispatch(setCoupon(newCoupon));
        // // Update coupon discount
        // const discount = calculateCouponDiscount(
        //   cart,
        //   newCoupon,
        //   bulk,
        //   applyBulk,
        //   buyType,
        //   recommendedProduct,
        //   priceList,
        //   bulkPriceList,
        //   clubPriceList,
        // );

        // dispatch(setCouponDiscount(discount.discount));
        // Object.keys(discount.cart).forEach((id) => {
        //   dispatch(updateCartProduct(discount.cart[id]));
        // });

        // console.log(discount.cart);

        // const newCart = await calculateDiscounts(
        //   dispatch,
        //   orders,
        //   cart,
        //   bulk,
        //   applyBulk,
        //   buyType,
        //   recommendedProduct,
        //   store,
        //   customer,
        //   payments,
        //   couponCode,
        // );

        // // Update general discount
        // const generalDiscount = calculateGeneralDiscount(
        //   orders,
        //   discount.cart,
        //   bulk,
        //   applyBulk,
        //   buyType,
        //   priceList,
        //   bulkPriceList,
        //   clubPriceList,
        // );

        // console.log(newCart);

        // dispatch(setAllDiscounts(newCart.all_discounts));
        // // dispatch(setGeneralDiscount(generalDiscount.discount));
        // Object.keys(newCart.cart).forEach((id) => {
        //   dispatch(updateCartProduct(newCart.cart[id]));
        // });

        setCouponSuccess('¡Cupon aplicado!');
        setCouponError('');
        setTimeout(() => setOpen(false), 1500);
      } else {
        setCouponError('Ha ocurrido un error');
        setCouponSuccess('');
        return;
      }
    } catch (err) {
      const status = err.response ? err.response.status : 500;
      const msg = err.response ? err.response.data.data.errorMessage : 'Ha ocurrido un error';
      if (status === 400 || status === 404) {
        setCouponError(msg);
        setCouponSuccess('');
      }
    } finally {
      setLoading(false);
    }
  };

  const removeCoupon = () => {
    dispatch(setCoupon(null));
  };

  // useEffect(() => {
  //   const cartProductIds = Object.keys(cart).map((item) => cart[item].product.id);
  //   if (coupon) {
  //     // Remove coupon if products allowed are removed
  //     if (!coupon.products.some((id) => cartProductIds.includes(id))) {
  //       dispatch(resetCoupon());
  //       dispatch(resetCouponDiscount());
  //       return;
  //     }
  //     // Update coupon discount
  //     const discount = calculateCouponDiscount(
  //       cart,
  //       coupon,
  //       bulk,
  //       applyBulk,
  //       buyType,
  //       recommendedProduct,
  //       priceList,
  //       bulkPriceList,
  //       clubPriceList,
  //     );

  //     dispatch(setCouponDiscount(discount.all_discounts));
  //     // Object.keys(discount.cart).forEach((id) => {
  //     //   dispatch(updateCartProduct(discount.cart[id]));
  //     // });

  //     const newCart = calculateDiscounts(
  //       orders,
  //       discount.cart,
  //       bulk,
  //       applyBulk,
  //       buyType,
  //       recommendedProduct,
  //       store,
  //       customer,
  //       payments,
  //     );

  //     // // Update general discount
  //     // const generalDiscount = calculateGeneralDiscount(
  //     //   orders,
  //     //   discount.cart,
  //     //   bulk,
  //     //   applyBulk,
  //     //   buyType,
  //     //   priceList,
  //     //   clubPriceList,
  //     //   bulkPriceList,
  //     // );

  //     dispatch(setAllDiscounts(newCart.all_discounts));
  //     // dispatch(setGeneralDiscount(generalDiscount.discount));
  //     Object.keys(newCart.cart).forEach((id) => {
  //       dispatch(updateCartProduct(newCart.cart[id]));
  //     });
  //   }
  // }, [...Object.keys(cart).map((id) => cart[id].amount)]);

  return (
    <>
      <div className="coupon-holder">
        <button
          type="button"
          className="transparent-button"
          onClick={(e) => onCouponClick(e)}
        >
          {coupon ? coupon.coupon.code : 'Agregar Cupón'}
        </button>
        {coupon ? (
          <button
            type="button"
            className="remove-coupon-button"
            onClick={() => removeCoupon()}
          >
            &times;
          </button>
        ) : null}
      </div>
      <Popover
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div className="cart-popover-container">
          <div className="popover-cart-info-message">
            <p>Ingresa el código del cupón: </p>
            <input
              id="coupon-code"
              type="text"
              placeholder=""
              className="coupon-input"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
          </div>
          <div className="cart-popup-messages">
            {couponSuccess && (
              <div className="cart-popup-success-messsage">
                <p>{couponSuccess}</p>
              </div>
            )}
            {couponError && (
              <div className="cart-popup-error-messsage">
                <p>{couponError}</p>
              </div>
            )}
          </div>
          <div className="cart-popup-confirmation-buttons">
            <button
              disabled={loading}
              className="accept-cart-button"
              type="submit"
              onClick={() => handleCouponSubmit()}
            >
              {loading ? (
                <CircularProgress color="black" size={15} />
              ) : 'Aceptar'}
            </button>
            <button
              className="cancel-cart-button"
              type="submit"
              onClick={() => handleClose()}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Popover>
    </>
  );
}

export default CartCoupon;
