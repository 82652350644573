import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import axios from '../../utils/axios';
import Page from '../../components/Layout/Page';
import './SavedOrders.css';
import SavedList from '../../components/Saved/SavedList';
import { STATUS_BILLING_PROCESSING } from '../../utils/const';

function SavedOrders() {
  const [scrolled, setScrolled] = useState(false);
  const [savedOrders, setSavedOrders] = useState([]);
  const user = useSelector((state) => state.account.user);
  const customer = useSelector((state) => state.customer);

  useEffect(() => {
    const getSavedOrders = async () => {
      try {
        const response = await axios.get(`/api/orders/saved/${user.id}`);
        setSavedOrders(response.data.data.savedOrders ? response.data.data.savedOrders : []);
      } catch (error) {
        setSavedOrders([]);
      }
    };

    getSavedOrders();
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > 50;
      if (scrollCheck !== scrolled) {
        setScrolled(scrollCheck);
      }
    });
  });

  if (customer.order_id
    && (customer.order_status === STATUS_BILLING_PROCESSING)) {
    return (<Redirect to="pago-final" />);
  }

  return (
    <Page
      title="Club Líquidos | POS Guardados"
      progressStep="none"
      backRef="/inicio"
    >
      <div className="saved-orders-container">
        <SavedList scrolled={scrolled} savedOrders={savedOrders} />
      </div>
    </Page>
  );
}

export default SavedOrders;
