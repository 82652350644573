import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import axios from '../../../utils/axios';
import './Cancel.css';
import { resetCustomer, setPaymentLoading } from '../../../actions/customerActions';

const CancelPay = ({ paids }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [error, setError] = useState(false);
  const devolutions = {};
  const orderId = useSelector((state) => state.customer.order_id);
  if (paids.length) {
    // eslint-disable-next-line no-return-assign
    paids.forEach((paid) => {
      if (!devolutions[paid.paymentType]) devolutions[paid.paymentType] = 0;
      devolutions[paid.paymentType] += paid.amount;
    });
  }

  const handleClose = () => {
    setCancel(false);
    setOpen(false);
  };

  const cancelClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    const cancelPay = async () => {
      try {
        await axios.put('/api/orders/reject', {
          order_id: orderId,
        });
      } catch (err) {
        setError(true);
      }
      window.location.href = '/inicio';
    };

    if (cancel) {
      cancelPay();
      dispatch(setPaymentLoading(false));
      dispatch(resetCustomer());
    }
  }, [cancel]);

  return (
    <>
      <button type="button" className="red-button" onClick={(e) => cancelClick(e)}>
        Cancelar
      </button>
      <Popover
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div className="cart-popover-container">
          <div className="popover-cart-info-message">
            <div className="content-cancel">
              <p>Al cancelar el pago, volverá a la pantalla anterior.</p>
              <p>Debera entregar la siguiente devolucion</p>
              {(devolutions) ? (
                <ul>
                  {Object.keys(devolutions)
                    .map((key) => (<li>{`$ ${key}: ${devolutions[key]}`}</li>))}
                </ul>
              ) : ''}
              {(error) ? (
                <p>Error al intentar cancelar el pago</p>
              ) : ''}
            </div>
          </div>
          <div className="cart-popup-confirmation-buttons">
            <button
              className="accept-cart-button"
              type="submit"
              onClick={() => setCancel(true)}
            >
              Aceptar
            </button>
            <button
              className="cancel-cart-button"
              type="submit"
              onClick={() => handleClose()}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default CancelPay;
