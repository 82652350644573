import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import Page from '../../components/Layout/Page';
import './Delivery.css';
import {
  setCustomerType,
} from '../../actions/customerActions';
import {
  BUY_TYPE_RAPPI_PAYLESS,
  BUY_TYPE_RAPPI,
  BUY_TYPE_UBEREATS,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_RAPPI_TURBO,
  BUY_TYPE_GOODMEAL,
  STATUS_BILLING_PROCESSING,
} from '../../utils/const';

function Delivery() {
  const dispatch = useDispatch();

  const customer = useSelector((state) => state.customer);
  const { user } = useSelector((state) => state.account);
  const {
    rappi_active: rappiActive,
    rappi_turbo_active: rappiTurboActive,
    rappi_payless_active: rappiPaylessActive,
    // cornershop_active: cornershopActive,
    ubereats_active: ubereatsActive,
    ubereats_active_integration: ubereatsActiveIntegration,
    pedidosya_active: pedidosyaActive,
    goodmeal_active: goodmealActive,
  } = user?.Store || {};

  const onClick = (input) => {
    dispatch(setCustomerType(input));
    if (input === BUY_TYPE_UBEREATS && ubereatsActiveIntegration) {
      window.location.href = `/orden-delivery/${input}`;
    } else {
      window.location.href = '/ingreso';
    }
  };

  if (customer.order_id
    && (customer.order_status === STATUS_BILLING_PROCESSING)) {
    return (<Redirect to="pago-final" />);
  }

  return (
    <Page
      title="Club Líquidos | POS Perfil"
      progressStep="perfil"
      backRef="/ingreso"
    >
      <div className="delivery-container">
        <h1>Seleccionar delivery</h1>
        <div className="delivery-type-buttons">
          {rappiPaylessActive ? (

            <div className="row-button-box">
              <button type="button" value="normal" className="yellow-button" onClick={() => onClick(BUY_TYPE_RAPPI_PAYLESS)}>
                Rappi Payless
              </button>
            </div>
          ) : null}
          {!rappiPaylessActive && rappiActive ? (
            <div className="row-button-box">
              <button type="button" value="normal" className="yellow-button" onClick={() => onClick(BUY_TYPE_RAPPI)}>
                Rappi
              </button>
            </div>
          ) : null}
          {rappiTurboActive ? (
            <div className="row-button-box">
              <button type="button" value="normal" className="yellow-button" onClick={() => onClick(BUY_TYPE_RAPPI_TURBO)}>
                Rappi Turbo
              </button>
            </div>
          ) : null}
          {/* {cornershopActive ? (
            <div className="row-button-box">
              <button
                type="button"
                value="normal"
                className="yellow-button"
                onClick={() => onClick(BUY_TYPE_CORNERSHOP)}
              >
                Cornershop
              </button>
            </div>
          ) : null} */}
          {ubereatsActive ? (
            <div className="row-button-box">
              <button type="button" value="normal" className="yellow-button" onClick={() => onClick(BUY_TYPE_UBEREATS)}>
                Uber Eats
              </button>
            </div>
          ) : null}
          {pedidosyaActive ? (
            <div className="row-button-box">
              <button type="button" value="normal" className="yellow-button" onClick={() => onClick(BUY_TYPE_PEDIDOSYA)}>
                Pedidos Ya
              </button>
            </div>
          ) : null}
          {goodmealActive ? (
            <div className="row-button-box">
              <button type="button" value="normal" className="yellow-button" onClick={() => onClick(BUY_TYPE_GOODMEAL)}>
                GoodMeal
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </Page>
  );
}

export default Delivery;
