/* eslint-disable no-plusplus */
import {
  COUPON_TYPE_DIFF,
  COUPON_TYPE_PERCENTAGE,
} from '../../../utils/const';

const getUnitPriceCoupon = (quantities, prices) => {
  const validCouponPriceLists = [
    'normal',
    'club',
    'clubGeneral',
    'bulk',
    'recommended',
    'sellout',
  ];
  let price = 0;

  Object.keys(quantities).forEach((q) => {
    if (quantities[q] && validCouponPriceLists.includes(q)) {
      const p = q === 'clubGeneral' ? 'normal' : q;
      price += (quantities[q] * prices[p].price);
    }
  });

  return price;
};

const calculateCouponDiscountNew = (
  cart,
  coupon,
) => {
  let discount = 0;
  const individualDiscount = {};
  const keys = Object.keys(cart);

  // Diff coupon
  if (coupon.coupon.coupon_type === COUPON_TYPE_DIFF) {
    discount = coupon.coupon.coupon_value;
    const allValidPrices = keys.reduce((a, b) => {
      const { quantities, prices } = cart[b];
      const price = getUnitPriceCoupon(quantities, prices);
      return a + price;
    }, 0);
    for (let i = 0; i < keys.length; i++) {
      const id = keys[i];
      const { quantities, prices } = cart[id];
      const price = getUnitPriceCoupon(quantities, prices);
      individualDiscount[id] = Math.round((price * discount) / allValidPrices);
    }
  }

  // Percentage coupon without products
  if (coupon.coupon.coupon_type === COUPON_TYPE_PERCENTAGE) {
    let totalDiscount = 0;

    for (let i = 0; i < keys.length; i++) {
      const id = keys[i];
      const { quantities, prices } = cart[id];
      const price = getUnitPriceCoupon(quantities, prices);
      const couponPrice = price * coupon.coupon.coupon_value_percentage;
      const couponDiscount = Math.round(couponPrice / 100);
      if (coupon.products.length === 0 || coupon.products.includes(parseInt(id, 10))) {
        individualDiscount[id] = couponDiscount;
        totalDiscount += couponDiscount;
      } else {
        individualDiscount[id] = 0;
      }
    }

    if (coupon.coupon.max_discount) {
      if (totalDiscount > coupon.coupon.max_discount) {
        for (let i = 0; i < keys.length; i++) {
          const id = keys[i];
          const newDiscount = Math
            .round((individualDiscount[id] / totalDiscount) * coupon.coupon.max_discount);
          if (coupon.products.length === 0 || coupon.products.includes(parseInt(id, 10))) {
            individualDiscount[id] = newDiscount;
          } else {
            individualDiscount[id] = 0;
          }
        }

        return {
          totalDiscount: coupon.coupon.max_discount,
          individualDiscount,
        };
      }
    }

    return {
      totalDiscount,
      individualDiscount,
    };
  }

  return {
    totalDiscount: discount,
    individualDiscount,
  };
};

export default calculateCouponDiscountNew;
