import {
  BUY_TYPE_NO_SOCIO,
  BUY_TYPE_SOCIO,
  // BUY_TYPE_RAPPI,
  // BUY_TYPE_CORNERSHOP,
  // BUY_TYPE_UBEREATS,
  // BUY_TYPE_PEDIDOSYA,
} from './const';

const getPrice = (prices, list) => {
  const price = prices.filter((p) => p.price_list_id === list);
  if (price.length === 0) return null;
  return price[0].price;
};

// const getUnitPrice = (
//   item,
//   bulk,
//   buyType,
//   recommendedProduct,
//   priceList,
//   bulkPriceList,
//   clubPriceList,
//   rappiPriceList = null,
//   cornershopPriceList = null,
//   ubereatsPriceList = null,
//   pedidosyaPriceList = null,
// ) => {
//   if (buyType === BUY_TYPE_RAPPI && rappiPriceList) {
//     return getPrice(item.product.prices, rappiPriceList);
//   }

//   if (buyType === BUY_TYPE_CORNERSHOP && cornershopPriceList) {
//     return getPrice(item.product.prices, cornershopPriceList);
//   }

//   if (buyType === BUY_TYPE_UBEREATS && ubereatsPriceList) {
//     return getPrice(item.product.prices, ubereatsPriceList);
//   }

//   if (buyType === BUY_TYPE_PEDIDOSYA && pedidosyaPriceList) {
//     return getPrice(item.product.prices, pedidosyaPriceList);
//   }

//   const price = getPrice(item.product.prices, priceList);
//   const bulkPrice = getPrice(item.product.prices, bulkPriceList);
//   const clubPrice = getPrice(item.product.prices, clubPriceList);

//   if (buyType === BUY_TYPE_NO_SOCIO) {
//     if (bulk || item.amount >= item.product.bulk_amount) {
//       if (bulkPrice < price) {
//         return bulkPrice;
//       }

//       return Math.min(price, bulkPrice);
//     }

//     return price;
//   }

//   if (buyType === BUY_TYPE_SOCIO) {
//     if (bulk || item.amount >= item.product.bulk_amount) {
//       if (bulkPrice < Math.min(price, clubPrice)) {
//         return bulkPrice;
//       }

//       if (clubPrice < price) {
//         return clubPrice;
//       }

//       return price;
//     }

//     if (clubPrice < price) {
//       return clubPrice;
//     }

//     return price;
//   }

//   return null;
// };

// const getUnitBulkPrice = (
//   item,
//   bulk,
//   applyBulk,
//   buyType,
//   recommendedProduct,
//   priceList,
//   bulkPriceList,
//   clubPriceList,
//   rappiPriceList = null,
//   cornershopPriceList = null,
//   ubereatsPriceList = null,
//   pedidosyaPriceList = null,
// ) => {
//   if (buyType === BUY_TYPE_RAPPI && rappiPriceList) {
//     return getPrice(item.product.prices, rappiPriceList);
//   }

//   if (buyType === BUY_TYPE_CORNERSHOP && cornershopPriceList) {
//     return getPrice(item.product.prices, cornershopPriceList);
//   }

//   if (buyType === BUY_TYPE_UBEREATS && ubereatsPriceList) {
//     return getPrice(item.product.prices, ubereatsPriceList);
//   }

//   if (buyType === BUY_TYPE_PEDIDOSYA && pedidosyaPriceList) {
//     return getPrice(item.product.prices, pedidosyaPriceList);
//   }

//   let price = getPrice(item.product.prices, priceList);
//   const bulkPrice = getPrice(item.product.prices, bulkPriceList);
//   const clubPrice = getPrice(item.product.prices, clubPriceList);

//   if (item.product.sellout?.id && item.product.sellout.stock > 0) {
//     price = Math.min(item.product.sellout.price, price);
//   }

//   if (
//     recommendedProduct
//     && recommendedProduct === item.product.id
//     && item.product.recommended_price
//     && buyType === BUY_TYPE_SOCIO
//   ) {
//     price = Math.min(item.product.recommended_price, price);
//   }

//   if (buyType === BUY_TYPE_NO_SOCIO) {
//     if ((bulk || item.amount >= item.product.bulk_amount) && applyBulk) {
//       if (bulkPrice < price) {
//         return bulkPrice;
//       }

//       return Math.min(price, bulkPrice);
//     }

//     return price;
//   }
//   if (buyType === BUY_TYPE_SOCIO) {
//     if ((bulk || item.amount >= item.product.bulk_amount) && applyBulk) {
//       if (bulkPrice < Math.min(price, clubPrice)) {
//         return bulkPrice;
//       }

//       if (clubPrice < price) {
//         return clubPrice;
//       }

//       return price;
//     }

//     if (clubPrice < price) {
//       return clubPrice;
//     }

//     return price;
//   }

//   return null;
// };

// const getTotalPrice = (
//   item,
//   bulk,
//   applyBulk,
//   buyType,
//   recommendedProduct,
//   priceList,
//   bulkPriceList,
//   clubPriceList,
//   rappiPriceList = null,
//   cornershopPriceList = null,
//   ubereatsPriceList = null,
//   pedidosyaPriceList = null,
// ) => {
//   if (buyType === BUY_TYPE_RAPPI && rappiPriceList) {
//     return getPrice(item.product.prices, rappiPriceList) * item.amount;
//   }

//   if (buyType === BUY_TYPE_CORNERSHOP && cornershopPriceList) {
//     return getPrice(item.product.prices, cornershopPriceList) * item.amount;
//   }

//   if (buyType === BUY_TYPE_UBEREATS && ubereatsPriceList) {
//     return getPrice(item.product.prices, ubereatsPriceList) * item.amount;
//   }

//   if (buyType === BUY_TYPE_PEDIDOSYA && pedidosyaPriceList) {
//     return getPrice(item.product.prices, pedidosyaPriceList) * item.amount;
//   }

//   const price = getPrice(item.product.prices, priceList);
//   const bulkPrice = getPrice(item.product.prices, bulkPriceList);
//   const clubPrice = getPrice(item.product.prices, clubPriceList);

//   // Check for sellout
//   if (item.product?.sellout?.id && item.product?.sellout?.stock > 0) {
//     const selloutPrice = item.product.sellout.price;
//     if (
//       recommendedProduct
//       && recommendedProduct === item.product.id
//       && item.product.recommended_price
//       && buyType === BUY_TYPE_SOCIO
//     ) {
//       const recommendedPrice = item.product.recommended_price;

//       if ((bulk || item.amount >= item.product.bulk_amount) && applyBulk) {
//         if (bulkPrice < Math.min(price, selloutPrice, recommendedPrice)) {
//           return bulkPrice * item.amount;
//         }

//         return Math.min(price, selloutPrice, recommendedPrice) * item.amount;
//       }

//       return Math.min(price, selloutPrice, recommendedPrice) * item.amount;
//     }

//     if (buyType === BUY_TYPE_SOCIO) {
//       if ((bulk || item.amount >= item.product.bulk_amount) && applyBulk) {
//         if (bulkPrice < Math.min(price, clubPrice, selloutPrice)) {
//           return bulkPrice * item.amount;
//         }

//         if (selloutPrice < Math.min(price, clubPrice)) {
//           return selloutPrice * item.amount;
//         }

//         if (clubPrice < price) {
//           return clubPrice * item.amount;
//         }

//         return price * item.amount;
//       }

//       if (selloutPrice < Math.min(price, clubPrice)) {
//         return selloutPrice * item.amount;
//       }

//       if (clubPrice < price) {
//         return clubPrice * item.amount;
//       }

//       return price * item.amount;
//     }

//     if ((bulk || item.amount >= item.product.bulk_amount) && applyBulk) {
//       if (bulkPrice < Math.min(price, selloutPrice)) {
//         return bulkPrice * item.amount;
//       }

//       if (selloutPrice < price) {
//         return selloutPrice * item.amount;
//       }

//       return price * item.amount;
//     }

//     if (selloutPrice < price) {
//       return selloutPrice * item.amount;
//     }

//     return price * item.amount;
//   }

//   if (buyType === BUY_TYPE_NO_SOCIO) {
//     if ((bulk || item.amount >= item.product.bulk_amount) && applyBulk) {
//       if (bulkPrice < price) {
//         return bulkPrice * item.amount;
//       }

//       return Math.min(price, bulkPrice) * item.amount;
//     }

//     return price * item.amount;
//   }

//   if (buyType === BUY_TYPE_SOCIO) {
//     if (
//       recommendedProduct
//       && recommendedProduct === item.product.id
//       && item.product.recommended_price
//       && buyType === BUY_TYPE_SOCIO
//     ) {
//       const recommendedPrice = item.product.recommended_price;

//       if ((bulk || item.amount >= item.product.bulk_amount) && applyBulk) {
//         if (bulkPrice < Math.min(price, clubPrice, recommendedPrice)) {
//           return bulkPrice * item.amount;
//         }

//         return Math.min(price, clubPrice, recommendedPrice) * item.amount;
//       }

//       return Math.min(price, clubPrice, recommendedPrice) * item.amount;
//     }

//     if ((bulk || item.amount >= item.product.bulk_amount) && applyBulk) {
//       if (bulkPrice < Math.min(price, clubPrice)) {
//         return bulkPrice * item.amount;
//       }

//       if (clubPrice < price) {
//         return clubPrice * item.amount;
//       }

//       return price * item.amount;
//     }

//     if (clubPrice < price) {
//       return clubPrice * item.amount;
//     }

//     return price * item.amount;
//   }

//   return null;
// };

const getSalePrices = (p, s, buyType) => {
  const { price: normalPrice } = p.prices.find(
    (pr) => pr.price_list_id === s.price_list_id,
  ) || {};
  const { price: clubPrice } = p.prices.find(
    (pr) => pr.price_list_id === s.club_price_list_id,
  ) || {};
  const { price: bulkPrice } = p.prices.find(
    (pr) => pr.price_list_id === s.bulk_price_list_id,
  ) || {};
  const selloutPrice = p.sellout.price;
  const recommendedPrice = p.recommended_price;

  const { price: rappiPrice } = p.prices.find(
    (pr) => pr.price_list_id === s.rappi_price_list_id,
  ) || {};
  const { price: rappiTurboPrice } = p.prices.find(
    (pr) => pr.price_list_id === s.rappi_turbo_price_list_id,
  ) || {};
  const { price: cornershopPrice } = p.prices.find(
    (pr) => pr.price_list_id === s.ubereats_price_list_id,
  ) || {};
  const { price: ubereatsPrice } = p.prices.find(
    (pr) => pr.price_list_id === s.ubereats_price_list_id,
  ) || {};
  const { price: pedidosyaPrice } = p.prices.find(
    (pr) => pr.price_list_id === s.pedidosya_price_list_id,
  ) || {};
  const { price: goodmealPrice } = p.prices.find(
    (pr) => pr.price_list_id === s.goodmeal_price_list_id,
  ) || {};

  // Prices priorities
  // recommended price is the lowest between normal, club, sellout and recommended
  // bulk price is the lowest between normal, club, sellout and bulk
  // sellout price is the lowest between normal, club and sellout
  // club is the lowest between normal and club

  // THIS ORDER MUST BE SET IN THE RETURN.
  // If a new price is added, it must be in the correct place
  let finalBulkPrice = 0;

  if (buyType === BUY_TYPE_SOCIO) {
    finalBulkPrice = Math.min(
      normalPrice,
      clubPrice,
      selloutPrice || clubPrice,
      bulkPrice,
    );
  }

  // If buy type is no socio, bulk price is the lowest between normal, sellout and bulk
  if (buyType === BUY_TYPE_NO_SOCIO) {
    finalBulkPrice = Math.min(
      normalPrice,
      selloutPrice || normalPrice,
      bulkPrice,
    );
  }

  const finalRecommendedPrice = Math.min(
    normalPrice,
    clubPrice,
    selloutPrice || clubPrice,
    recommendedPrice || clubPrice,
  );

  const finalSelloutPrice = Math.min(normalPrice, clubPrice, selloutPrice);

  const finalClubPrice = Math.min(normalPrice, clubPrice);

  return {
    recommended: { price: finalRecommendedPrice },
    bulk: { price: finalBulkPrice },
    sellout: { price: finalSelloutPrice },
    club: { price: finalClubPrice },
    normal: { price: normalPrice },
    rappi: { price: rappiPrice },
    rappiTurbo: { price: rappiTurboPrice },
    cornershop: { price: cornershopPrice },
    ubereats: { price: ubereatsPrice },
    pedidosya: { price: pedidosyaPrice },
    goodmeal: { price: goodmealPrice },
  };
};

export {
  getPrice,
  // getUnitPrice,
  // getUnitBulkPrice,
  // getTotalPrice,
  getSalePrices,
};
