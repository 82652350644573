import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, Popover } from '@material-ui/core';
import Page from '../../../components/Layout/Page';

import '../ExpressDelivery.css';
import axios from '../../../utils/axios';
import { DELIVERY_STATUS_PREPARADO } from '../../../utils/const';

function DetailsOrder() {
  const { orderId } = useSelector((state) => state.delivery);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isPack, setIsPack] = useState(false);
  const [open, setOpen] = useState(false);
  const searchInput = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [finalMessage, setFinalMessage] = useState('');
  const [productsReady, setProductsReady] = useState([]);
  const [ready, setReady] = useState(false);
  const [count, setCount] = useState(1);
  const [packCount, setPackCount] = useState(0);
  const [packProducts, setPackProducts] = useState([]);
  const [orderLoading, setOrderLoading] = useState(true);
  const [orderError, setOrderError] = useState(null);
  const [order, setOrder] = useState(null);

  const getOrder = async () => {
    try {
      setOrderLoading(true);
      const response = await axios.get(`/api/orders/checkout/express/details/${orderId}`);
      const { success, data } = response.data;
      if (!success) {
        setOrderError('Error cargando pedido. Por favor recargar.');
        setOrderLoading(false);
        return;
      }
      setOrder(data.order);
      setProductsReady(data.order.OrderProducts.map(((o) => ({
        ean: o.Product.ean,
        ready: false,
      }))));
      setOrderLoading(false);
    } catch (err) {
      setOrderError('Error cargando pedido. Por favor recargar.');
      setOrderLoading(false);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const createBill = async () => {
    try {
      const response = await axios.post('/api/payments/bill/print', {
        order_id: orderId,
        format: 'impresa',
        email: null,
      });
      if (response.status === 200) {
        if (response.data.success) {
          return true;
        }
        return false;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  const deliveryReady = async () => {
    try {
      setFinalMessage('');
      setLoading(true);
      const response = await axios.put('/api/delivery/status', {
        order_id: order.id,
        delivery_status: DELIVERY_STATUS_PREPARADO,
      });
      if (response.data.data.order) {
        const printed = await createBill();
        if (printed) {
          setFinalMessage(`Orden ${order.order_number} lista para entregar`);
        } else {
          setFinalMessage(`Error al imprimir la boleta. Orden ${order.order_number} lista para entregar`);
        }
        window.location.href = '/entrega-inmediata';
      } else {
        setFinalMessage('Problema al intentar cambiar el estado del delivery');
      }
    } catch (err) {
      setFinalMessage('Problema al intentar cambiar el estado del delivery');
    } finally {
      setLoading(false);
    }
  };

  const getProductApi = async () => {
    try {
      const response = await axios.get(`/api/product-by-ean/${searchQuery}`);
      if (response.data.data.errorMessage) {
        setError(response.data.data.errorMessage.data.errorMessage);
        return null;
      }
      const { product } = response.data.data;
      setError('');
      return product;
    } catch (err) {
      selectedProduct.success = false;
      setError('Error desconocido');
      return null;
    }
  };

  const searchProduct = async () => {
    const product = await getProductApi();
    if (product && product.ean === selectedProduct.Product.ean
      && selectedProduct.amount === count) {
      const newProductsReady = [];
      productsReady.forEach((p) => {
        const newP = p;
        if (p.ean === product.ean) {
          newP.ready = true;
        }
        newProductsReady.push(newP);
      });
      selectedProduct.success = true;
      setCount(1);
      setProductsReady(newProductsReady);
      setOpen(false);
    } else {
      selectedProduct.success = false;
      if (!product) setError('Producto no encontrado');
      else if (product.ean !== selectedProduct.Product.ean) setError(`EAN INVALIDO: ${selectedProduct.Product.name}`);
      else if (selectedProduct.amount > count) setError(`ERROR: Hay ${selectedProduct.amount - count} productos faltantes`);
      else if (selectedProduct.amount < count) setError(`ERROR: Hay ${count - selectedProduct.amount} productos sobrantes`);
      else setError('Error desconocido');
    }
  };

  const searchPackProduct = async () => {
    for (let i = 0; i < packProducts.length; i += 1) {
      const packProduct = packProducts[i].ChildProduct;
      // eslint-disable-next-line no-await-in-loop
      const product = await getProductApi();
      const eans = packProduct.ean.split(',');
      if (product && eans.indexOf(product.ean) !== -1) {
        if (packCount > 1) {
          setPackCount(packCount - 1);
          setSearchQuery('');
        } else {
          const newProductsReady = [];
          productsReady.forEach((p) => {
            const newP = p;
            if (p.ean === selectedProduct.Product.ean) {
              newP.ready = true;
            }
            newProductsReady.push(newP);
          });
          selectedProduct.success = true;
          setProductsReady(newProductsReady);
          setOpen(false);
        }
      } else {
        selectedProduct.success = false;
        setError(`Ocurrió un error con ${selectedProduct.Product.name}`);
      }
    }
  };

  useEffect(() => {
    const someOneMissing = !productsReady.some((p) => p.ready === false);
    setReady(someOneMissing);
  }, [productsReady]);

  useEffect(() => {
    if (searchQuery && !isPack) searchProduct();
    else if (searchQuery && isPack) searchPackProduct();
  }, [searchQuery, count, packCount]);

  useEffect(() => {
    const getPackData = async () => {
      const flag = selectedProduct.Product.sku.toString().substr(0, 3) === '999';
      try {
        if (flag) {
          setIsPack(flag);
          const packResponse = await axios.get(`api/products/packs/children/${selectedProduct.Product.id}`);
          const { data } = packResponse.data;
          setPackCount(data.length);
          setPackProducts(data);
        } else {
          setIsPack(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (selectedProduct) getPackData();
  }, [selectedProduct]);

  useEffect(() => {
    if (open) {
      setError('');
      setSearchQuery('');
    }
  }, [open]);

  const increaseCount = () => {
    setCount(count + 1);
  };

  const decreaseCount = () => {
    setCount(count - 1);
  };

  const createRows = () => order.OrderProducts.map((product) => {
    const verifiedProduct = productsReady.find((p) => p.ean === product.Product.ean);
    return ([
      <div>
        <div className="order-number-cell">
          {product.Product.name}
        </div>
        <div className="order-number-cell">
          {product.Product.sku}
        </div>
        <div className="order-number-cell">
          {product.amount}
        </div>
        {((verifiedProduct && verifiedProduct.ready)
        || order.delivery_status === DELIVERY_STATUS_PREPARADO) ? (
          <button
            type="button"
            className="green-button"
            onClick={() => {}}
          >
            Verificado
          </button>
          ) : (
            <button
              type="button"
              className="yellow-button"
              onClick={() => {
                setSelectedProduct(product);
                setOpen(true);
              }}
            >
              Verificar
            </button>
          )}
      </div>,
      <hr />,
    ]);
  });

  return (
    <>
      {orderLoading ? (
        <Page
          title="Club Líquidos | POS"
          progressStep="compra"
          backRef="/entrega-inmediata"
        >
          <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
        </Page>
      ) : (
        <Page
          // title={`Club Líquidos | POS Pedido ${order.order_number}`}
          progressStep="compra"
          backRef="/entrega-inmediata"
        >
          {orderError ? (
            <div className="order-details-container">
              <p style={{ color: 'red', textAlign: 'center' }}>{`${orderError}`}</p>
              <div className="order-details-error-button">
                <button
                  type="button"
                  className="yellow-button"
                  onClick={() => getOrder()}
                >
                  Recargar
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="delivered-orders-table">
                <h3 className="yellow-title-delivered-orders">
                  Detalles orden nº
                  {' '}
                  {order.order_number}
                </h3>
                <div className="scrollable">
                  <div className="order-number-cell">Producto</div>
                  <div className="order-number-cell">SKU</div>
                  <div className="order-number-cell">Cantidad</div>
                  <div className="button-size-spacer" />
                </div>
                <hr className="scrollable-hr" />
                {createRows()}
                {(ready) ? (
                  <button
                    type="button"
                    className="deliver-button"
                    onClick={() => deliveryReady()}
                  >
                    Preparado
                  </button>
                ) : ''}
                {(loading) ? <CircularProgress /> : ''}
                <div>
                  <p>{finalMessage}</p>
                </div>
              </div>
              <Popover
                open={open}
                onClose={() => handleClose()}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
              >
                { (selectedProduct) ? (
                  <div className="cart-popover-container">
                    <div className="popover-cart-info-message">
                      { (isPack)
                        ? <p>Ingresa los códigos del pack </p>
                        : <p>Ingresa el código de producto </p> }
                      <div className="search-products-input">
                        <input
                          ref={searchInput}
                          type="text"
                          placeholder="EAN"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button
                          onClick={() => increaseCount()}
                          className="add-subtract"
                          type="button"
                        >
                          +
                        </button>
                        <div>{count}</div>
                        <button
                          onClick={() => decreaseCount()}
                          className="add-subtract"
                          type="button"
                        >
                          -
                        </button>
                      </div>
                      {(!error && isPack)
                        ? (
                          <p>
                            {' '}
                            Productos faltantes del pack:
                            {' '}
                            {packCount}
                          </p>
                        ) : ''}
                      {(error) ? (
                        <div className="error-message">
                          <p>Error al ingresar producto</p>
                          <p>{error}</p>
                        </div>
                      ) : (
                        <p>
                          Escanea el código de barras del producto
                        </p>
                      )}
                    </div>
                    <div className="cart-popup-confirmation-buttons">
                      <button
                        className="cancel-button"
                        type="submit"
                        onClick={() => handleClose()}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                ) : ''}
              </Popover>
            </>
          )}
        </Page>
      )}
    </>
  );
}

export default DetailsOrder;
