import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import customerReducer from './customerReducer';
import deliveryReducer from './deliveryReducer';
import sessionReducer from './sessionReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  customer: customerReducer,
  session: sessionReducer,
  delivery: deliveryReducer,
  form: formReducer,
});

export default rootReducer;
