import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  resetCustomerType,
  resetReturnType,
  resetCustomer,
  resetReturnCode,
  resetReturnProducts,
  resetDocumentType,
  resetPayments,
  resetApplyBulk,
  resetBulk,
  resetTotal,
  resetGeneralDiscount,
  resetCouponDiscount,
  resetCoupon,
  resetSubtotal,
  resetBillingInfo,
  resetCart,
  resetCustomerOrderId,
  markSmsSent,
  setCustomerPoints,
  resetRecommendedProduct,
  resetAllDiscounts,
  resetCartLoading,
  resetCustomerOrderNumber,
  resetReturnSmartPOSTransactionId,
  resetOrderStatus,
} from '../../actions/customerActions';

function PaymentError({ error, errorAction }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const goToHome = () => {
    dispatch(resetCustomerType());
    dispatch(resetCustomer());
    dispatch(resetReturnType());
    dispatch(resetReturnProducts());
    dispatch(resetReturnCode());
    dispatch(resetDocumentType());
    dispatch(resetPayments());
    dispatch(resetApplyBulk());
    dispatch(resetBulk());
    dispatch(resetTotal());
    dispatch(resetGeneralDiscount());
    dispatch(resetCouponDiscount());
    dispatch(resetCoupon());
    dispatch(resetSubtotal());
    dispatch(resetBillingInfo());
    dispatch(resetCart());
    dispatch(resetCustomerOrderId());
    dispatch(resetCustomerOrderNumber());
    dispatch(markSmsSent(false));
    dispatch(setCustomerPoints(0));
    dispatch(resetRecommendedProduct());
    dispatch(resetAllDiscounts());
    dispatch(resetCartLoading());
    dispatch(resetReturnSmartPOSTransactionId());
    dispatch(resetOrderStatus());

    history.push('/inicio');
  };

  return (
    <div className="final-payment-result">
      <h1>PAGO</h1>
      <p>{error}</p>
      <div className="final-payment-buttons">
        {errorAction !== 'reset' ? (
          <div className="tabs-payment">
            <button
              type="button"
              className="yellow-button"
              onClick={() => window.location.reload()}
            >
              Reintentar
            </button>
          </div>
        ) : null}
        <div className="tabs-payment">
          <button
            type="button"
            className="yellow-button"
            onClick={() => goToHome()}
          >
            Volver a inicio
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentError;
