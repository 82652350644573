export const SET_ORDER_ID = '@session/set-order-id';

export function setOrderId(orderId) {
  return (dispatch) => dispatch({
    type: SET_ORDER_ID,
    payload: {
      orderId,
    },
  });
}
