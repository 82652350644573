/* eslint-disable import/prefer-default-export */
/* eslint-disable no-plusplus */
// import { setCouponDiscount } from '../actions/customerActions';
import calculateCouponDiscountNew from '../views/Cart/coupon/CartCouponRulesNew';
import axios from './axios';
import { BUY_TYPE_SOCIO, TZ_AMERICA_SANTIAGO } from './const';
import getSaleQuantities from './getQuantities';
// import applyPromotion from './promotions';

const moment = require('moment-timezone');

const isDayOfDiscount = (product) => {
  if (!product.customer_days_discount) return false;
  const days = product.customer_days_discount.split(',');
  if (days.length !== 7) return false;
  const date = moment().tz(TZ_AMERICA_SANTIAGO);
  const hour = date.hour();
  let dow = date.day();
  if (hour < 4) {
    dow -= 1;
    if (dow === -1) {
      dow = 6;
    }
  }
  return (days[dow] === '1');
};

const getClubGeneralLimitAmount = (orders, item) => {
  // Get past amounts
  let pastAmount = 0;
  let returnAmount = 0;
  if (orders && orders.length > 0) {
    for (let j = 0; j < orders.length; j++) {
      const order = orders[j];
      const orderProducts = order.OrderProducts.find((p) => p.Product.id === item.product.id);
      const returnOrders = order.Returns
        .find((r) => r.ReturnProducts.find((p) => p.product_id === item.product.id));
      if (orderProducts) pastAmount += orderProducts.amount;
      if (returnOrders) {
        const returnProducts = returnOrders.ReturnProducts
          .find((p) => p.product_id === item.product.id);
        returnAmount += returnProducts.amount;
      }
    }
  }
  const limitAmount = pastAmount - returnAmount > 0
    ? pastAmount - returnAmount : 0;
  return limitAmount;
};

const calculateDiscounts = async (
  orders,
  cart,
  bulk,
  applyBulk,
  buyType,
  recommendedProducts,
  store,
  customer,
  payments,
  coupon,
) => {
  const priceLists = {
    priceList: store.price_list_id,
    bulkPriceList: store.bulk_price_list_id,
    clubPriceList: store.club_price_list_id,
    rappiPriceList: store.rappi_price_list_id,
    rappiTurboPriceList: store.rappi_turbo_price_list_id,
    cornershopPriceList: store.ubereats_price_list_id,
    ubereatsPriceList: store.ubereats_price_list_id,
    pedidosyaPriceList: store.pedidosya_price_list_id,
    goodmealPriceList: store.goodmeal_price_list_id,
  };

  const allDiscounts = {
    bulkDiscount: 0,
    clubDiscount: 0,
    clubGeneralDiscount: 0,
    selloutDiscount: 0,
    recommendedDiscount: 0,
    offersDiscount: 0,
    couponDiscount: 0,
    totalDiscount: 0,
  };

  const newCart = {};
  const keys = Object.keys(cart);

  for (let i = 0; i < keys.length; i++) {
    const id = keys[i];
    const item = cart[id];
    const {
      amount,
      prices,
      max_quantities: maxQuantities,
      product: { recommended_price: recommendedProductPrice },
    } = item;

    const previousQtyPurchased = getClubGeneralLimitAmount(orders, item);

    const isDay = item.product.customer_discount && isDayOfDiscount(item.product);

    const quantities = getSaleQuantities(
      id,
      amount,
      prices,
      maxQuantities,
      previousQtyPurchased,
      buyType,
      bulk,
      applyBulk,
      recommendedProducts,
      recommendedProductPrice,
      priceLists,
      isDay,
    );

    const externalApps = [
      'rappi',
      'rappiTurbo',
      'cornershop',
      'ubereats',
      'pedidosya',
      'goodmeal',
    ];
    let externalAppCheck = false;
    externalApps.forEach((ea) => {
      if (quantities[ea]) externalAppCheck = ea;
    });

    const getSaleDiscounts = (pri, qty, day) => {
      // Descuento Precio Socio
      // Descuento May.
      // Descuento Rec.
      // Subtotal Descuentos
      // Descuento Ofertas
      // Descuento Liq.
      // Descuento Socio (15%)
      // Cupon
      // Total Descuentos
      // % Dscto
      if (!pri || !qty) return {};

      const response = {
        club: 0,
        bulk: 0,
        recommended: 0,
        discountSubtotal: 0,
        sellout: 0,
        clubGeneral: 0,
        coupon: 0,
        offers: 0,
        discountTotal: 0,
        percentageDiscount: 0,
      };

      if (externalAppCheck) {
        return response;
      }

      Object.keys(qty).forEach((q) => {
        if (pri.normal.price - pri[q]?.price < 0) {
          response[q] = 0;
        } else {
          const disc = qty[q] * (pri.normal.price - pri[q]?.price);
          response[q] = disc || 0;
        }
      });

      response.discountSubtotal = response.club + response.bulk + response.recommended;

      if (day && buyType === BUY_TYPE_SOCIO && store.customer_discount) {
        if (amount - quantities.sellout < 0) {
          response.clubGeneral = 0;
        } else {
          let maxClubAmount = maxQuantities.clubGeneral.max - previousQtyPurchased;
          if (maxClubAmount < 0) maxClubAmount = 0;
          const discountedPrice = Math.round(prices.normal.price * maxQuantities.clubGeneral.disc);
          response.clubGeneral = Math.round(
            (
              Math.min(amount - quantities.sellout, maxClubAmount)
              / (amount - quantities.sellout)
            )
            * ((amount - quantities.sellout) * discountedPrice - response.discountSubtotal),
            // * ((amount - quantities.sellout) * prices.normal.price - response.discountSubtotal)
            // * maxQuantities.clubGeneral.disc,
          );
          if (response.clubGeneral < 0) response.clubGeneral = 0;
        }
      }

      if (Number.isNaN(response.clubGeneral)) response.clubGeneral = 0;

      response.discountTotal = response.discountSubtotal
        + response.sellout
        + response.clubGeneral;

      response.percentageDiscount = response.discountTotal / (amount * prices.normal.price);

      return response;
    };

    const discounts = getSaleDiscounts(prices, quantities, isDay);

    let itemSubtotal = prices.normal.price * amount;
    if (externalAppCheck) {
      itemSubtotal = prices[externalAppCheck].price * amount;
    }

    const finalTotal = itemSubtotal - discounts.discountTotal;

    newCart[id] = {
      product: item.product,
      amount: item.amount,
      prices,
      max_quantities: maxQuantities,
      quantities,
      discounts,
      subtotal_amount: itemSubtotal,
      total_amount: finalTotal,
    };
    allDiscounts.bulkDiscount += discounts.bulk;
    allDiscounts.clubDiscount += discounts.club;
    allDiscounts.clubGeneralDiscount += discounts.clubGeneral;
    allDiscounts.selloutDiscount += discounts.sellout;
    allDiscounts.recommendedDiscount += discounts.recommended;
    allDiscounts.totalDiscount += discounts.discountTotal;
  }

  // Get coupon discounts
  let couponDiscount = 0;
  if (coupon) {
    const couponDiscounts = calculateCouponDiscountNew(
      newCart,
      coupon,
    );
    couponDiscount = couponDiscounts.totalDiscount;
    Object.keys(couponDiscounts.individualDiscount).forEach((key) => {
      if (couponDiscounts.individualDiscount[key]) {
        newCart[key].discounts.coupon = couponDiscounts.individualDiscount[key];
      } else {
        newCart[key].discounts.coupon = 0;
      }

      // Check if there are clubGeneral quantities to recalculate the clubGeneral discount
      // with the coupon discount
      if (newCart[key].quantities.clubGeneral) {
        // Discount the previous general discount
        newCart[key].total_amount += newCart[key].discounts.discountTotal;
        allDiscounts.totalDiscount -= newCart[key].discounts.discountTotal;
        newCart[key].discounts.discountTotal -= newCart[key].discounts.clubGeneral;
        allDiscounts.clubGeneralDiscount -= newCart[key].discounts.clubGeneral;

        // Set the new general discount
        const previousQtyPurchased = getClubGeneralLimitAmount(orders, newCart[key]);
        let maxClubAmount = newCart[key].max_quantities.clubGeneral.max - previousQtyPurchased;
        if (maxClubAmount < 0) maxClubAmount = 0;
        newCart[key].discounts.clubGeneral = Math.round(
          (
            Math.min(newCart[key].amount - newCart[key].quantities.sellout, maxClubAmount)
            / (newCart[key].amount - newCart[key].quantities.sellout)
          )
          * (
            (newCart[key].amount - newCart[key].quantities.sellout)
            * newCart[key].prices.normal.price
            - (newCart[key].discounts.discountSubtotal + newCart[key].discounts.coupon)
          ) * newCart[key].max_quantities.clubGeneral.disc,
        );
        if (newCart[key].discounts.clubGeneral < 0) newCart[key].discounts.clubGeneral = 0;

        newCart[key].discounts.discountTotal += newCart[key].discounts.clubGeneral;

        const finalDiscountTotal = (newCart[key].total_amount
          - newCart[key].discounts.discountTotal) < 0
          ? newCart[key].total_amount
          : newCart[key].discounts.discountTotal;

        newCart[key].total_amount -= finalDiscountTotal;
        allDiscounts.clubGeneralDiscount += newCart[key].discounts.clubGeneral;
        allDiscounts.totalDiscount += newCart[key].discounts.discountTotal;

        newCart[key].discounts.percentageDiscount = newCart[key].discounts.discountTotal
          / (newCart[key].amount * newCart[key].prices.normal.price);
      }

      const finalCouponDiscount = newCart[key].total_amount - newCart[key].discounts.coupon < 0
        ? newCart[key].total_amount
        : newCart[key].discounts.coupon;
      newCart[key].total_amount -= finalCouponDiscount;
      newCart[key].discounts.discountTotal += newCart[key].discounts.coupon;
    });
  }

  allDiscounts.couponDiscount = couponDiscount;
  allDiscounts.totalDiscount += allDiscounts.couponDiscount;

  // Get offers/promotions discount
  // let allAmounts = 0;
  const getPromotions = async () => {
    try {
      const fixedCart = Object.keys(newCart).map((key) => {
        const amount = newCart[key].quantities.normal
          + newCart[key].quantities.club
          + newCart[key].quantities.clubGeneral
          + newCart[key].quantities.bulk
          + newCart[key].quantities.sellout
          + newCart[key].quantities.recommended;

        // allAmounts += amount;

        return {
          product: newCart[key].product,
          amount,
        };
      });
      const response = await axios.post('/api/products/promotions',
        {
          store_id: store.id,
          cart: fixedCart,
          userId: customer?.user?.id || false,
          applyBulk,
          store,
          payments,
        });
      return response?.data?.data?.data?.data || [];
    } catch (err) {
      console.log(err.message);
      return [];
    }
  };

  const promotions = await getPromotions();

  // Offers discount
  if (promotions.length) {
    // Iterate over products
    Object.keys(newCart).forEach((key) => {
      // Get all promotion discounts for this product
      const promotionDiscount = promotions.reduce((t, c) => {
        // Get current product's promotion discount
        const pDiscountArray = c.products.filter((p) => p.id === Number(key));
        if (pDiscountArray.length > 0) {
          const pDiscount = pDiscountArray[0];
          const d = pDiscount.discount || 0;
          return Math.max(t, d);
        }
        return t;
      }, 0);

      // TODO: Should check if promotion applies for each quantity (club, bulk, etc)
      if (promotionDiscount >= newCart[key].discounts.discountTotal) {
        // Reset all discounts for this product
        allDiscounts.clubDiscount -= newCart[key].discounts.club;
        allDiscounts.clubGeneralDiscount -= newCart[key].discounts.clubGeneral;
        allDiscounts.bulkDiscount -= newCart[key].discounts.bulk;
        allDiscounts.recommendedDiscount -= newCart[key].discounts.recommended;
        allDiscounts.selloutDiscount -= newCart[key].discounts.sellout;

        allDiscounts.totalDiscount -= (newCart[key].discounts.club
          + newCart[key].discounts.clubGeneral
          + newCart[key].discounts.recommended
          + newCart[key].discounts.sellout
          + newCart[key].discounts.bulk);

        newCart[key].total_amount += (newCart[key].discounts.club
          + newCart[key].discounts.clubGeneral
          + newCart[key].discounts.recommended
          + newCart[key].discounts.sellout
          + newCart[key].discounts.bulk);

        newCart[key].discounts.discountTotal = newCart[key].discounts.coupon;
        newCart[key].discounts.club = 0;
        newCart[key].discounts.bulk = 0;
        newCart[key].discounts.recommended = 0;
        newCart[key].discounts.discountSubtotal = 0;
        newCart[key].discounts.sellout = 0;
        newCart[key].discounts.clubGeneral = 0;

        // Check coupon
        if (coupon) {
          // If coupon is type %, reset coupon discount
          // TODO: Should check if coupon applies with other promotions
          if (coupon.coupon.coupon_type === 'percentage') {
            newCart[key].total_amount += newCart[key].discounts.coupon;
            newCart[key].discounts.discountTotal -= newCart[key].discounts.coupon;
            allDiscounts.couponDiscount -= newCart[key].discounts.coupon;
            allDiscounts.totalDiscount -= newCart[key].discounts.coupon;
            newCart[key].discounts.coupon = 0;
          }
        }

        const finalPromotionDiscount = newCart[key].total_amount - promotionDiscount < 0
          ? newCart[key].total_amount
          : promotionDiscount;

        newCart[key].total_amount -= finalPromotionDiscount;

        newCart[key].discounts.offers = finalPromotionDiscount;
        newCart[key].discounts.discountTotal += finalPromotionDiscount;
        allDiscounts.offersDiscount += finalPromotionDiscount;
        allDiscounts.totalDiscount += finalPromotionDiscount;

        // Reset percentage discount
        newCart[key].discounts.percentageDiscount = newCart[key].discounts.discountTotal
          / (newCart[key].amount * newCart[key].prices.normal.price);
      }
    });

    // let allOffersDiscounts = 0;
    // eslint-disable-next-line max-len
    // // First check if the total discount of the promotions are higher than the current totalDiscount
    // promotions.forEach((p) => {
    //   console.log('p: ', p);
    //   allOffersDiscounts += p.discount_total;
    // });

    // if (allDiscounts.totalDiscount <= allOffersDiscounts) {
    //   promotions.forEach((p) => {
    //     // Set all discount
    //     allDiscounts.offersDiscount += p.discount_total;

    //     // Get product amounts
    //     let allPromotionAmount = 0;
    //     p.products.forEach((prod) => {
    //       allPromotionAmount += newCart[prod.id].amount;
    //     });

    //     // Set individual discount
    //     if (p.discount_all_cart) {
    //       Object.keys(newCart).forEach((key) => {
    //         if (p.discount_type === '$') {
    //           // Reset total amount
    //           newCart[key].total_amount += newCart[key].discounts.discount;

    //           const offerDiscount = Math.round(
    //             (p.discount_total * newCart[key].amount) / allAmounts,
    //           );
    //           newCart[key].total_amount -= offerDiscount;
    //           newCart[key].discounts.offers += offerDiscount;
    //           newCart[key].discounts.discountTotal = offerDiscount;
    //         } else {
    //           const amount = newCart[key].quantities.normal
    //             + newCart[key].quantities.club
    //             + newCart[key].quantities.clubGeneral
    //             + newCart[key].quantities.bulk
    //             + newCart[key].quantities.sellout
    //             + newCart[key].quantities.recommended;

    //           const price = amount * newCart[key].prices.normal.price;

    //           // Reset total amount
    //           newCart[key].total_amount += newCart[key].discounts.discount;

    //           const offerDiscount = Math.round(price * (p.discount_value / 100));

    //           newCart[key].total_amount -= offerDiscount;
    //           newCart[key].discounts.offers += offerDiscount;
    //           newCart[key].discounts.discountTotal = offerDiscount;
    //         }

    //         // Set the other discounts to 0
    //         newCart[key].discounts.club = 0;
    //         newCart[key].discounts.bulk = 0;
    //         newCart[key].discounts.recommended = 0;
    //         newCart[key].discounts.discountSubtotal = 0;
    //         newCart[key].discounts.sellout = 0;
    //         newCart[key].discounts.clubGeneral = 0;
    //         newCart[key].discounts.coupon = 0;
    //         newCart[key].discounts.percentageDiscount = newCart[key].discounts.discountTotal
    //           / (newCart[key].amount * newCart[key].prices.normal.price);
    //       });
    //     } else {
    //       const productsWithoutPromo = [];
    //       p.products.forEach((prod) => {
    //         productsWithoutPromo.push(prod.id);
    //         // Reset total amount
    //         newCart[prod.id].total_amount += newCart[prod.id].discounts.discount;

    //         let offerDiscount = 0;
    //         if (p.discount_type === '$') {
    //           offerDiscount = Math.round(
    //             (p.discount_total * newCart[prod.id].amount) / allPromotionAmount,
    //           );
    //         } else {
    //           offerDiscount = prod.discount;
    //         }

    //         newCart[prod.id].total_amount -= offerDiscount;
    //         newCart[prod.id].discounts.offers += offerDiscount;
    //         newCart[prod.id].discounts.discountTotal = offerDiscount;

    //         // Set the other discounts to 0
    //         newCart[prod.id].discounts.club = 0;
    //         newCart[prod.id].discounts.bulk = 0;
    //         newCart[prod.id].discounts.recommended = 0;
    //         newCart[prod.id].discounts.discountSubtotal = 0;
    //         newCart[prod.id].discounts.sellout = 0;
    //         newCart[prod.id].discounts.clubGeneral = 0;
    //         newCart[prod.id].discounts.coupon = 0;
    // eslint-disable-next-line max-len
    //         newCart[prod.id].discounts.percentageDiscount = newCart[prod.id].discounts.discountTotal
    //           / (newCart[prod.id].amount * newCart[prod.id].prices.normal.price);
    //       });

    //       // Reset all other discounts
    //       Object.keys(newCart).forEach((key) => {
    //         // console.log('key: ', key);
    //         if (!productsWithoutPromo.includes(Number(key))) {
    //           console.log('key: ', key);
    //           // Reset total amount
    //           newCart[key].total_amount += newCart[key].discounts.discountTotal;

    //           newCart[key].discounts.club = 0;
    //           newCart[key].discounts.bulk = 0;
    //           newCart[key].discounts.recommended = 0;
    //           newCart[key].discounts.discountSubtotal = 0;
    //           newCart[key].discounts.sellout = 0;
    //           newCart[key].discounts.clubGeneral = 0;
    //           newCart[key].discounts.coupon = 0;
    //           newCart[key].discounts.discountTotal = 0;
    //           newCart[key].discounts.percentageDiscount = 0;
    //         }
    //       });
    //     }

    //     allDiscounts.bulkDiscount = 0;
    //     allDiscounts.clubDiscount = 0;
    //     allDiscounts.selloutDiscount = 0;
    //     allDiscounts.recommendedDiscount = 0;
    //     allDiscounts.couponDiscount = 0;

    //     allDiscounts.totalDiscount = allDiscounts.offersDiscount;
    //   });
    // }
  }

  // Set subtotal
  const subtotal = Object.keys(newCart).reduce((a, p) => a + newCart[p].subtotal_amount, 0);

  let total = subtotal - allDiscounts.totalDiscount < 0
    ? 0
    : subtotal - allDiscounts.totalDiscount;

  if (Number.isNaN(total)) total = subtotal;

  return {
    all_discounts: allDiscounts,
    subtotal,
    total,
    cart: newCart,
  };
};

export {
  calculateDiscounts,
};
