const PAYMENT_TYPE_METHOD = 'METODO DE PAGO';
const PAYMENT_TYPE_RAPPI = 'RAPPI';
const PAYMENT_TYPE_RAPPI_TURBO = 'RAPPI TURBO';
const PAYMENT_TYPE_RAPPI_PAYLESS = 'RAPPI PAYLESS';
const PAYMENT_TYPE_CORNERSHOP = 'CORNERSHOP';
const PAYMENT_TYPE_UBEREATS = 'UBEREATS';
const PAYMENT_TYPE_PEDIDOSYA = 'PEDIDOSYA';
const PAYMENT_TYPE_EFECTIVO = 'EFECTIVO';
const PAYMENT_TYPE_TARJETA_DEBITO = 'TARJETA DE DEBITO';
const PAYMENT_TYPE_TARJETA_CREDITO = 'TARJETA DE CREDITO';
const PAYMENT_TYPE_TRANSFERENCIA = 'TRANSFERENCIA BANCARIA';
const PAYMENT_TYPE_WALLET = 'WALLET';
const PAYMENT_TYPE_OTRO = 'OTRO';
const PAYMENT_TYPE_NC = 'NOTA DE CREDITO';
const PAYMENT_TYPE_GOODMEAL = 'GOODMEAL';
const PAYMENT_TYPE_MACH = 'MACH';
const PAYMENT_TYPE_REDPAY = 'REDPAY';
const PAYMENT_TYPE_FREE = 'FREE';

const STATUS_PENDIENTE = 'PENDIENTE';
const STATUS_APROBADO = 'APROBADO';
const STATUS_RECHAZADO = 'RECHAZADO';
const STATUS_CANCELADO = 'CANCELADO';
const STATUS_PAGANDO = 'PAGANDO';
const STATUS_ERROR = 'ERROR';
const STATUS_BILLING_PROCESSING = 'BILLING PROCESSING';
const STATUS_FINISHED = 'FINISHED';
const STATUS_EXPIRADO = 'EXPIRADO';

const BUY_TYPE_SOCIO = 'socio';
const BUY_TYPE_NO_SOCIO = 'no-socio';
const BUY_TYPE_RAPPI_PAYLESS = 'rappi-payless';
const BUY_TYPE_RAPPI = 'rappi';
const BUY_TYPE_RAPPI_TURBO = 'rappi-turbo';
const BUY_TYPE_CORNERSHOP = 'cornershop';
const BUY_TYPE_UBEREATS = 'ubereats';
const BUY_TYPE_PEDIDOSYA = 'pedidosya';
const BUY_TYPE_GOODMEAL = 'goodmeal';

const COUPON_TYPE_DIFF = 'diff';
const COUPON_TYPE_PERCENTAGE = 'percentage';

const DELIVERY_STATUS_PENDIENTE = 'PENDIENTE';
const DELIVERY_STATUS_EN_PROCESO = 'EN PROCESO';
const DELIVERY_STATUS_PREPARADO = 'PREPARADO';
const DELIVERY_STATUS_EN_RUTA = 'EN RUTA';
const DELIVERY_STATUS_ENTREGADO = 'ENTREGADO';
const DELIVERY_STATUS_NO_ENTREGADO = 'NO ENTREGADO';

const DOCUMENT_TYPE_BOLETA = 'Boleta';
const DOCUMENT_TYPE_FACTURA = 'Factura';

const REDELCOM_PROVIDER = 'REDELCOM';
const TRANSBANK_PROVIDER = 'TRANSBANK';
const TRANSBANK_SMARTPOS_PROVIDER = 'TRANSBANK-SMARTPOS';

const TZ_AMERICA_SANTIAGO = 'America/Santiago';

const DOCUMENT_ENTRY_TYPE_FACTURA = 'factura';
const DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO = 'guia-de-despacho';

const FINISHED_PURCHASE_INVOICE = 'TERMINADA';

const CANCELLED_STORE_ORDER = 'CANCELADA';
const RECEIVED_STORE_ORDER = 'RECIBIDA';

const RECOMMENDED_PRICE_LIST_STORE = 91;

module.exports = {
  PAYMENT_TYPE_METHOD,
  PAYMENT_TYPE_RAPPI,
  PAYMENT_TYPE_RAPPI_TURBO,
  PAYMENT_TYPE_RAPPI_PAYLESS,
  PAYMENT_TYPE_CORNERSHOP,
  PAYMENT_TYPE_UBEREATS,
  PAYMENT_TYPE_PEDIDOSYA,
  PAYMENT_TYPE_EFECTIVO,
  PAYMENT_TYPE_TARJETA_DEBITO,
  PAYMENT_TYPE_TARJETA_CREDITO,
  PAYMENT_TYPE_TRANSFERENCIA,
  PAYMENT_TYPE_WALLET,
  PAYMENT_TYPE_OTRO,
  PAYMENT_TYPE_NC,
  PAYMENT_TYPE_GOODMEAL,
  PAYMENT_TYPE_FREE,
  STATUS_PENDIENTE,
  STATUS_APROBADO,
  STATUS_RECHAZADO,
  STATUS_CANCELADO,
  STATUS_PAGANDO,
  STATUS_ERROR,
  STATUS_BILLING_PROCESSING,
  STATUS_FINISHED,
  STATUS_EXPIRADO,
  BUY_TYPE_SOCIO,
  BUY_TYPE_NO_SOCIO,
  BUY_TYPE_RAPPI_PAYLESS,
  BUY_TYPE_RAPPI,
  BUY_TYPE_RAPPI_TURBO,
  BUY_TYPE_CORNERSHOP,
  BUY_TYPE_UBEREATS,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_GOODMEAL,
  COUPON_TYPE_DIFF,
  COUPON_TYPE_PERCENTAGE,
  DELIVERY_STATUS_PENDIENTE,
  DELIVERY_STATUS_EN_PROCESO,
  DELIVERY_STATUS_PREPARADO,
  DELIVERY_STATUS_EN_RUTA,
  DELIVERY_STATUS_ENTREGADO,
  DELIVERY_STATUS_NO_ENTREGADO,
  DOCUMENT_TYPE_BOLETA,
  DOCUMENT_TYPE_FACTURA,
  REDELCOM_PROVIDER,
  TRANSBANK_PROVIDER,
  TRANSBANK_SMARTPOS_PROVIDER,
  TZ_AMERICA_SANTIAGO,
  PAYMENT_TYPE_MACH,
  PAYMENT_TYPE_REDPAY,
  DOCUMENT_ENTRY_TYPE_FACTURA,
  DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO,
  FINISHED_PURCHASE_INVOICE,
  CANCELLED_STORE_ORDER,
  RECEIVED_STORE_ORDER,
  RECOMMENDED_PRICE_LIST_STORE,
};
