/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import { TZ_AMERICA_SANTIAGO } from './const';

const customerDiscountDay = () => {
  const customerDiscountDays = [1, 4];
  const endHour = 7;

  let valid = false;
  const todayDay = moment().tz(TZ_AMERICA_SANTIAGO).day();
  const hour = moment().tz(TZ_AMERICA_SANTIAGO).hour();

  customerDiscountDays.forEach((d) => {
    // Check current day
    // E.g. d 4, todayDay 4, hour 17 valid -> true
    // E.g. d 4, todayDay 4, hour 0 valid -> false (0 >= endHour -> false)
    if ((d === todayDay) && (hour >= endHour) && (hour <= 23)) valid = true;

    // Check tomorrow day
    // E.g. todayDay 5, tomorrowDay (4 + 1), hour 0 valid -> true
    // E.g. todayDay 5, tomorrowDay (4 + 1), hour 2 valid -> false (2 < endHour -> false)
    let tomorrowDay = d + 1;
    if (tomorrowDay === 7) tomorrowDay = 0;
    if ((tomorrowDay === todayDay) && (hour < endHour)) valid = true;
  });
  return valid;
};

export {
  customerDiscountDay,
};
