import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import axios from '../../../utils/axios';
import {
  BUY_TYPE_RAPPI,
  BUY_TYPE_CORNERSHOP,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_UBEREATS,
  BUY_TYPE_RAPPI_TURBO,
  BUY_TYPE_GOODMEAL,
  BUY_TYPE_RAPPI_PAYLESS,
} from '../../../utils/const';
import thousandSeparator from '../../../utils/thousandSeparator';

const CartTotals = ({
  buyType,
  subtotal,
  // couponDiscount,
  // generalDiscount,
  total,
  totalPoints,
  // promotionsDiscount,
}) => {
  const allDiscounts = useSelector((state) => state.customer.all_discounts);
  const cartLoading = useSelector((state) => state.customer.cart_loading);

  const [allDiscountsFormat, setAllDiscountsFormat] = useState({});

  const discountTypes = {
    bulkDiscount: 'DESCUENTO MAYORISTA',
    clubDiscount: 'DESCUENTO SOCIO',
    selloutDiscount: 'DESCUENTO LIQUIDACIÓN',
    recommendedDiscount: 'DESCUENTO PROD. RECOMENDADO',
    offersDiscount: 'DESCUENTO OFERTAS',
    couponDiscount: 'DESCUENTO CUPÓN',
    totalDiscount: 'DESCUENTO TOTAL',
  };

  useEffect(() => {
    const discounts = {
      bulkDiscount: allDiscounts.bulkDiscount,
      clubDiscount: allDiscounts.clubDiscount + allDiscounts.clubGeneralDiscount,
      selloutDiscount: allDiscounts.selloutDiscount,
      recommendedDiscount: allDiscounts.recommendedDiscount,
      offersDiscount: allDiscounts.offersDiscount,
      couponDiscount: allDiscounts.couponDiscount,
      totalDiscount: allDiscounts.totalDiscount,
    };
    setAllDiscountsFormat(discounts);
  }, [allDiscounts]);

  const renderDiscounts = () => (
    Object.keys(allDiscountsFormat).map((d) => {
      if (!allDiscountsFormat[d]) return null;
      return (
        <div className="cart-discount-display">
          <h5>
            {(allDiscountsFormat[d] > 0)
              ? (
                <div>
                  {discountTypes[d]}
                  <span>
                    {` - $${thousandSeparator(allDiscountsFormat[d], '.')}`}
                  </span>
                </div>
              ) : ''}
          </h5>
        </div>
      );
    })
  );

  const renderSubtotal = () => (
    <div className="subtotal">
      <h5>SUBTOTAL:</h5>
      {cartLoading ? (
        <CircularProgress style={{
          color: 'var(--global--secondary--BackgroundColor)',
          width: '30px',
          height: '30px',
          marginTop: '10px',
          marginBottom: '3px',
        }}
        />
      ) : (
        <h1>
          $
          {thousandSeparator(
            subtotal,
            '.',
          )}
        </h1>
      )}
    </div>
  );

  return (
    <>
      {total < subtotal ? renderSubtotal() : null}
      {renderDiscounts()}
      <div className="total">
        <h5>TOTAL:</h5>
        {cartLoading ? (
          <CircularProgress style={{
            color: 'var(--global--secondary--BackgroundColor)',
            width: '30px',
            height: '30px',
            marginTop: '10px',
            marginBottom: '2px',
          }}
          />
        ) : (
          <h1>
            $
            {thousandSeparator(total, '.')}
          </h1>
        )}
      </div>
      {
        (buyType !== BUY_TYPE_RAPPI
          && buyType !== BUY_TYPE_RAPPI_TURBO
          && buyType !== BUY_TYPE_RAPPI_PAYLESS
          && buyType !== BUY_TYPE_CORNERSHOP
          && buyType !== BUY_TYPE_UBEREATS
          && buyType !== BUY_TYPE_PEDIDOSYA
          && buyType !== BUY_TYPE_GOODMEAL)
        && totalPoints > 0 && (
          <div className="total-points">
            <h5>Puntos Disponibles:</h5>
            <h1>{thousandSeparator(totalPoints, '.')}</h1>
          </div>
        )
      }
    </>
  );
};

export default CartTotals;
