/* eslint-disable camelcase */
import axios from '../../../utils/axios';

import {
  setCustomerOrderId,
  setCustomerOrderNumber,
  setOrderStatus,
} from '../../../actions/customerActions';

import {
  STATUS_PAGANDO,
} from '../../../utils/const';

const saveOrder = async (
  saved,
  products,
  documentType,
  store,
  customer,
  user,
  coupon,
  buy_type,
  payments,
  machine_number,
  orderId,
  enqueueSnackbar,
  closeSnackbar,
  dispatch,
  promotion,
  recommended_product_ids,
  recommendedWatchTime,
) => {
  if (!orderId) {
    try {
      const response = await axios.post('/api/orders/new', {
        sale_admin_id: user.id || null,
        save_admin_id: user.id || null,
        customer_id: customer.user ? customer.user.id || null : null,
        products,
        saved: false,
        store_id: store.id,
        coupon,
        machine_number,
        buy_type,
        payments: payments.map((p) => ({ payment_type: p.payment_type, amount: p.amount })),
        delivery_company: customer.type,
        // promotion,
        recommended_product_ids,
        doc_type: documentType,
        recommended_watch_time: recommendedWatchTime,
      });
      if (response.data.code === 201) {
        dispatch(setCustomerOrderId(response.data.data.order_id));
        dispatch(setCustomerOrderNumber(response.data.data.order_number || null));
        dispatch(setOrderStatus(STATUS_PAGANDO));
        if (saved) {
          const key = enqueueSnackbar('Pedido creado y guardado!', {
            variant: 'success',
            onClick: () => {
              closeSnackbar(key);
            },
          });
        }
        return true;
      }
      const key = enqueueSnackbar(response.data.errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return false;
    } catch (err) {
      const msg = err.response ? err.response.data.errorMessage : 'Ha ocurrido un error';
      const key = enqueueSnackbar(msg, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return false;
    }
  } else {
    try {
      const response = await axios.put(`/api/orders/${orderId}`, {
        products,
        coupon,
      });
      if (response.data.code === 200) {
        if (saved) {
          const key = enqueueSnackbar('Pedido editado y guardado!', {
            variant: 'success',
            onClick: () => {
              closeSnackbar(key);
            },
          });
        }

        return true;
      }
    } catch (err) {
      const msg = err.response ? err.response.data.errorMessage : 'Ha ocurrido un error';
      const key = enqueueSnackbar(msg, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return false;
    }
  }
  return false;
};

export default saveOrder;
