/* eslint-disable react/jsx-props-no-spreading */
import React, {
  Suspense,
  Fragment,
  lazy,
} from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import AuthGuard from './components/Auth/AuthGuard';
import LoadingScreen from './components/Loading/LoadingScreen';
import Error404View from './views/Error404/Error404View';
import Login from './views/Login/Login';
// import Home from './views/Home/Home';
import Entry from './views/Entry/Entry';
import Profile from './views/Profile/Profile';
import Buy from './views/Buy/Buy';
import Cart from './views/Cart/Cart';
import ReturnType from './views/Returns/ReturnType';
import ReturnEntry from './views/Returns/ReturnEntry';
import ReturnCart from './views/Returns/ReturnCart';
import SavedOrders from './views/SavedOrders/SavedOrders';
import Billing from './views/Billing/Billing';
import FinalPay from './views/FinalPay/FinalPay';
// import AddStock from './views/AddStock/AddStock';
import ReturnFinal from './views/Returns/ReturnFinal';
import ExpressDelivery from './views/ExpressDelivery/ExpresDelivery';
import DetailsOrder from './views/ExpressDelivery/details/DetailsOrder';
import Delivery from './views/Delivery/Delivery';
import SmartPOSOptions from './views/SmartPOS';
import pkg from '../package.json';
import AddStockSelect from './views/AddStock/AddStockSelect';
import AddStockEntry from './views/AddStock/AddStockEntry';
import DeliveryOrder from './views/DeliveryOrder/DeliveryOrder';

const isSmartPOS = !!window.AppPagoSDK;

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/404',
    component: () => <Error404View />,
  },
  {
    id: 2,
    exact: true,
    path: '/login',
    component: () => <Login version={pkg.version} />,
  },
  {
    id: 3,
    path: '/',
    guard: AuthGuard,
    routes: [
      {
        id: 4,
        exact: true,
        path: '/',
        component: () => (isSmartPOS ? <Redirect to="/inicio" /> : <Redirect to="/maquina" />),
      },
      {
        id: 5,
        exact: true,
        path: '/maquina',
        component: lazy(() => import('./views/Machine')),
      },
      {
        id: 6,
        exact: true,
        path: '/inicio',
        component: lazy(() => import('./views/Home')),
      },
      {
        id: 7,
        exact: true,
        path: '/ingreso',
        component: () => <Entry />,
      },
      {
        id: 8,
        exact: true,
        path: '/perfil',
        component: () => <Profile />,
      },
      {
        id: 9,
        exact: true,
        path: '/compra',
        component: () => <Buy />,
      },
      {
        id: 10,
        exact: true,
        path: '/carro',
        component: () => <Cart />,
      },
      {
        id: 11,
        exact: true,
        path: '/tipo-devolucion',
        component: () => <ReturnType />,
      },
      {
        id: 12,
        exact: true,
        path: '/devolucion',
        component: () => <ReturnEntry />,
      },
      {
        id: 13,
        exact: true,
        path: '/carro-devolucion',
        component: () => <ReturnCart />,
      },
      {
        id: 14,
        exact: true,
        path: '/guardados',
        component: () => <SavedOrders />,
      },
      {
        id: 15,
        exact: true,
        path: '/factura',
        component: () => <Billing />,
      },
      {
        id: 16,
        exact: true,
        path: '/pago-final',
        component: () => <FinalPay />,
      },
      {
        id: 17,
        exact: true,
        path: '/ingresar-stock',
        component: () => <AddStockSelect />,
      },
      {
        id: 18,
        exact: true,
        path: '/ingresar-stock/:type/:id',
        component: () => <AddStockEntry />,
      },
      {
        id: 19,
        exact: true,
        path: '/final-devolucion',
        component: () => <ReturnFinal />,
      },
      {
        id: 20,
        exact: true,
        path: '/entrega-inmediata',
        component: () => <ExpressDelivery />,
      },
      {
        id: 21,
        exact: true,
        path: '/orden-detalles',
        component: () => <DetailsOrder />,
      },
      {
        id: 22,
        exact: true,
        path: '/delivery',
        component: () => <Delivery />,
      },
      {
        id: 23,
        exact: true,
        path: '/orden-delivery/:deliveryType',
        component: () => <DeliveryOrder />,
      },
      {
        id: 24,
        exact: true,
        path: '/opciones-smartpos',
        component: () => <SmartPOSOptions />,
      },
      {
        id: 25,
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route) => {
        const Guard = route.guard || Fragment;
        const Component = route.component;

        return (
          <Route
            key={route.id}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                {route.routes
                  ? renderRoutes(route.routes)
                  : <Component {...props} />}
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
