import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import {
  CircularProgress,
  Popover,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Layout/Page';
import axios from '../../utils/axios';
import {
  CANCELLED_STORE_ORDER,
  DOCUMENT_ENTRY_TYPE_FACTURA,
  DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO,
  FINISHED_PURCHASE_INVOICE,
  RECEIVED_STORE_ORDER,
} from '../../utils/const';
import SmartPOSCamera from '../../components/SmartPOSCamera';
import { setEANFromCamera } from '../../actions/sessionActions';
import { createEnteredPrint } from '../../utils/addstock';
import './AddStock.css';

function AddStockEntry() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // Url params
  const { type, id } = useParams();

  // Ref
  const eanInput = useRef(null);
  const quantityInput = useRef(null);

  // Redux states
  const { machineNumber, eanFromCamera } = useSelector((state) => state.session);

  // React states
  const [open, setOpen] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [ean, setEan] = useState('');
  const [quantity, setQuantity] = useState('');
  const [loading, setLoading] = useState(true);
  const [entryLoading, setEntryLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [eanLoading, setEanLoading] = useState(false);
  const [popoverLoading, setPopoverLoading] = useState(false);
  const [document, setDocument] = useState({});
  const [products, setProducts] = useState([]);

  window.callPrinterWithResponse = async (resp) => {
    if (resp?.status === 2) {
      const errorMessage = resp.error || 'Falta papel en la impresora';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else if (resp?.status === 9) {
      const errorMessage = resp.error || 'Batería muy baja para imprimir';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      const errorMessage = resp.error || 'Ha ocurrido un error al imprimir';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  };

  window.requestForPrinterResult = async (resp) => {
    if (resp?.response?.codeResponse === 2) {
      let errorMessage = resp.response?.glossResponse || 'Falta papel en la impresora';
      errorMessage += `(Cod ${resp.response.codeResponse})`;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else if (resp?.response?.codeResponse === 9) {
      let errorMessage = resp.response?.glossResponse || 'Batería muy baja para imprimir';
      errorMessage += `(Cod ${resp.response.codeResponse})`;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      let errorMessage = resp?.response?.glossResponse || 'Ha ocurrido un error al imprimir';
      if (resp?.response?.codeResponse) {
        errorMessage += `(Cod ${resp.response.codeResponse})`;
      }
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  };

  const isDocumentClosed = () => {
    if (type === DOCUMENT_ENTRY_TYPE_FACTURA) {
      if (document.status === FINISHED_PURCHASE_INVOICE) return true;
      return false;
    }
    if (type === DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO) {
      if (document.status === CANCELLED_STORE_ORDER
        || document.status === RECEIVED_STORE_ORDER) return true;
      return false;
    }
    return false;
  };

  useEffect(() => {
    // Get document detail
    const getDocumentDetail = async () => {
      try {
        setLoading(true);

        let data = {};

        if (type === DOCUMENT_ENTRY_TYPE_FACTURA) {
          const response = await axios.get(`/api/purchase-invoice/detail/${id}`);
          data = response.data;
        }
        if (type === DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO) {
          const response = await axios.get(`/api/store-order/detail/${id}`);
          data = response.data;
        }

        if (data.success) {
          setDocument(data.data.document);
          setProducts(data.data.products);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.errorMessage || 'Ha ocurrido un error obteniendo el detalle del documento';
        const key = enqueueSnackbar(errorMessage, {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          },
        });
      } finally {
        setLoading(false);
      }
    };
    getDocumentDetail();
  }, []);

  useEffect(() => {
    if (eanFromCamera) {
      setEan(eanFromCamera);
      dispatch(setEANFromCamera(''));
    }
  }, [eanFromCamera]);

  const handleAddTemp = async (productId) => {
    try {
      setPopoverLoading(true);

      // Make a copy of products
      const newProducts = [...products];
      // Get product
      const product = newProducts.find((p) => p.id === productId);

      if (!product) {
        const key = enqueueSnackbar(`No se ha encontrado el producto con id ${productId}`, {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          },
        });
      }

      let response = {};
      if (type === DOCUMENT_ENTRY_TYPE_FACTURA) {
        // Check if the stock_to_enter is valid
        response = await axios.post('/api/purchase-order/stock-entry-left', {
          purchase_order_id: document.purchase_order_id || null,
          product_id: productId || null,
          new_amount: Number(quantity),
        });
      }
      if (type === DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO) {
        // Check if the stock_to_enter is valid
        response = await axios.post('/api/store-order/stock-entry-left', {
          store_order_id: id || null,
          product_id: productId || null,
          new_amount: Number(quantity),
        });
      }

      if (!response?.data?.success) {
        const key = enqueueSnackbar(response.data?.errorMessage, {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          },
        });
      } else {
        // Update products
        product.stock_to_enter = Number(quantity);
        setProducts(newProducts);
        setQuantity('');
        setEan('');
        setOpen(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || 'Ha ocurrido un error al ingresar el EAN';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } finally {
      setPopoverLoading(false);
    }
  };

  const handleChange = async (event) => {
    setEan(event.target.value);
  };

  const handleResetStockToEnter = (productId) => {
    // Make a copy of products
    const newProducts = [...products];
    // Get product
    const product = newProducts.find((p) => p.id === productId);
    if (product) {
      product.stock_to_enter = 0;
      setProducts(newProducts);
    }
  };

  const submitEan = async (event) => {
    event.preventDefault();
    // Check if the ean is in the products
    const validEan = products.find((s) => s.ean?.split(',').find((e) => e === ean));
    if (!validEan) {
      const key = enqueueSnackbar('EAN no pertenece a esta lista', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else if (!quantity) {
      const key = enqueueSnackbar('Ingrese una cantidad', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      try {
        setEanLoading(true);
        const productResponse = await axios.get(`/api/product-by-ean-unitary/${ean}`);
        if (productResponse.data.code === 200) {
          setOpen(true);
        }
      } catch (err) {
        const key = enqueueSnackbar(`No se encontró el producto con EAN ${ean}`, {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          },
        });
      } finally {
        setEanLoading(false);
      }
    }
  };

  const handleAdd = () => {
    const newQuantity = Number(quantity) + 1;
    if (newQuantity < 100000) setQuantity(String(newQuantity));
  };

  const handleRemove = () => {
    let newQuantity = Number(quantity);
    if ((newQuantity - 1) <= 0) newQuantity = '';
    else newQuantity -= 1;
    setQuantity(String(newQuantity));
  };

  const handleQuantityChange = (e) => {
    const valueNumber = Number(e.target.value);
    if (valueNumber <= 0) {
      setQuantity('');
    } else if (valueNumber < 100000) {
      setQuantity(String(valueNumber));
    }
  };

  const handleAddStock = async (status = null) => { // ingresar stock
    // add stock and change document product quantities
    try {
      setEntryLoading(true);
      if (type === DOCUMENT_ENTRY_TYPE_FACTURA) {
        const { data } = await axios.post('/api/purchase-invoice/ingress', {
          document,
          products,
          status,
        });
        if (data) {
          setProducts(data.data.products);
          return { success: true };
        }
        return { success: false };
      }
      if (type === DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO) {
        const { data } = await axios.post('/api/store-order/ingress', {
          document,
          products,
        });
        if (data) {
          setProducts(data.data.products);
          return { success: true };
        }
        return { success: false };
      }
      return { success: false };
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || 'Ha ocurrido un error ingresando las cantidades';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return { success: false };
    } finally {
      setEntryLoading(false);
    }
  };

  const invoiceIngress = async () => { // cerrar documento
    setLoading(true);
    try {
      // Update stock
      const addStockResponse = await handleAddStock(FINISHED_PURCHASE_INVOICE);
      if (addStockResponse.success) {
        // Change document status
        if (type === DOCUMENT_ENTRY_TYPE_FACTURA) {
          const { data } = await axios.post('/api/purchase-invoice/entry', {
            purchase_order_id: document.purchase_order_id,
            status: FINISHED_PURCHASE_INVOICE,
            invoice_number: document.number,
          });
          const invoiceResponse = data.data.purchaseInvoice;
          if (invoiceResponse) {
            const key = enqueueSnackbar(`Se cerró la factura ${document.number} exitosamente`, {
              variant: 'success',
              onClick: () => {
                closeSnackbar(key);
              },
            });
            setOpenPrint(true);
          }
        } else {
          const { data } = await axios.get(`/api/store-order/close/${document.id}`);
          if (data) {
            const key = enqueueSnackbar(`Se cerró la guía de despacho ${document.number} exitosamente`, {
              variant: 'success',
              onClick: () => {
                closeSnackbar(key);
              },
            });
            setOpenPrint(true);
          }
        }
      } else {
        setOpenInvoice(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || `Ha ocurrido un error cerrando la ${type}`;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const createRows = () => products.map((product) => (
    <tr key={product.id} className="addstock-order-list-scrollable">
      <td className="addstock-info">
        {product.name}
      </td>
      <td className="addstock-info-number">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{product.stock_to_enter}</span>
          {product.stock_to_enter ? (
            <button
              type="button"
              onClick={() => handleResetStockToEnter(product.id)}
              className="addstock-stock-to-enter-reset"
            >
              Borrar
            </button>
          ) : null}
        </div>
      </td>
      <td className="addstock-info-number">
        {product.current_stock}
      </td>
    </tr>
  ));

  const printEnteredProducts = async () => {
    let machineType = 'redelcom';
    if (window.AppPagoSDK) machineType = 'smartPOS';

    setPrintLoading(true);

    let documentId = document.id;
    if (type === DOCUMENT_ENTRY_TYPE_FACTURA) documentId = document.purchase_order_id;
    const documentNumber = document.number;

    const printResponse = await createEnteredPrint({
      type,
      machineType,
      documentId,
      machineNumber,
      documentNumber,
    });

    setPrintLoading(false);

    if (!printResponse.success) {
      const key = enqueueSnackbar(printResponse.errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      window.location.assign('/ingresar-stock');
    }
  };

  const popoverContent = () => {
    const product = products.find((s) => s.ean?.split(',').find((e) => e === ean));
    if (product) {
      return (
        <div className="addstock-entry-popover-container">
          <div className="addstock-popover-info-message">
            <p>{`Ingresar x${quantity} de ${product.name} (EAN: ${ean})`}</p>
          </div>
          {popoverLoading
            ? <CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} />
            : (
              <div className="addstock-entry-confirmation-buttons">
                <button
                  disabled={popoverLoading}
                  className="addstock-accept-entry-button"
                  type="submit"
                  onClick={() => handleAddTemp(product.id)}
                >
                  Aceptar
                </button>
                <button
                  className="addstock-cancel-entry-button"
                  type="submit"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancelar
                </button>
              </div>
            )}
        </div>
      );
    }
    // setOpen(false); react error
    return null;
  };

  const popoverInvoiceContent = () => (
    <div className="addstock-entry-popover-container">
      <div className="addstock-popover-info-message">
        <div>
          <p>{`¿Desea cerrar esta ${type === 'factura' ? 'factura' : 'guía'}?`}</p>
        </div>
      </div>
      <div className="addstock-entry-confirmation-buttons">
        {popoverLoading
          ? <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
          : (
            <>
              <button
                disabled={popoverLoading}
                className="addstock-accept-entry-button"
                type="submit"
                onClick={() => invoiceIngress()}
              >
                Aceptar
              </button>
              <button
                className="addstock-cancel-entry-button"
                type="submit"
                onClick={() => setOpenInvoice(false)}
              >
                Cancelar
              </button>
            </>
          )}
      </div>
    </div>
  );

  const popoverPrintEnteredProducts = () => (
    <div className="addstock-entry-popover-container">
      <div className="addstock-popover-info-message">
        <div>
          <p>¿Desea imprimir lo ingresado?</p>
        </div>
      </div>
      {printLoading ? (
        <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
      ) : (
        <div className="addstock-entry-confirmation-buttons">
          <button
            disabled={printLoading}
            className="addstock-accept-entry-button"
            type="submit"
            onClick={() => printEnteredProducts()}
          >
            Si
          </button>
          <button
            className="addstock-cancel-entry-button"
            type="submit"
            onClick={() => window.location.assign('/ingresar-stock')}
          >
            No
          </button>
        </div>
      )}
    </div>
  );

  return (
    <Page
      title={`Club Líquidos | POS Ingreso ${type}`}
      progressStep="none"
      backRef="/ingresar-stock"
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <div className="addstock-component-wrapper">
          <div className="addstock-form-field">
            <h3 style={{ margin: '10px' }}>
              {`${type === DOCUMENT_ENTRY_TYPE_FACTURA ? 'Factura ingresada' : 'Guía ingresada'}:`}
            </h3>
            <h2 style={{ margin: '5px' }}>{document.number}</h2>
            {isDocumentClosed() ? (
              <span>(Cerrada)</span>
            ) : (
              <form className="addstock-ean-form" onSubmit={submitEan}>
                <label htmlFor="ean">
                  Ingresar código de barra
                  <input
                    ref={eanInput}
                    id="ean"
                    type="text"
                    name="ean"
                    value={ean}
                    onChange={(e) => handleChange(e)}
                    className="addstock-ean-input"
                  />
                </label>
                <div className="addstock-amount-cell-cart">
                  <button
                    type="button"
                    className="addstock-yellow-button-add-product-ean"
                    onClick={() => handleRemove()}
                  >
                    -
                  </button>
                  <input
                    ref={quantityInput}
                    id="quantity"
                    type="number"
                    name="quantity"
                    value={quantity}
                    onChange={(e) => handleQuantityChange(e)}
                    className="addstock-ean-quantity-input"
                  />
                  <button
                    type="button"
                    className="addstock-yellow-button-add-product-ean"
                    onClick={() => handleAdd()}
                  >
                    +
                  </button>
                </div>
                <div className="addstock-erase-ean">
                  <SmartPOSCamera />
                  <button
                    className="addstock-clear-ean-button"
                    type="button"
                    onClick={() => {
                      setEan('');
                      setQuantity('');
                      eanInput.current.focus();
                    }}
                  >
                    Borrar
                  </button>
                </div>
                <button
                  disabled={eanLoading}
                  type="submit"
                  className="addstock-yellow-button-add-product"
                  onClick={(e) => submitEan(e)}
                >
                  {eanLoading
                    ? <CircularProgress style={{ color: 'black', width: '15px', height: '15px' }} />
                    : <span>Agregar Producto</span>}
                </button>
              </form>
            )}
          </div>
          <>
            <table className="addstock-order-list-table-main">
              <thead className="addstock-order-list-title-table">
                <tr className="addstock-order-list-scrollable">
                  <th className="addstock-order-number-cell">Producto</th>
                  <th className="addstock-order-number-cell-number">Por ingresar</th>
                  <th className="addstock-order-number-cell-number">Ingresado</th>
                </tr>
              </thead>
              <tbody className="addstock-order-list-table">
                {createRows()}
              </tbody>
            </table>
            {isDocumentClosed() ? (
              <div>
                <button
                  className="addstock-yellow-button"
                  type="button"
                  onClick={() => {
                    printEnteredProducts();
                  }}
                >
                  {printLoading
                    ? (<CircularProgress style={{ color: 'black' }} />)
                    : 'Re imprimir lo ingresado'}
                </button>
              </div>
            ) : (
              <>
                <div className="addstock-row-elements">
                  <button
                    disabled={entryLoading}
                    type="submit"
                    className="addstock-yellow-button"
                    onClick={() => handleAddStock()}
                  >
                    {entryLoading
                      ? <CircularProgress style={{ color: 'black', width: '15px', height: '15px' }} />
                      : <span>Ingresar Stock</span>}
                  </button>
                </div>
                <div className="addstock-row-elements">
                  <button
                    disabled={entryLoading}
                    type="submit"
                    className="addstock-yellow-button"
                    onClick={() => setOpenInvoice(true)}
                  >
                    {`Cerrar ${type === DOCUMENT_ENTRY_TYPE_FACTURA ? 'Factura' : 'Guía de Despacho'}`}
                  </button>
                </div>
              </>
            )}
          </>
        </div>
      )}
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {popoverContent()}
      </Popover>
      <Popover
        open={openInvoice}
        onClose={() => setOpenInvoice(false)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {popoverInvoiceContent()}
      </Popover>
      <Popover
        open={openPrint}
        onClose={() => setOpenPrint(false)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        disableBackdropClick
      >
        {popoverPrintEnteredProducts()}
      </Popover>
    </Page>
  );
}

export default AddStockEntry;
