import Popover from '@material-ui/core/Popover';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  DOCUMENT_TYPE_BOLETA,
  DOCUMENT_TYPE_FACTURA,
} from '../../../utils/const';

import { setDocumentType } from '../../../actions/customerActions';

const CartBilling = ({
  documentType,
}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState(documentType);

  const handleClose = () => {
    setOpen(false);
  };

  const onTypeClick = () => {
    setOpen(true);
  };

  const handleAccept = () => {
    dispatch(setDocumentType(document));
    setOpen(false);
  };

  return (
    <>
      <div className="button-holder">
        <button
          type="button"
          className="transparent-button"
          onClick={(e) => onTypeClick(e)}
        >
          {documentType}
        </button>
      </div>
      <Popover
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div className="cart-popover-container">
          <div className="cart-type-container">
            <div className="radio-input-container">
              <label htmlFor="boleta">
                <input
                  id="boleta"
                  type="radio"
                  value={DOCUMENT_TYPE_BOLETA}
                  checked={document === DOCUMENT_TYPE_BOLETA}
                  onChange={() => setDocument(DOCUMENT_TYPE_BOLETA)}
                />
                Boleta
              </label>
            </div>
            <div className="radio-input-container">
              <label htmlFor="factura">
                <input
                  id="factura"
                  type="radio"
                  value={DOCUMENT_TYPE_FACTURA}
                  checked={document === DOCUMENT_TYPE_FACTURA}
                  onChange={() => setDocument(DOCUMENT_TYPE_FACTURA)}
                />
                Factura
              </label>
            </div>
          </div>
          <div className="cart-popup-confirmation-buttons">
            <button
              className="accept-cart-button"
              type="submit"
              onClick={() => handleAccept()}
            >
              Aceptar
            </button>
            <button
              className="cancel-cart-button"
              type="submit"
              onClick={() => handleClose()}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default CartBilling;
