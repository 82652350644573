// /* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import './ErrorBoundary.css';

class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = { hasError: '' };
    this.handleOneClickButton = function resetData() {
      // Clear all local storage
      localStorage.clear();

      // Expire all site cookies
      const cookies = document.cookie.split(';');
      cookies.forEach((cookie) => {
        const cookieParts = cookie.split('=');
        const cookieName = cookieParts[0].trim();
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
      });

      // Redirect to login
      window.location.assign('/login');
    };
  }

  componentDidCatch(error /* , errorInfo */) {
    this.setState({ hasError: error.message });
    // TODO: send an email with the error
  }

  render() {
    const { state, props } = this;
    if (state.hasError) {
      return (
        <div className="errorboundary-page">
          <img className="errorboundary-logo" src="img/logo-liquidos-blanco.png" alt="logo" />
          <h1 className="errorboundary-title">Ha ocurrido un error</h1>
          <h3 className="errorboundary-error">{state.hasError}</h3>
          <button type="button" className="errorboundary-button" onClick={this.handleOneClickButton}>Refrescar</button>
        </div>
      );
    }

    return props.children;
  }
}

export default ErrorBoundary;
