/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CircularProgress, Dialog, DialogTitle, DialogContent, makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import thousandSeparator from '../../utils/thousandSeparator';
import ChangePayMethod from './changeMethod/ChangePayMethod';
import CancelPay from './cancelPay/CancelPay';
import axios from '../../utils/axios';
import './Payment.css';
import { setPaymentLoading } from '../../actions/customerActions';

const useStyles = makeStyles(() => ({
  paper: {
    border: '1px solid #e6d51b',
  },
}));

function CashPayment({
  handlePayed,
  payed,
  total,
  type,
  step,
  paids,
  paymentStep,
  payments,
  setPayments,
  totalSteps,
}) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { payment_loading: paymentLoading } = useSelector((state) => state.customer);
  const { Store: store } = useSelector((state) => state.account.user);

  // const [loading, setLoading] = useState(false);
  const [amountPayed, setAmountPayed] = useState(null);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [cancelButton, setCancelButton] = useState(false);
  // const [smartPOSCancelButton, setSmartPOSCancelButton] = useState(false);
  const [rdcPaymentId, setRDCPaymentId] = useState(null);
  // const [authCodeButton, setAuthCodeButton] = useState(false);
  const [authCodeForm, setAuthCodeForm] = useState(false);
  const [authCode, setAuthCode] = useState(null);
  const [authCodeError, setAuthCodeError] = useState(null);
  const [isBack, setIsBack] = useState(false);

  window.onpopstate = () => {
    setIsBack(true);
  };

  useEffect(() => {
    if (payed) {
      handlePayed(null);
    }
  }, [payed, handlePayed]);

  const orderId = useSelector((state) => state.customer.order_id);
  const { machineNumber } = useSelector((state) => state.session);

  const confirmPayment = async (rdcId) => {
    let error = null;
    let maxCount = 0;

    while (!paymentConfirmed) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const resp = await axios.get(`/api/payments/redelcom/${rdcId}`);

        if (resp.status === 200) {
          const { code, data } = resp.data;

          if (code === 200) {
            setPaymentConfirmed(true);

            if (data.ESTADO === 'APROBADO') {
              error = null;
            } else if (data.ESTADO === 'RECHAZADO') {
              error = `PAGO REDELCOM RECHAZADO: ${data.MENSAJE_VISOR}`;
            } else if (data.ESTADO === 'ERROR') {
              error = `ERROR REDELCOM: ${data.MENSAJE_VISOR}`;
            } else {
              error = 'ERROR REDELCOM';
            }

            break;
          } else if (code === 204) {
            setPaymentConfirmed(false);
          } else {
            setPaymentConfirmed(true);
            error = 'Ha ocurrido un error. Por favor intente nuevamente.';
            break;
          }
        } else {
          setPaymentConfirmed(true);
          error = 'Ha ocurrido un error. Por favor intente nuevamente.';
          break;
        }
      } catch (err) {
        console.log('Error confirming payment: ', err);
      }

      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 1000));
      maxCount += 1;

      if (maxCount >= 90) {
        setCancelButton(true);
      }
    }

    dispatch(setPaymentLoading(false));
    // setLoading(false);
    handlePayed(error);
  };

  const handleRedelcomPayment = async () => {
    try {
      dispatch(setPaymentLoading(true));
      // setLoading(true);

      const resp = await axios.post('/api/payments/redelcom/new', {
        order_id: orderId,
        machine_id: machineNumber,
        amount: total,
        payment_type: type,
        payment_id: paymentStep.id,
      });

      const { payment, errorMessage, errorAction } = resp.data.data;

      if (!payment) {
        dispatch(setPaymentLoading(false));
        if (errorAction === 'reset') {
          handlePayed(errorMessage, errorAction);
        } else {
          handlePayed(errorMessage || 'Ha ocurrido un error. Por favor intente nuevamente.');
        }
      } else {
        setRDCPaymentId(payment.rdc_transaction_id);
        confirmPayment(payment.rdc_transaction_id);
      }
    } catch (err) {
      dispatch(setPaymentLoading(false));
      setPaymentConfirmed(true);
      handlePayed('Ha ocurrido un error. Por favor intente nuevamente.');
    }
  };

  const handleSmartPOSPayment = async () => {
    // eslint-disable-next-line max-len
    // const smartPOSCookie = document.cookie.split('; ').find((row) => row.startsWith('smart_pos_keys='))?.split('=')[1];
    // const smartPOSCookieBool = smartPOSCookie === 'true';
    // if (!smartPOSCookieBool) {
    //   const errorMessage = 'No están cargadas las llaves del SmartPOS';
    //   const key = enqueueSnackbar(errorMessage, {
    //     variant: 'error',
    //     onClick: () => {
    //       closeSnackbar(key);
    //     },
    //   });
    // } else if (window.AppPagoSDK) {
    //   dispatch(setPaymentLoading(true));
    //   // setLoading(true);
    //   window.AppPagoSDK.requestForSales(`${total}`);

    //   let count = 0;
    //   while (paymentLoading) {
    //     await new Promise((res) => setTimeout(res, 1000));
    //     count += 1;

    //     if (count === 10) {
    //       setSmartPOSCancelButton(true);
    //     }
    //   }
    // }
    await axios.post('/api/payments/transbank-smartpos/new', {
      order_id: orderId,
      payment_id: paymentStep.id,
      transbank_code: null,
      smart_pos_transaction_id: null,
    }).catch((e) => {
      const key = enqueueSnackbar(e.message, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    });
    dispatch(setPaymentLoading(false));
    handlePayed(null);
  };

  const calculateChange = () => {
    const change = amountPayed - total >= 0 ? amountPayed - total : 0;
    return `$${thousandSeparator(change, '.')}`;
  };

  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter' && !loading) {
  //     handleRedelcomPayment();
  //   }
  // };

  const cancel = () => {
    setPaymentConfirmed(true);
    handlePayed('Operación cancelada');
  };

  const handleChange = (event) => {
    setAuthCode(event.target.value);
  };

  const handleAuthCode = async () => {
    if (!authCode) {
      setAuthCodeError('Debe ingresar un codigo');
    } else {
      setAuthCodeError(null);
      await axios.post(`/api/payments/redelcom/${rdcPaymentId}/authCode`, {
        authCode,
      });
      setAuthCodeForm(false);
      setPaymentConfirmed(true);
      handlePayed(null);
    }
  };

  return (
    <div>
      {paymentLoading ? (
        <div>
          <Dialog
            open={authCodeForm}
            classes={{ paper: classes.paper }}
          >
            <DialogTitle className="auth-code-form" id="alert-dialog-title">Codigo de autorización</DialogTitle>
            <DialogContent className="auth-code-form auth-code-form-content">
              <input
                type="text"
                placeholder="codigo de autorización"
                className="auth-code-input"
                name="authCode"
                value={authCode}
                onChange={(e) => handleChange(e)}
              />
              {authCodeError && <span className="error-message auth-code-error-message">{authCodeError}</span>}
              <div className="auth-code-form-row-buttons">
                <button type="button" className="yellow-button auth-code-button" onClick={() => handleAuthCode()}>Aceptar</button>
                <button type="button" className="auth-code-cancel-button auth-code-button" onClick={() => cancel()}>Cancelar</button>
              </div>
            </DialogContent>
          </Dialog>
          <h1 className="waiting-payment-header">Esperando Pago</h1>
          <div
            style={{
              margin: '0 auto', display: 'flex', flexDirection: 'row', justifyContent: 'center',
            }}
          >
            <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
          </div>
          <div>
            {cancelButton && (
              <p className="cancel-payment-button">
                ¿No abre la aplicación de pago?
                <div className="cancel-payment-button">
                  <CancelPay paids={paids} />
                </div>
              </p>
            )}
            {/* {smartPOSCancelButton && (
              <button
                className="cancel-payment-button"
                type="button"
                onClick={() => dispatch(setPaymentLoading(false))}
              >
                Cancelar SmartPOS
              </button>
            )} */}
          </div>
          <div>
            {/* {authCodeButton && (
              <p className="auth-payment-button">
                <button type="button" onClick={() => setAuthCodeForm(true)}>
                  Ingrese codigo de autorización
                </button>
              </p>
            )} */}
          </div>
        </div>
      ) : (
        <div>
          <h1>{`Pago ${type !== 'WALLET' ? type : 'OTRO'}`}</h1>
          <h2>
            $
            {thousandSeparator(total, '.')}
          </h2>
          {type.toLowerCase() === 'efectivo'
            ? (
              <>
                <input
                  className="change-input"
                  type="number"
                  placeholder="Efectivo pagado"
                  value={amountPayed}
                  onChange={(e) => setAmountPayed(e.target.value)}
                // onKeyDown={handleKeyDown}
                />
                <p>
                  Debe entregar
                  {' '}
                  <span className="change-amount">
                    {calculateChange()}
                  </span>
                  {' '}
                  de vuelto
                </p>
              </>
            ) : null}
          <div>
            <div className="tabs-payment">
              {window.AppPagoSDK ? (
                <>
                  {store.tbk_smart_pos_active ? (
                    <button
                      disabled={paymentLoading}
                      type="button"
                      className="pay-button"
                      onClick={() => handleSmartPOSPayment()}
                      style={{ fontWeight: 'bold' }}
                    >
                      PAGAR
                    </button>
                  ) : <span className="error-message">Tienda no habilitada</span>}
                </>
              ) : (
                <button disabled={paymentLoading} type="button" className="pay-button" onClick={() => handleRedelcomPayment()}>
                  Pagar con Redelcom
                </button>
              )}
            </div>
          </div>
          <div className="tabs-payment">
            {(!isBack)
              ? <ChangePayMethod step={step} payments={payments} setPayments={setPayments} />
              : ''}
            <CancelPay paids={paids} />
          </div>
          <div className="tabs-payment">
            {`Paso ${step + 1} de ${totalSteps}`}
          </div>
        </div>
      )}
    </div>
  );
}

export default CashPayment;
