/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import axios from '../../utils/axios';
import {
  STATUS_APROBADO,
  STATUS_EXPIRADO,
  STATUS_PENDIENTE,
  STATUS_RECHAZADO,
} from '../../utils/const';
import thousandSeparator from '../../utils/thousandSeparator';

const MachPayment = ({
  amount,
  orderId,
  handlePayed,
}) => {
  const [machQr, setMachQr] = useState(null);
  const [machPaymentId, setMachPaymentId] = useState(0);
  const [loading, setLoading] = useState(false);

  const cancel = async (
    msg = 'Pago expirado o rechazado. Intentar nuevamente.',
  ) => {
    try {
      setLoading(true);
      if (machPaymentId) {
        const { data } = await axios.post(`/api/mach/cancel/${machPaymentId}`);
        const cancelled = data.data.data;
        if (cancelled.paid) {
          handlePayed(null);
        } else {
          handlePayed(msg);
        }
      }
    } catch {
      handlePayed('Hubo un error, intentar nuevamente.');
    }
    setLoading(false);
  };

  const machPaymentMonitoring = async (id) => {
    let pendingStatus = true;
    let timePassed = 0;

    let response = {
      success: false,
      message: 'Ha ocurrido un error',
    };

    try {
      while (pendingStatus) {
        const { data } = await axios.get(`/api/mach/monitoring/${id}`);
        await new Promise((resolve) => setTimeout(resolve, 5000));
        timePassed += 5;

        if (timePassed >= 1260) {
          await axios.post(`/api/mach/cancel/${id}`);
          response = {
            success: false,
            message: 'El pago fue rechazado.',
          };
          pendingStatus = false;
          break;
        }

        const { status } = data.data;

        if (status !== STATUS_PENDIENTE) {
          switch (status) {
            case STATUS_APROBADO:
              response = {
                success: true,
                message: 'El pago fue aprobado.',
              };
              break;
            case STATUS_RECHAZADO:
              response = {
                success: false,
                message: 'El pago fue rechazado.',
              };
              break;
            case STATUS_EXPIRADO:
              response = {
                success: false,
                message: 'El pago está expirado.',
              };
              break;
            default:
              response = {
                success: false,
                message: 'Hubo un problema con el pago y fue rechazado.',
              };
              break;
          }
          pendingStatus = false;
        }
      }

      if (!response.success) handlePayed(response.message, 'reset');
      else handlePayed(null);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      handlePayed(errorMessage);
    }
  };

  const init = async () => {
    setLoading(true);
    try {
      const machPayment = await axios.post('/api/mach/new', {
        amount,
        orderId,
      });
      if (machPayment.data.success) {
        const { id } = machPayment.data.data;
        setMachPaymentId(id);
        const { data } = await axios.get(`/api/mach/qr/${id}`, { responseType: 'arraybuffer' });
        const blob = new Blob([new Uint8Array(data)], { type: 'image/png' });
        const blobUrl = URL.createObjectURL(blob);
        setMachQr(blobUrl);
        setLoading(false);

        machPaymentMonitoring(id);
      } else {
        await cancel();
      }
    } catch {
      await cancel();
    }
  };

  useEffect(() => {
    init();
  }, [orderId]);

  return (
    <>
      {loading ? (
        <p style={{ textAlign: 'center' }}>
          <b>Cargando...</b>
        </p>
      ) : (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          <h1>Pago MACH</h1>
          <h2>{`$${thousandSeparator(amount, '.')}`}</h2>
          <h3>
            Escanea el QR con tu aplicación de MACH
          </h3>
          <img width="300px" height="300px" src={machQr} alt="QR Code" />
          <button
            type="button"
            onClick={() => cancel('Pago cancelado')}
            className="reset-qr"
          >
            Cancelar
          </button>
        </div>
      )}
    </>
  );
};

export default MachPayment;
