export const SET_CUSTOMER = '@customer/set-customer';
export const SET_CUSTOMER_TYPE = '@customer/set-customer-type';
export const UPDATE_CUSTOMER_CART = '@customer/update-customer-cart';
export const ADD_PRODUCT = '@customer/add_product';
export const REMOVE_PRODUCT = '@customer/remove_product';
export const RESET_CART = '@customer/reset_cart';
export const UPDATE_CART_PRODUCT = '@customer/update_cart_product';
export const RESET_CUSTOMER = '@customer/reset_customer';
export const RESET_CUSTOMER_TYPE = '@customer/reset_customer_type';
export const SET_RETURN_TYPE = '@customer/set-return-type';
export const RESET_RETURN_TYPE = '@customer/reset-return-type';
export const SET_RETURN_CODE = '@customer/set-return-code';
export const RESET_RETURN_CODE = '@customer/reset-return-code';
export const SET_CUSTOMER_ORDER_ID = '@customer/set_customer_order_id';
export const RESET_CUSTOMER_ORDER_ID = '@customer/reset_customer_order_id';
export const SET_CUSTOMER_ORDER_NUMBER = '@customer/set_customer_order_number';
export const RESET_CUSTOMER_ORDER_NUMBER = '@customer/reset_customer_order_number';
export const SET_RETURN_PRODUCTS = '@customer/set-return-products';
export const RESET_RETURN_PRODUCTS = '@customer/reset-return-products';
export const SET_DOCUMENT_TYPE = '@customer/set_document_type';
export const RESET_DOCUMENT_TYPE = '@customer/reset_document_type';
export const SET_BILLING_INFO = '@customer/set-billing_info';
export const RESET_BILLING_INFO = '@customer/reset-billing_info';
export const SET_RETURN_ID = '@customer/set_return_id';
export const RESET_RETURN_ID = '@customer/reset_return_id';
export const SET_ORDER_STATUS = '@customer/set_order_status';
export const RESET_ORDER_STATUS = '@customer/reset_order_status';
export const SET_SUBTOTAL = '@customer/set_subtotal';
export const RESET_SUBTOTAL = '@customer/reset_subtotal';
export const SET_COUPON = '@customer/set_coupon';
export const RESET_COUPON = '@customer/reset_coupon';
export const SET_COUPON_DISCOUNT = '@customer/set_coupon_discount';
export const RESET_COUPON_DISCOUNT = '@customer/reset_coupon_discount';
// export const SET_GENERAL_DISCOUNT = '@customer/set_general_discount';
export const RESET_GENERAL_DISCOUNT = '@customer/reset_general_discount';
export const SET_TOTAL = '@customer/set_total';
export const RESET_TOTAL = '@customer/reset_total';
export const SET_BULK = '@customer/set_bulk';
export const RESET_BULK = '@customer/reset_bulk';
export const SET_APPLY_BULK = '@customer/set_apply_bulk';
export const RESET_APPLY_BULK = '@customer/reset_apply_bulk';
export const ADD_PAYMENT = '@customer/add_payment';
export const UPDATE_PAYMENT = '@customer/update_payment';
export const REMOVE_PAYMENT = '@customer/remove_payment';
export const RESET_PAYMENTS = '@customer/reset_payment';
export const SMS_SENT = '@customer/sms_sent';
export const SET_CUSTOMER_POINTS = '@customer/set_customer_points';
export const ADD_RECOMMENDED_PRODUCT = '@customer/add_recommended_product';
export const RESET_RECOMMENDED_PRODUCT = '@customer/reset_recommended_product';
export const SET_ALL_DISCOUNTS = '@customer/set_all_discounts';
export const RESET_ALL_DISCOUNTS = '@customer/reset_all_discounts';
export const SET_CART_LOADING = '@customer/set_cart_loading';
export const RESET_CART_LOADING = '@customer/reset_cart_loading';
export const SET_PAYMENT_LOADING = '@customer/set_payment_loading';
export const SET_RETURN_SMART_POS_TRANSACTION_ID = '@customer/set_return_smart_pos_transaction_id';
export const RESET_RETURN_SMART_POS_TRANSACTION_ID = '@customer/reset_return_smart_pos_transaction_id';
export const SET_RECOMMENDED_PRODUCTS_LOADED = '@customer/set_recommended_products_loaded';

export function resetCustomer() {
  return (dispatch) => dispatch({
    type: RESET_CUSTOMER,
  });
}

export function resetCustomerType() {
  return (dispatch) => dispatch({
    type: RESET_CUSTOMER_TYPE,
  });
}

export function setCustomerData(customer) {
  return (dispatch) => dispatch({
    type: SET_CUSTOMER,
    payload: {
      customer,
    },
  });
}

export function setCustomerType(type) {
  return (dispatch) => dispatch({
    type: SET_CUSTOMER_TYPE,
    payload: {
      type,
    },
  });
}

export function setOrderStatus(status) {
  return (dispatch) => dispatch({
    type: SET_ORDER_STATUS,
    payload: {
      status,
    },
  });
}

export function resetOrderStatus() {
  return (dispatch) => dispatch({
    type: RESET_ORDER_STATUS,
  });
}

export function resetCustomerOrderId() {
  return (dispatch) => dispatch({
    type: RESET_CUSTOMER_ORDER_ID,
  });
}

export function setCustomerOrderId(orderId) {
  return (dispatch) => dispatch({
    type: SET_CUSTOMER_ORDER_ID,
    payload: {
      orderId,
    },
  });
}

export function setCustomerOrderNumber(orderNumber) {
  return (dispatch) => dispatch({
    type: SET_CUSTOMER_ORDER_NUMBER,
    payload: {
      orderNumber,
    },
  });
}

export function resetCustomerOrderNumber() {
  return (dispatch) => dispatch({
    type: RESET_CUSTOMER_ORDER_NUMBER,
  });
}

export function addToCart(
  product,
  amount,
  prices,
  maxQuantities,
) {
  return (dispatch) => dispatch({
    type: ADD_PRODUCT,
    payload: {
      product,
      prices,
      amount,
      maxQuantities,
    },
  });
}

export function removeFromCart(productId) {
  return (dispatch) => dispatch({
    type: REMOVE_PRODUCT,
    payload: {
      productId,
    },
  });
}

export function resetCart() {
  return (dispatch) => dispatch({
    type: RESET_CART,
  });
}

export function updateCartProduct(item) {
  return (dispatch) => dispatch({
    type: UPDATE_CART_PRODUCT,
    payload: {
      item,
    },
  });
}

export function setReturnType(type) {
  return (dispatch) => dispatch({
    type: SET_RETURN_TYPE,
    payload: {
      type,
    },
  });
}

export function resetReturnType() {
  return (dispatch) => dispatch({
    type: RESET_RETURN_TYPE,
  });
}

export function setReturnCode(code) {
  return (dispatch) => dispatch({
    type: SET_RETURN_CODE,
    payload: {
      code,
    },
  });
}

export function resetReturnCode() {
  return (dispatch) => dispatch({
    type: RESET_RETURN_CODE,
  });
}

export function setReturnProducts(products) {
  return (dispatch) => dispatch({
    type: SET_RETURN_PRODUCTS,
    payload: {
      products,
    },
  });
}

export function resetReturnProducts() {
  return (dispatch) => dispatch({
    type: RESET_RETURN_PRODUCTS,
  });
}

export function setDocumentType(document) {
  return (dispatch) => dispatch({
    type: SET_DOCUMENT_TYPE,
    payload: {
      document,
    },
  });
}

export function resetDocumentType() {
  return (dispatch) => dispatch({
    type: RESET_DOCUMENT_TYPE,
  });
}

export function setBillingInfo(billing) {
  return (dispatch) => dispatch({
    type: SET_BILLING_INFO,
    payload: {
      billing,
    },
  });
}

export function resetBillingInfo() {
  return (dispatch) => dispatch({
    type: RESET_BILLING_INFO,
  });
}

export function resetReturnId() {
  return (dispatch) => dispatch({
    type: RESET_RETURN_ID,
  });
}

export function setReturnId(returnId) {
  return (dispatch) => dispatch({
    type: SET_RETURN_ID,
    payload: {
      returnId,
    },
  });
}

export function setSubtotal(subtotal) {
  return (dispatch) => dispatch({
    type: SET_SUBTOTAL,
    payload: {
      subtotal,
    },
  });
}

export function resetSubtotal() {
  return (dispatch) => dispatch({
    type: RESET_SUBTOTAL,
  });
}

export function setCoupon(coupon) {
  return (dispatch) => dispatch({
    type: SET_COUPON,
    payload: {
      coupon,
    },
  });
}

export function resetCoupon() {
  return (dispatch) => dispatch({
    type: RESET_COUPON,
  });
}

export function setCouponDiscount(discount) {
  return (dispatch) => dispatch({
    type: SET_COUPON_DISCOUNT,
    payload: {
      discount,
    },
  });
}

export function resetCouponDiscount() {
  return (dispatch) => dispatch({
    type: RESET_COUPON_DISCOUNT,
  });
}

// export function setGeneralDiscount(discount) {
//   return (dispatch) => dispatch({
//     type: SET_GENERAL_DISCOUNT,
//     payload: {
//       discount,
//     },
//   });
// }

export function resetGeneralDiscount() {
  return (dispatch) => dispatch({
    type: RESET_GENERAL_DISCOUNT,
  });
}

export function setTotal(total) {
  return (dispatch) => dispatch({
    type: SET_TOTAL,
    payload: {
      total,
    },
  });
}

export function resetTotal() {
  return (dispatch) => dispatch({
    type: RESET_TOTAL,
  });
}

export function setBulk(bulk) {
  return (dispatch) => dispatch({
    type: SET_BULK,
    payload: {
      bulk,
    },
  });
}

export function resetBulk() {
  return (dispatch) => dispatch({
    type: RESET_BULK,
  });
}

export function setApplyBulk(bulk) {
  return (dispatch) => dispatch({
    type: SET_APPLY_BULK,
    payload: {
      bulk,
    },
  });
}

export function resetApplyBulk() {
  return (dispatch) => dispatch({
    type: RESET_APPLY_BULK,
  });
}

export function addPayment(payment) {
  return (dispatch) => dispatch({
    type: ADD_PAYMENT,
    payload: {
      payment,
    },
  });
}

export function updatePayment(payment) {
  return (dispatch) => dispatch({
    type: UPDATE_PAYMENT,
    payload: {
      payment,
    },
  });
}

export function removePayment(payment) {
  return (dispatch) => dispatch({
    type: REMOVE_PAYMENT,
    payload: {
      payment,
    },
  });
}

export function resetPayments() {
  return (dispatch) => dispatch({
    type: RESET_PAYMENTS,
  });
}

export function markSmsSent(smsSent) {
  return (dispatch) => dispatch({
    type: SMS_SENT,
    payload: smsSent,
  });
}

export function setCustomerPoints(points) {
  return (dispatch) => dispatch({
    type: SET_CUSTOMER_POINTS,
    payload: points,
  });
}

export function addRecommendedProduct(productId) {
  return (dispatch) => dispatch({
    type: ADD_RECOMMENDED_PRODUCT,
    payload: productId,
  });
}

export function resetRecommendedProduct() {
  return (dispatch) => dispatch({
    type: RESET_RECOMMENDED_PRODUCT,
  });
}

export function setAllDiscounts(discounts) {
  return (dispatch) => dispatch({
    type: SET_ALL_DISCOUNTS,
    payload: discounts,
  });
}

export function resetAllDiscounts() {
  return (dispatch) => dispatch({
    type: RESET_ALL_DISCOUNTS,
  });
}

export function setCartLoading(cartLoading) {
  return (dispatch) => dispatch({
    type: SET_CART_LOADING,
    payload: cartLoading,
  });
}

export function resetCartLoading() {
  return (dispatch) => dispatch({
    type: RESET_CART_LOADING,
  });
}

export function setPaymentLoading(paymentLoading) {
  return (dispatch) => dispatch({
    type: SET_PAYMENT_LOADING,
    payload: paymentLoading,
  });
}

export function setReturnSmartPOSTransactionId(transactionId) {
  return (dispatch) => dispatch({
    type: SET_RETURN_SMART_POS_TRANSACTION_ID,
    payload: { transactionId },
  });
}

export function resetReturnSmartPOSTransactionId() {
  return (dispatch) => dispatch({
    type: RESET_RETURN_SMART_POS_TRANSACTION_ID,
  });
}

export function setRecommendedProductsLoaded(status) {
  return (dispatch) => dispatch({
    type: SET_RECOMMENDED_PRODUCTS_LOADED,
    payload: status,
  });
}
