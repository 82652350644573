/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ClickNHold from 'react-click-n-hold';
import { connect } from 'react-redux';

import thousandSeparator from '../../utils/thousandSeparator';

import {
  BUY_TYPE_RAPPI,
  BUY_TYPE_CORNERSHOP,
  BUY_TYPE_UBEREATS,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_RAPPI_TURBO,
  BUY_TYPE_GOODMEAL,
  BUY_TYPE_RAPPI_PAYLESS,
} from '../../utils/const';
import { getSalePrices } from '../../utils/getPrice';

const DEFAULT_TIMEOUT = 200;
const DEFAULT_START_TIME = 2;

class ProductItem extends React.Component {
  constructor(props) {
    super(props);

    this.t = null;
    this.state = {
      quantity: props.amount,
    };

    this.clearRepeat = this.clearRepeat.bind(this);
    this.repeat = this.repeat.bind(this);
    this.increase = this.increase.bind(this);
    this.decrease = this.decrease.bind(this);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.amount !== this.props.amount) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ quantity: this.props.amount });
    }
  }

  increase() {
    const {
      product,
      getStock,
    } = this.props;
    if (this.state.quantity + 1 <= getStock(product)) {
      this.setState({
        quantity: this.state.quantity + 1,
      });
    }
  }

  decrease() {
    const {
      quantity,
    } = this.state;

    if (quantity === 1) {
      return;
    }

    this.setState({
      quantity: this.state.quantity - 1,
    });
  }

  repeat(fn) {
    fn();
    this.t = setTimeout(() => this.repeat(fn), DEFAULT_TIMEOUT);
  }

  clearRepeat() {
    clearTimeout(this.t);

    const {
      subtractFromProduct,
      addToProduct,
      product,
      amount,
    } = this.props;

    const {
      quantity,
    } = this.state;

    if (quantity === amount) {
      return;
    }

    if (quantity > amount) {
      addToProduct(product.id, quantity - amount);
      return;
    }

    subtractFromProduct(product.id, amount - quantity);
  }

  addProductToCart() {
    const {
      addProductToCart,
      product,
      amount,
    } = this.props;

    addProductToCart(product, amount);
  }

  render() {
    const {
      priceList,
      product,
      store,
      recommendedProduct,
      buyType,
      getStock,
    } = this.props;

    const {
      sellout,
      recommended,
      bulk,
    } = getSalePrices(product, store, buyType);

    const bulkPriceList = store.bulk_price_list_id;
    let defaultPriceObj;
    let bulkPriceObj;
    if (product.ProductPrices) {
      defaultPriceObj = product.ProductPrices.filter((p) => (p.price_list_id === priceList));
      bulkPriceObj = product.ProductPrices.filter((p) => (p.price_list_id === bulkPriceList));
    } else if (product.prices) {
      defaultPriceObj = product.prices.filter((price) => (price.price_list_id === priceList));
      bulkPriceObj = product.prices.filter((price) => (price.price_list_id === bulkPriceList));
    } else {
      return (<div />);
    }

    if (defaultPriceObj.length < 1) {
      return (<div className="no-price">{`${product.name} no tiene precio`}</div>);
    }

    if (bulkPriceObj.length < 1) {
      return (<div className="no-price">{`${product.name} no tiene precio mayorista`}</div>);
    }

    const defaultPrice = defaultPriceObj[0].price;
    // const bulkPrice = bulkPriceObj[0].price;

    const {
      quantity,
    } = this.state;

    const renderPrices = () => {
      if (
        buyType === BUY_TYPE_RAPPI
        || buyType === BUY_TYPE_RAPPI_TURBO
        || buyType === BUY_TYPE_RAPPI_PAYLESS
        || buyType === BUY_TYPE_PEDIDOSYA
        || buyType === BUY_TYPE_UBEREATS
        || buyType === BUY_TYPE_CORNERSHOP
        || buyType === BUY_TYPE_GOODMEAL
      ) {
        return (
          <p>
            $
            {thousandSeparator(defaultPrice, '.')}
          </p>
        );
      }

      if (product.sellout.id && product.sellout.stock > 0) {
        if (
          recommendedProduct
          && recommendedProduct === product.id
          && product.recommended_price
        ) {
          return (
            <p style={{ color: 'red' }}>
              Recomendado: $
              {thousandSeparator(recommended.price, '.')}
            </p>
          );
        }
        return (
          <p style={{ color: 'var(--global--secondary--BackgroundColor)' }}>
            Liquidación: $
            {thousandSeparator(sellout.price, '.')}
          </p>
        );
      }

      if (
        recommendedProduct
        && recommendedProduct === product.id
        && product.recommended_price
      ) {
        return (
          <p style={{ color: 'red' }}>
            Recomendado: $
            {thousandSeparator(recommended.price, '.')}
          </p>
        );
      }

      return (
        <p>
          $
          {thousandSeparator(defaultPrice, '.')}
          {' '}
          <span className="bulk-price-text">
            - M: $
            {thousandSeparator(bulk.price, '.')}
          </span>
        </p>
      );
    };

    const renderStock = () => {
      if (
        buyType === BUY_TYPE_RAPPI
        || buyType === BUY_TYPE_RAPPI_TURBO
        || buyType === BUY_TYPE_RAPPI_PAYLESS
        || buyType === BUY_TYPE_PEDIDOSYA
        || buyType === BUY_TYPE_UBEREATS
        || buyType === BUY_TYPE_CORNERSHOP
        || buyType === BUY_TYPE_GOODMEAL
      ) {
        return (
          <p className="stock-text">
            Stock:
            {thousandSeparator(getStock(product), '.')}
          </p>
        );
      }

      if (product.sellout.id && product.sellout.stock > 0) {
        return (
          <p className="stock-text" style={{ color: 'var(--global--secondary--BackgroundColor)' }}>
            Stock Liquidación:
            {thousandSeparator(getStock(product), '.')}
          </p>
        );
      }

      return (
        <p className="stock-text">
          Stock:
          {thousandSeparator(getStock(product), '.')}
        </p>
      );
    };

    return ([
      <div style={{ minHeight: '80px' }}>
        <div
          className="amount-cell"
          onTouchEnd={() => this.clearRepeat()}
          onMouseUp={() => this.clearRepeat()}
          onBlur={() => this.clearRepeat()}
        >
          <ClickNHold
            time={DEFAULT_START_TIME}
            onClickNHold={() => this.repeat(this.decrease)}
            onEnd={this.clearRepeat}
          >
            <button onClick={this.decrease} className="add-subtract" type="button">-</button>
          </ClickNHold>
          <div>{quantity}</div>
          <ClickNHold
            time={DEFAULT_START_TIME}
            onClickNHold={() => this.repeat(this.increase)}
            onEnd={this.clearRepeat}
          >
            <button onClick={this.increase} className="add-subtract" type="button">+</button>
          </ClickNHold>
        </div>
        <div className="info">
          <p className="product-name">{product.name}</p>
          {renderPrices()}
          {renderStock()}
        </div>
        <button type="button" className="yellow-button" onClick={() => this.addProductToCart()}>
          Agregar
        </button>
      </div>,
      <hr style={{ width: '100%' }} />,
    ]);
  }
}

const mapStateToProps = ({ customer }) => ({
  cart: customer.cart,
});

export default connect(mapStateToProps)(ProductItem);
