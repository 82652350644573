import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup, Popover,
  Typography,
} from '@material-ui/core';
import Page from '../../components/Layout/Page';
import './Return.css';
import ReturnList from '../../components/Cart/ReturnList';
import { logout } from '../../actions/accountActions';
import {
  setReturnId,
  setReturnSmartPOSTransactionId,
} from '../../actions/customerActions';
import axios from '../../utils/axios';
import { STATUS_BILLING_PROCESSING } from '../../utils/const';

function ReturnCart() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const customer = useSelector((state) => state.customer);
  const returnProducts = useSelector((state) => state.customer.return_products);
  const orderNumber = useSelector((state) => state.customer.return_code);
  const smartPOSTransactionNumber = useSelector(
    (state) => state.customer.return_smart_pos_transaction_id,
  );
  const [scrolled, setScrolled] = useState(false);
  const [amounts, setAmounts] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.account);
  const { machineNumber } = useSelector((state) => state.session);
  const [retError, setRetError] = useState(null);
  const [checkbox, setCheckbox] = useState({ yes: false, no: false });
  const [annulmentError, setAnnulmentError] = useState('');

  const clickLogout = () => {
    dispatch(logout());
  };

  const smartPOSRequestForAnnulment = (transactionNumber) => {
    window.AppPagoSDK.requestForAnnulment(transactionNumber);
  };

  // SmartPOS annulment response
  window.annulmentResponse = (resp) => {
    if (resp?.codigoRespuesta === '0') {
      const newSmartPOSTransactionNumber = smartPOSTransactionNumber.filter(
        (t) => t !== resp.numeroOperacion,
      );
      dispatch(setReturnSmartPOSTransactionId(newSmartPOSTransactionNumber));
      if (newSmartPOSTransactionNumber.length) {
        smartPOSRequestForAnnulment(newSmartPOSTransactionNumber[0]);
      } else {
        history.push('/final-devolucion');
      }
    } else if (resp?.codigoRespuesta !== '0') {
      let errorMessage = resp?.glosaRespuesta || 'Ha ocurrido un error';
      const codigoRespuesta = resp?.codigoRespuesta || '';
      if (codigoRespuesta) errorMessage += `. Código ${codigoRespuesta}`;
      setAnnulmentError(errorMessage);
    }
  };

  const submitChange = () => {
    if (!checkbox.yes && !checkbox.no) {
      const key = enqueueSnackbar('Debe seleccionar una opción de movimiento de efectivo', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      setOpen(true);
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    const returnObj = {};
    const products = Object.keys(amounts).filter((k) => amounts[k] > 0).map((prodId) => ({
      id: prodId,
      amount: amounts[prodId],
    }));

    if (products.length > 0) {
      returnObj.order_id = orderNumber;
      returnObj.products = products;
      returnObj.machine_id = machineNumber;
      returnObj.cash_movement = checkbox.yes;
      returnObj.smart_pos = !!window.AppPagoSDK;
      const res = await axios.post('/api/returns/new', returnObj);
      if (res.data.code === 201) {
        setLoading(false);
        dispatch(setReturnId(res.data.data.return.id));
        // smartPOS annulment *annuls the entire sale*
        // if is enable ensure that window.annulmentResponse is uncommented
        // if (smartPOSTransactionNumber.length) {
        //   smartPOSRequestForAnnulment(smartPOSTransactionNumber[0]);
        //   setOpen(false);
        // } else {
        //   history.push('/final-devolucion');
        // }
        history.push('/final-devolucion');
      } else {
        const errorMessage = res.data?.data?.errorMessage || 'Error generando nota de crédito. Por favor intente nuevamente.';
        setRetError(errorMessage);
      }
    } else {
      const key = enqueueSnackbar('Debe devolver al menos 1 producto', {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > 45;
      if (scrollCheck !== scrolled) {
        setScrolled(scrollCheck);
      }
    });
  });

  const popoverContent = () => (
    <div className="entry-popover-container">
      <div className="popover-info-message return-confirm">
        <p>Estas seguro?</p>
      </div>
      <div className="entry-confirmation-buttons">
        <button disabled={loading} className="accept-entry-button" type="submit" onClick={() => handleConfirm()}>Aceptar</button>
        <button className="notmember-entry-button" type="submit" onClick={() => { handleClose(); }}>Cancelar</button>
      </div>
    </div>
  );

  const popoverSmartPOSError = () => (
    <div className="entry-popover-container">
      <div className="popover-info-message return-confirm">
        <p>{annulmentError}</p>
      </div>
      <div className="entry-confirmation-buttons">
        <button
          disabled={loading}
          className="accept-entry-button"
          type="submit"
          onClick={() => {
            smartPOSRequestForAnnulment(smartPOSTransactionNumber[0]);
            setAnnulmentError('');
          }}
        >
          Reintentar
        </button>
        <button
          className="notmember-entry-button"
          type="submit"
          onClick={() => {
            setAnnulmentError('');
            history.push('/final-devolucion');
          }}
        >
          Cancelar
        </button>
      </div>
    </div>
  );

  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    const currentValue = checkbox[name];
    setCheckbox({ [name]: !currentValue, [name === 'yes' ? 'no' : 'yes']: false });
  };

  if (customer.order_id
    && (customer.order_status === STATUS_BILLING_PROCESSING)) {
    return (<Redirect to="pago-final" />);
  }

  return (
    <Page
      title="Club Líquidos | Devoluciones"
      progressStep="none"
      backRef="/devolucion"
    >
      {loading ? (
        <div style={{ paddingTop: '150px' }}>
          <h1 style={{ textAlign: 'center' }}>Generando Nota de Crédito</h1>
          {retError ? null : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress
                style={{ color: 'var(--global--secondary--BackgroundColor' }}
              />
            </div>
          )}
          {retError ? (
            <div className="return-cart-container">
              <p style={{ color: 'red', textAlign: 'center', padding: '10px' }}>{retError}</p>
              <div className="return-button-container">
                <button
                  type="button"
                  className="yellow-button longer-button"
                  onClick={() => window.location.reload()}
                >
                  Reintentar
                </button>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="return-cart-container">
          <div className="header">
            <p>{`Vendedor: ${user.name}`}</p>
            <button type="button" className="logout" onClick={() => clickLogout()}>Cerrar Sesión</button>
          </div>
          <ReturnList
            scrolled={scrolled}
            products={returnProducts || []}
            amounts={amounts}
            setAmounts={setAmounts}
          />
          <div>
            <Typography>¿Genera movimiento de efectivo?</Typography>
            <FormGroup style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginBottom: '10px',
            }}
            >
              <FormControlLabel
                control={(
                  <Checkbox
                    name="yes"
                    checked={checkbox.yes}
                    onChange={(e) => handleCheckboxChange(e)}
                    style={{ color: 'var(--global--secondary--BackgroundColor)' }}
                  />
                )}
                label="Si"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    name="no"
                    checked={checkbox.no}
                    onChange={(e) => handleCheckboxChange(e)}
                    style={{ color: 'var(--global--secondary--BackgroundColor)' }}
                  />
                )}
                label="No"
              />
            </FormGroup>
          </div>
          <div className="return-button-container">
            <button type="button" className="yellow-button longer-button" onClick={() => submitChange()}>
              Realizar Devolución
            </button>
          </div>
          <Popover
            open={open}
            onClose={() => handleClose()}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            {popoverContent()}
          </Popover>
          <Popover
            open={!!annulmentError}
            onClose={() => setAnnulmentError('')}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            {popoverSmartPOSError()}
          </Popover>
        </div>
      )}
    </Page>
  );
}

export default ReturnCart;
