import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import Page from '../../components/Layout/Page';
import './Return.css';
import {
  setReturnCode,
  setReturnProducts,
  resetReturnProducts,
  resetReturnCode,
  setReturnSmartPOSTransactionId,
} from '../../actions/customerActions';
import axios from '../../utils/axios';
import { STATUS_BILLING_PROCESSING } from '../../utils/const';

function ReturnEntry() {
  const dispatch = useDispatch();

  const customer = useSelector((state) => state.customer);
  const [code, setCode] = useState('');
  const returnType = useSelector((state) => state.customer.returnType);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(resetReturnProducts());
    dispatch(resetReturnCode());
  }, [dispatch]);

  const submitCode = async () => {
    setError('');
    if (!loading) {
      setLoading(true);
      try {
        const response = await axios.get(`/api/order-by-bill/${code}`);

        if (response.data.code === 200) {
          const returnProducts = response.data.data.order.OrderProducts;

          const orderId = response.data.data.order.id;
          dispatch(setReturnCode(orderId));
          const payments = await axios.get(`/api/payments/order/${orderId}`);
          if (payments.data?.data?.length) {
            // Check if the payment were made in smart pos
            const smartPOSTransactionIds = [];
            payments.data.data.forEach((p) => {
              if (p.smart_pos_transaction_id) {
                smartPOSTransactionIds.push(p.smart_pos_transaction_id);
              }
            });
            if (smartPOSTransactionIds.length) {
              dispatch(setReturnSmartPOSTransactionId(smartPOSTransactionIds));
            }
          }

          const returnFinal = [];
          if (response.data.data.order.Returns.length > 0) {
            const amounts = {};
            response.data.data.order.Returns.forEach((returnOrder) => {
              // revisamos cada return dentro
              returnOrder.ReturnProducts.forEach((product) => {
                // Vemos cada producto
                amounts[product.product_id] = amounts[product.product_id]
                  ? product.amount + amounts[product.product_id] : product.amount;
              });
            });
            returnProducts.forEach((prod) => {
              const product = amounts[prod.product_id]
                ? { ...prod, amount: prod.amount - amounts[prod.product_id] }
                : { ...prod, amount: prod.amount };
              returnFinal.push(product);
            });
            dispatch(setReturnProducts(returnFinal));
          } else {
            dispatch(setReturnProducts(returnProducts));
          }
          window.location.href = '/carro-devolucion';
        } else {
          setError('El código ingresado no existe.');
        }
      } catch (err) {
        const errorMessage = err.response.data.errorMessage || err.message;
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !loading) {
      submitCode();
    }
  };

  const handleChange = (event) => {
    setCode(event.target.value);
  };

  if (customer.order_id
    && (customer.order_status === STATUS_BILLING_PROCESSING)) {
    return (<Redirect to="pago-final" />);
  }

  return (
    <Page
      title="Club Líquidos | Devoluciones"
      progressStep="none"
      backRef="/tipo-devolucion"
    >
      <div className="return-container">
        <h1>
          Ingresar Datos
          {returnType}
        </h1>
        <div className="code-form-field">
          <input id="code" type="text" name="code" value={code} onChange={(e) => handleChange(e)} onKeyDown={handleKeyDown} placeholder="Código" />
        </div>
        <button disabled={loading} type="button" className="yellow-button" onClick={() => submitCode()}>
          {loading ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : 'Enviar'}
        </button>
        <p className="error-message">{error}</p>
      </div>
    </Page>
  );
}

export default ReturnEntry;
