import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from '../../components/Layout/Page';
import './Login.css';
import { login } from '../../actions/accountActions';

const { validate, clean } = require('rut.js');

function Login({ version }) {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [formInputs, setFormInputs] = useState({
    rut: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  // const storeId = useSelector((state) => state.account.user.store_id);

  const submitLogin = async () => {
    setError('');
    try {
      // Clean Rut
      if (!validate(formInputs.rut)) {
        setError('El RUT ingresado no es válido');
        return;
      }

      setLoading(true);
      await dispatch(login(clean(formInputs.rut), formInputs.password));
      setLoading(false);

      if (window.AppPagoSDK) window.location.href = '/inicio';
      else window.location.href = '/maquina';
    } catch (e) {
      const message = e.errorMessage || 'Ha ocurrido un error. Por favor intente nuevamente más tarde.';
      setError(message);
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !loading) {
      submitLogin();
    }
  };

  const handleChange = (event) => {
    setFormInputs({ ...formInputs, [event.target.name]: event.target.value });
  };

  return (
    <Page
      title="Club Líquidos | POS Login"
    >
      <div className="login-form">
        <img src="img/logo-liquidos-blanco.png" alt="logo" />
        <h1>Punto de Venta</h1>
        <div className="form-field">
          <label htmlFor="rut">
            Usuario (RUT)
            <input id="rut" type="text" name="rut" value={formInputs.rut} onChange={(e) => handleChange(e)} onKeyDown={handleKeyDown} />
          </label>
        </div>
        <div className="form-field">
          <label htmlFor="password">
            Contraseña
            <input id="password" type="password" name="password" value={formInputs.password} onChange={(e) => handleChange(e)} onKeyDown={handleKeyDown} />
          </label>
        </div>
        <button disabled={loading} type="submit" className="yellow-button" onClick={() => submitLogin()}>
          Ingresar
        </button>
        <p className="error-message">{error !== '' ? <div>{error}</div> : null}</p>
        <p className="version">{`v${version}`}</p>
      </div>
    </Page>
  );
}

export default Login;
