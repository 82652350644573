import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { markSmsSent } from '../../actions/customerActions';

import axios from '../../utils/axios';
import thousandSeparator from '../../utils/thousandSeparator';

function WalletPayment({
  handlePayed,
  payed,
  total,
  payment,
}) {
  const INITIAL_RESEND_TIMER = 30;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [verificationCode, setVerificationCode] = useState(null);
  const orderId = useSelector((state) => state.customer.order_id);
  const customerId = useSelector((state) => state.customer.user.id);
  const sended = useSelector((state) => state.customer.sms_sent);
  const storeId = useSelector((state) => state.session.store.id);
  const [resendTimer, setResendTimer] = useState(null);
  const [resendSupervisorTimer, setSupervisorResendTimer] = useState(null);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (payed) {
      handlePayed(null);
    }
  }, [payed, handlePayed]);

  useEffect(() => {
    const sendCode = async () => {
      try {
        const paymentData = {
          customer_id: customerId,
          amount: total,
        };
        await axios.post('/api/payments/send-code', paymentData);
      } catch {
        console.log('Error sending code');
      }
    };
    if (!sended) {
      dispatch(markSmsSent(true));
      sendCode();
    }
  }, []);

  const handlePayment = async () => {
    if (!verificationCode) {
      const key = enqueueSnackbar('Falta el código de verificación', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }
    setLoading(true);
    try {
      const resp = await axios.post('/api/payments/wallet/new', {
        order_id: orderId,
        amount: total,
        customer_id: customerId,
        code: verificationCode,
        paymentId: payment.id,
      });

      const { success, errorMessage } = (resp.data) ? resp.data.data : {};
      if (success) {
        handlePayed(null);
      } else {
        handlePayed(errorMessage || 'Algo salió mal');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || `Algo salió mal. ${error.message}`;
      handlePayed(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleResendCode = async () => {
    setLoading(true);
    try {
      const paymentData = {
        customer_id: customerId,
        amount: total,
      };
      await axios.post('/api/payments/send-code', paymentData);
      // Start countdown
      setResendTimer(INITIAL_RESEND_TIMER);
    } catch {
      const key = enqueueSnackbar('Ha ocurrido un error', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
    setLoading(false);
  };

  const handleSupervisorResendCode = async () => {
    setLoading(true);
    try {
      const paymentData = {
        store_id: storeId,
        customer_id: customerId,
        amount: total,
      };
      const resp = await axios.post('/api/payments/send-supervisor-code', paymentData);
      if (resp.data && !resp.data.success) {
        const errorMessage = resp.data.data.errorMessage || 'Ha ocurrido un error';
        const key = enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          onClick: () => {
            closeSnackbar(key);
          },
        });
      }
    } catch {
      const key = enqueueSnackbar('Ha ocurrido un error', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
    setLoading(false);

    // Start countdown
    setSupervisorResendTimer(INITIAL_RESEND_TIMER);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (resendTimer) {
      const currentTimer = resendTimer - 1;
      const interval = setInterval(() => {
        setResendTimer(currentTimer);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [resendTimer]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (resendSupervisorTimer) {
      const currentTimer = resendSupervisorTimer - 1;
      const interval = setInterval(() => {
        setSupervisorResendTimer(currentTimer);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [resendSupervisorTimer]);

  return (
    <div>
      <h1>Pago Puntos / Wallet</h1>
      <h2>
        $
        {thousandSeparator(total, '.')}
      </h2>
      <div className="rappi-payment-inputs">
        <input style={{ marginBottom: '15px' }} type="text" placeholder="Código SMS" value={verificationCode} onChange={handleChange} />
        <button disabled={loading} type="button" className="yellow-button" onClick={() => handlePayment()}>
          Pagado!
        </button>
        <div className="resend-buttons-wrapper">
          <button
            disabled={loading || resendTimer}
            type="button"
            className={resendTimer ? 'resend-button-disabled' : 'resend-button'}
            onClick={() => handleResendCode()}
          >
            {resendTimer ? `Reintentar en ${resendTimer}` : 'Reenviar código al cliente'}
          </button>
          <button
            disabled={loading || resendSupervisorTimer}
            type="button"
            className={resendSupervisorTimer ? 'resend-button-disabled' : 'resend-button'}
            onClick={() => handleSupervisorResendCode()}
          >
            {resendSupervisorTimer ? `Reintentar en ${resendSupervisorTimer}` : 'Reenviar código al email del supervisor'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default WalletPayment;
