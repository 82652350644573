import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
import { Zoom } from '@material-ui/core';
import './index.css';
import App from './App';
import { configureStore } from './store';
import * as serviceWorker from './serviceWorker';
import LoadingScreen from './components/Loading/LoadingScreen';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingScreen />} persistor={persistor}>
      <React.StrictMode>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          maxSnack={1}
          autoHideDuration={2500}
          preventDuplicate
          transitionDuration={{
            enter: 150,
            exit: 150,
          }}
          TransitionComponent={Zoom}
        >
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </SnackbarProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
