const getStock = (prod, cart = {}) => {
  // Unitary products
  if (!prod.pack) {
    let total = prod.sellout.id && prod.sellout.stock > 0
      ? prod.sellout.stock : prod.stock;
    // Check if the unit product is in the cart
    if (cart[prod.id]) {
      total -= cart[prod.id].amount;
    }
    // Check if the unit product is in the cart packs childs
    Object.keys(cart).forEach((c) => {
      if (cart[c].product.pack) {
        const child = cart[c].product.childs.find((cc) => cc.child_product_id === prod.id);
        if (child) {
          total -= child.child_quantity * cart[c].amount;
        }
      }
    });
    return total < 0 ? 0 : total;
  }

  // Pack products
  let childs = prod.childs.map((c) => ({ ...c }));
  // Check if the pack is in sellout
  if (prod.sellout.id && prod.sellout.stock > 0) {
    childs = childs.map((c) => ({
      ...c,
      child_stock: c.child_quantity * prod.sellout.stock,
    }));
  }
  // Check if a child of the pack is in the cart
  Object.keys(cart).forEach((c) => {
    // Check if the childs are in the cart as unit product
    const unitChild = childs.find((cc) => cc.child_product_id === Number(c));
    if (unitChild) {
      unitChild.child_stock -= cart[c].amount;
    }
    // Check if the childs are in the cart as a childs of a pack
    if (cart[c].product.pack) {
      const packChildsIds = childs.map((cc) => cc.child_product_id);
      const packChilds = cart[c].product.childs.filter(
        (cc) => packChildsIds.includes((cc.child_product_id)),
      );
      if (packChilds.length) {
        packChilds.forEach((pc) => {
          const childPack = childs.find(
            (cc) => cc.child_product_id === pc.child_product_id,
          );
          if (childPack) {
            childPack.child_stock -= pc.child_quantity * cart[c].amount;
          }
        });
      }
    }
  });
  // Return the min value
  const total = Math.min(
    ...childs.map((ch) => Math.floor(ch.child_stock / ch.child_quantity)),
  );
  return total < 0 ? 0 : total;
};

export default getStock;
