import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import Page from '../../components/Layout/Page';
import './Return.css';
import { setReturnType, resetReturnCode } from '../../actions/customerActions';
import { STATUS_BILLING_PROCESSING } from '../../utils/const';

function ReturnType() {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(resetReturnCode());
  }, [dispatch]);

  const onClick = (returnType) => {
    dispatch(setReturnType(returnType));
    window.location.href = '/devolucion';
  };

  if (customer.order_id
    && (customer.order_status === STATUS_BILLING_PROCESSING)) {
    return (<Redirect to="pago-final" />);
  }

  return (
    <Page
      title="Club Líquidos | Devoluciones"
      progressStep="none"
      backRef="/inicio"
    >
      <div className="return-container">
        <h1>Compra fue realizada con: </h1>
        <button type="button" className="yellow-button" onClick={() => onClick('Boleta')}>
          Boleta
        </button>
        <button type="button" className="yellow-button" onClick={() => onClick('Factura')}>
          Factura
        </button>
      </div>
    </Page>
  );
}

export default ReturnType;
