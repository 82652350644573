import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router';
import axios from '../../utils/axios';
import Page from '../../components/Layout/Page';
import './Buy.css';
import { logout } from '../../actions/accountActions';
import ProductList from '../../components/Cart/ProductList';
import ProductSlider from '../../components/Cart/Slider';
import SplashScreen from '../../components/Loading/SplashScreen';
import {
  resetDocumentType,
  resetPayments,
  // setOrderStatus,
  resetAllDiscounts,
  resetCartLoading,
  resetOrderStatus,
} from '../../actions/customerActions';
import {
  // STATUS_PENDIENTE,
  BUY_TYPE_RAPPI,
  BUY_TYPE_CORNERSHOP,
  BUY_TYPE_UBEREATS,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_RAPPI_TURBO,
  BUY_TYPE_GOODMEAL,
  BUY_TYPE_RAPPI_PAYLESS,
  STATUS_BILLING_PROCESSING,
} from '../../utils/const';
import SmartPOSCamera from '../../components/SmartPOSCamera';
import { setEANFromCamera } from '../../actions/sessionActions';

function Buy() {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const limitState = 20;
  const [pageLimit, setPageLimit] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const [scrolled, setScrolled] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.account);
  const customer = useSelector((state) => state.customer);
  const searchInput = useRef(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const orderId = useSelector((state) => state.customer.order_id);
  const orderStatus = useSelector((state) => state.customer.order_status);
  const [hasMore, setHasMore] = useState(true);
  const { machineNumber, eanFromCamera } = useSelector((state) => state.session);

  useEffect(() => {
    const getProducts = async () => {
      const response = await axios.get(`/api/products?store=${user.store_id}&type=${customer.type}`);
      setFilteredProducts(
        response.data.data.products,
      );
    };

    const getPopularProducts = async () => {
      const response = await axios.get(`/api/popular-products?store=${user.store_id}&type=${customer.type}`);
      setPopularProducts(response.data.data.products ? response.data.data.products : []);
    };

    const getResources = async () => {
      setLoading(true);
      await getProducts();
      await getPopularProducts();
      setLoading(false);
    };

    getResources();
  }, [user.store_id]);

  useEffect(() => {
    if (orderStatus !== STATUS_BILLING_PROCESSING) {
      dispatch(resetDocumentType());
      dispatch(resetPayments());
      dispatch(resetAllDiscounts());
      dispatch(resetCartLoading());
      dispatch(resetOrderStatus());
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > 200;
      if (scrollCheck !== scrolled) {
        setScrolled(scrollCheck);
      }
    });
  });

  const searchProducts = async (ean) => {
    setSearchLoading(true);
    const query = ean || searchQuery;
    const response = await axios.get(`/api/products?store=${user.store_id}&type=${customer.type}&query=${query}`);
    setFilteredProducts(response.data.data.products);
    setSearchLoading(false);
  };

  useEffect(() => {
    if (eanFromCamera) {
      setSearchQuery(eanFromCamera);
      searchProducts(eanFromCamera);
      dispatch(setEANFromCamera(''));
    }
  }, [eanFromCamera]);

  const goToCart = () => {
    window.location.href = '/carro';
  };

  const clickLogout = () => {
    dispatch(logout());
  };

  const getMoreProducts = async () => {
    const page = pageLimit + 1;
    setPageLimit(page);
    const getMoreProd = async () => {
      let url = `/api/products?store=${user.store_id}&type=${customer.type}&offset=${page * limitState}&limit=${limitState}`;
      if (searchQuery) {
        url = `/api/products?store=${user.store_id}&type=${customer.type}&query=${searchQuery}&offset=${page * limitState}&limit=${limitState}`;
      }
      await axios.get(url)
        .then((r) => {
          if (!r.data.data.products.length) setHasMore(false);
          else setFilteredProducts([...filteredProducts].concat(r.data.data.products));
        })
        .catch(() => {
          const key = enqueueSnackbar('Ha ocurrido un error al cargar más productos', {
            variant: 'error',
            onClick: () => {
              closeSnackbar(key);
            },
          });
        });
    };
    getMoreProd();
  };

  if (!customer.type) {
    window.location.href = '/inicio';
    return <SplashScreen />;
  }

  if (!customer.user && customer.type !== BUY_TYPE_CORNERSHOP
    && customer.type !== BUY_TYPE_UBEREATS
    && customer.type !== BUY_TYPE_RAPPI
    && customer.type !== BUY_TYPE_RAPPI_TURBO
    && customer.type !== BUY_TYPE_RAPPI_PAYLESS
    && customer.type !== BUY_TYPE_PEDIDOSYA
    && customer.type !== BUY_TYPE_GOODMEAL) {
    window.location.href = '/ingreso';
    return <SplashScreen />;
  }

  if (!machineNumber && !window.AppPagoSDK) {
    return (
      <Redirect to="maquina" />
    );
  }

  if (orderId && (
    orderStatus === STATUS_BILLING_PROCESSING
  )) {
    return (<Redirect to="pago-final" />);
  }

  return (
    <Page
      title="Club Líquidos | POS Compra"
      progressStep="compra"
      backRef="/perfil"
    >
      <div className="buy-container">
        <div className="header">
          <p>{`Vendedor: ${user.name}`}</p>
          <button type="button" onClick={() => clickLogout()} className="logout">Cerrar Sesión</button>
        </div>
        <div className="search-products">
          <div className="search-products-input">
            <input
              ref={searchInput}
              type="text"
              placeholder="Buscar por nombre, SKU o EAN"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
            <button type="button" onClick={() => searchProducts()}>
              {searchLoading ? (
                <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
              ) : (
                <img src="/img/search-icon.png" alt="Buscar" height="40" />
              )}
            </button>
          </div>
          <div className="search-options-wrapper">
            <div className="search-options">
              <button
                className="clear-search-button"
                style={{ width: '90%' }}
                type="button"
                onClick={() => { setSearchQuery(''); searchInput.current.focus(); }}
              >
                Limpiar
              </button>
              <SmartPOSCamera />
            </div>
          </div>
        </div>
        <ProductSlider
          enqueueSnackbar={enqueueSnackbar}
          closeSnackbar={closeSnackbar}
          products={popularProducts}
        />
        <ProductList
          enqueueSnackbar={enqueueSnackbar}
          closeSnackbar={closeSnackbar}
          scrolled={scrolled}
          products={filteredProducts}
          getMoreProducts={getMoreProducts}
          loading={loading}
          hasMore={hasMore}
        />
        <button type="button" className="yellow-button" onClick={() => goToCart()}>
          Ver Carrito
        </button>
      </div>
    </Page>
  );
}

export default Buy;
