/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import './Slider.css';
import Slider from 'react-slick';

import {
  BUY_TYPE_SOCIO,
  BUY_TYPE_RAPPI,
  BUY_TYPE_CORNERSHOP,
  BUY_TYPE_UBEREATS,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_RAPPI_TURBO,
  BUY_TYPE_GOODMEAL,
  BUY_TYPE_RAPPI_PAYLESS,
} from '../../utils/const';

import { addToCart } from '../../actions/customerActions';
import thousandSeparator from '../../utils/thousandSeparator';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import { getPrice, getSalePrices } from '../../utils/getPrice';

const maxLengthString = (text) => {
  if (text.length > 30) {
    return `${text.substring(0, 40)}...`;
  }

  return text;
};

function ProductSlider({ products, enqueueSnackbar, closeSnackbar }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [amounts, setAmounts] = useState({});
  const customerType = useSelector((state) => state.customer.type);
  const store = useSelector((state) => state.account.user.Store);
  let priceList = store.price_list_id;

  if (customerType === BUY_TYPE_RAPPI) {
    priceList = store.rappi_price_list_id;
  } else if (customerType === BUY_TYPE_RAPPI_PAYLESS) {
    priceList = store.rappi_price_list_id;
  } else if (customerType === BUY_TYPE_RAPPI_TURBO) {
    priceList = store.rappi_turbo_price_list_id;
  } else if (customerType === BUY_TYPE_PEDIDOSYA) {
    priceList = store.pedidosya_price_list_id;
  } else if (customerType === BUY_TYPE_UBEREATS) {
    priceList = store.ubereats_price_list_id;
  } else if (customerType === BUY_TYPE_CORNERSHOP) {
    priceList = store.ubereats_price_list_id;
  } else if (customerType === BUY_TYPE_SOCIO) {
    priceList = store.club_price_list_id;
  } else if (customerType === BUY_TYPE_GOODMEAL) {
    priceList = store.goodmeal_price_list_id;
  }

  const cart = useSelector((state) => state.customer.cart);
  const [totalAmounts, setTotalAmounts] = useState(Object.keys(cart).map((key) => ({
    [cart[key].id]: cart[key].amount,
  })));
  const dispatch = useDispatch();

  const addToProduct = () => {
    const newAmount = amounts[currentProduct.id] ? amounts[currentProduct.id] + 1 : 2;
    setAmounts({ ...amounts, [currentProduct.id]: newAmount });
  };

  const subtractFromProduct = () => {
    // eslint-disable-next-line no-nested-ternary
    const newAmount = amounts[currentProduct.id]
      ? (amounts[currentProduct.id] - 1 > 0
        ? amounts[currentProduct.id] - 1 : 1)
      : 1;
    setAmounts({ ...amounts, [currentProduct.id]: newAmount });
  };

  const checkStock = (amount) => {
    const amountInCart = totalAmounts[currentProduct.id] || 0;
    if ((amountInCart + amount) <= currentProduct.stock) {
      return true;
    }

    return false;
  };

  const addProductToCart = (amount) => {
    if (checkStock(amount)) {
      // Get prices
      const prices = getSalePrices(currentProduct, store, customerType);
      // Get max quantities
      const maxQuantities = {
        clubGeneral: {
          min: 0,
          max: currentProduct.customer_limit_discount,
          disc: currentProduct.customer_discount,
        },
        bulk: {
          min: currentProduct.bulk_amount,
          max: 99999,
        },
        sellout: {
          min: 0,
          max: currentProduct.sellout.max_quantity,
        },
        recommended: {
          min: 0,
          max: currentProduct.max_recommended_quantity,
        },
        offers: {
          min: 0,
          max: 0,
        },
      };
      dispatch(addToCart(currentProduct, amount, prices, maxQuantities));
      setAmounts({ ...amounts, [currentProduct.id]: 1 });
      const amountInCart = totalAmounts[currentProduct.id] || 0;
      setTotalAmounts({ ...amounts, [currentProduct.id]: amountInCart + amount });
      const key = enqueueSnackbar(`${amount} ${maxLengthString(currentProduct.name)} en carro!`, {
        variant: 'success',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      const key = enqueueSnackbar(`${maxLengthString(currentProduct.name)} no tiene stock suficiente!`, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentProduct(products[event.currentTarget.value]);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentProduct(null);
  };

  const createChildren = () => products.map((p, i) => {
    const price = getPrice(p.prices, priceList) || 0;
    const bulkPrice = getPrice(p.prices, store.bulk_price_list_id) || 0;
    return (
      p && priceList && (
        <div>
          <button
            type="button"
            value={i}
            key={i}
            className={`popular-product ${(price && bulkPrice) ? 'yellow-button' : 'red-button'}`}
            onClick={(e) => handleClick(e)}
          >
            <p>{maxLengthString(p.name)}</p>
            <p>
              $
              {thousandSeparator(price, '.')}
              {' - '}
              <span className={(price && bulkPrice) ? 'bulk-price-text' : 'bulk-price-text-reverse'}>
                M: $
                {thousandSeparator(bulkPrice, '.')}
              </span>
            </p>
          </button>
        </div>
      )
    );
  });

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  return (
    <div className="popular-products-slider">
      <h2>Productos Destacados</h2>
      <div className="slider-container">
        <Slider
          {...settings}
        >
          {createChildren()}
        </Slider>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        >
          {anchorEl && currentProduct ? (
            <div className="popover">
              <div className="amount-cell">
                <button type="button" onClick={() => subtractFromProduct()}>-</button>
                <div className="amount-number">{amounts[currentProduct.id] ? amounts[currentProduct.id] : 1}</div>
                <button type="button" onClick={() => addToProduct()}>+</button>
              </div>
              <button className="yellow-button" type="button" onClick={() => addProductToCart(amounts[currentProduct.id] ? amounts[currentProduct.id] : 1)}>
                Agregar
              </button>
            </div>
          ) : null}
        </Popover>
      </div>
    </div>
  );
}

export default ProductSlider;
