/* eslint-disable no-nested-ternary */
import React from 'react';

import { useSelector } from 'react-redux';
import './Footer.css';

function FooterProgress({ progressStep, backRef }) {
  const customerType = useSelector((state) => state.customer.type);

  if (!progressStep) {
    return null;
  }
  return (
    <>
      <div className="footer-container">
        {window.location.pathname !== '/pago-final' ? (
          <>
            <button type="button" onClick={() => { window.location.href = backRef; }}>
              <img src="/img/back-button-arrow.png" alt="atras" />
            </button>
            <div className="tabs">
              <button
                type="button"
                onClick={() => { window.location.href = '/ingreso'; }}
                className={progressStep !== 'none' ? (progressStep === 'ingreso' ? 'yellow-button' : 'transparent-button') : 'invisible'}
              >
                Ingreso
              </button>
              {customerType === 'socio' ? (
                <button
                  type="button"
                  onClick={['pago', 'compra', 'perfil'].includes(progressStep) ? () => { window.location.href = '/perfil'; } : null}
                  className={['pago', 'compra', 'perfil'].includes(progressStep) ? progressStep === 'perfil' ? 'yellow-button' : 'transparent-button' : 'invisible'}
                >
                  Perfil
                </button>
              ) : null}

              <button
                type="button"
                onClick={['pago', 'compra'].includes(progressStep) ? () => { window.location.href = '/compra'; } : null}
                className={['pago', 'compra'].includes(progressStep) ? progressStep === 'compra' ? 'yellow-button' : 'transparent-button' : 'invisible'}
              >
                Compra
              </button>
            </div>
            <button type="button" onClick={() => { window.location.href = '/inicio'; }}>
              <img src="/img/home-button.png" alt="inicio" />
            </button>
          </>
        ) : ('')}
      </div>
    </>
  );
}

export default FooterProgress;
