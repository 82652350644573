import jwtDecode from 'jwt-decode';
import axios from '../utils/axios';

class AuthService {
  static setAxiosInterceptors({ onLogout }) {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          AuthService.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      },
    );
  }

  static handleAuthentication() {
    const accessToken = AuthService.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (AuthService.isValidToken(accessToken)) {
      AuthService.setSession(accessToken);
    } else {
      AuthService.setSession(null);
    }
  }

  static loginWithRutAndPassword(rut, password) {
    return new Promise((resolve, reject) => {
      axios.post('/api/login', { rut, password })
        .then((response) => {
          if (response.data.data.user) {
            AuthService.setSession(response.data.data.accessToken, response.data.data.user.id);
            const { user } = response.data.data;
            if (window.AppPagoSDK) {
              if (!user.Store?.tbk_smart_pos_active) {
                const error = {
                  errorMessage: 'Este local no está habilitado para usar SmartPOS',
                };
                reject(error);
              } else {
                resolve(user);
              }
            } else if (user.Store.is_franchise) {
              if (!user.Store.bsale_api_token_name) {
                const error = {
                  errorMessage: 'Este local no tiene el token de Bsale configurado',
                };
                reject(error);
              } else if (!user.Store.rdc_rut || !user.Store.rdc_token) {
                const error = {
                  errorMessage: 'Este local no tiene el rut o el token de Redelcom configurado',
                };
                reject(error);
              } else {
                resolve(user);
              }
            } else {
              resolve(user);
            }
          } else {
            reject(response.data.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // static loginInWithToken() {
  //   return new Promise((resolve) => {
  //     const userId = localStorage.getItem('userId');
  //     axios.get(`/api/admins/${userId}`)
  //       .then((response) => {
  //         if (response.data.data.admin) {
  //           resolve(response.data.data.admin);
  //         } else {
  //           resolve(null);
  //         }
  //       })
  //       .catch(() => {
  //         resolve(null);
  //       });
  //   });
  // }

  static logout() {
    AuthService.setSession(null);
    return new Promise((resolve, reject) => {
      axios.get('/api/logout')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  static setSession(accessToken, userId) {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
    if (userId) {
      localStorage.setItem('userId', userId);
    }
  }

  static getAccessToken() { return localStorage.getItem('accessToken'); }

  static isValidToken(accessToken) {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  static isAuthenticated() { return !!AuthService.getAccessToken(); }

  static getDecodedToken() {
    const token = AuthService.getAccessToken();
    if (!token) return null;
    if (!AuthService.isValidToken(token)) return null;
    return jwtDecode(token);
  }
}

export default AuthService;
