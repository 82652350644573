/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  SET_STORE,
  SET_MACHINE_NUMBER,
  RESET_MACHINE_NUMBER,
  SET_EXPRESS_ORDERS,
  SET_EAN_FROM_CAMERA,
} from '../actions/sessionActions';

const initialState = {
  store: null,
  machineNumber: null,
  eanFromCamera: '',
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE: {
      const { storeData } = action.payload;

      return produce(state, (draft) => {
        draft.store = storeData;
      });
    }

    case SET_MACHINE_NUMBER: {
      const { number } = action.payload;

      return produce(state, (draft) => {
        draft.machineNumber = number;
      });
    }

    case RESET_MACHINE_NUMBER: {
      return produce(state, (draft) => {
        draft.machineNumber = null;
      });
    }

    case SET_EXPRESS_ORDERS: {
      const { orders } = action.payload;

      return produce(state, (draft) => {
        draft.expressOrders = orders;
      });
    }

    case SET_EAN_FROM_CAMERA: {
      const { ean } = action.payload;

      return produce(state, (draft) => {
        draft.eanFromCamera = ean;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
