import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  CircularProgress,
  Popover,
} from '@material-ui/core';
import thousandSeparator from '../../../utils/thousandSeparator';
import saveOrder from './CartSubmitRules';

import {
  PAYMENT_TYPE_METHOD,
  DOCUMENT_TYPE_FACTURA,
  PAYMENT_TYPE_FREE,
} from '../../../utils/const';
import { setPaymentLoading } from '../../../actions/customerActions';

const CartSubmit = ({
  documentType,
  cart,
  store,
  customer,
  user,
  coupon,
  buyType,
  payments,
  total,
  recommendedWatchTime,
}) => {
  const dispatch = useDispatch();
  const recommendedProduct = useSelector((state) => state.customer.recommended_product);
  const { machineNumber } = useSelector((state) => state.session);
  const {
    points,
    all_discounts: allDiscounts,
  } = useSelector((state) => state.customer);

  const history = useHistory();
  const [submit, setSubmit] = useState(false);
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [complete, setComplete] = useState(false);

  const handleSubmit = async () => {
    if (submit) {
      return;
    }
    setSubmit(true);
    const totalAmount = payments.reduce((p, c) => p + c.amount, 0);

    const {
      offersDiscount,
      couponDiscount,
    } = allDiscounts || {};

    if (totalAmount < 50 && !(offersDiscount || couponDiscount)) {
      const key = enqueueSnackbar('Pagos deben ser mayores a 50 CLP', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      setSubmit(false);
      return;
    }

    if (Object.keys(cart).length === 0) {
      const key = enqueueSnackbar('Debe agregar al menos 1 producto', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      setSubmit(false);
      return;
    }

    if (payments.length < 1) {
      const key = enqueueSnackbar('Debe agregar al menos 1 método de pago', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      setSubmit(false);
      return;
    }

    if (!complete) {
      const key = enqueueSnackbar('Carrito Incompleto', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      setSubmit(false);
      return;
    }

    if (totalAmount !== total) {
      const key = enqueueSnackbar('Pagos no coinciden con total', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      setSubmit(false);
      return;
    }

    let formatedPayments = payments.map((p) => ({
      payment_type: p.payment_type,
      amount: p.amount,
    }));
    // Check for amount 0 orders
    if (totalAmount === 0) {
      formatedPayments = [{ payment_type: PAYMENT_TYPE_FREE, amount: 0 }];
    }

    const recommendedProductIds = recommendedProduct.map((rp) => rp.id);

    const success = await saveOrder(
      false,
      Object.keys(cart).map((id) => ({
        amount: cart[id].amount,
        id: cart[id].product.id,
      })),
      documentType,
      store,
      customer,
      user,
      coupon ? coupon.coupon.code : null,
      buyType,
      formatedPayments,
      machineNumber,
      null,
      enqueueSnackbar,
      closeSnackbar,
      dispatch,
      null,
      recommendedProductIds,
      recommendedWatchTime,
    );

    setSubmit(false);
    if (success) {
      if (documentType === DOCUMENT_TYPE_FACTURA) {
        window.location.assign('/factura');
        // history.push('/factura');
      } else {
        history.push('/pago-final');
      }
    }
  };

  const handleSave = async () => {
    if (submit) {
      return;
    }
    setSubmit(true);
    const totalAmount = payments.reduce((p, c) => p + c.amount, 0);
    if (totalAmount < 50) {
      const key = enqueueSnackbar('Pagos deben ser mayores a 50 CLP', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }

    if (Object.keys(cart).length === 0) {
      const key = enqueueSnackbar('Debe agregar al menos 1 producto', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }

    if (payments.length < 1) {
      const key = enqueueSnackbar('Debe agregar al menos 1 método de pago', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }

    if (!complete) {
      const key = enqueueSnackbar('Carrito Incompleto', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }

    if (totalAmount !== total) {
      const key = enqueueSnackbar('Pagos no coinciden con total', {
        variant: 'warning',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      return;
    }

    const recommendedProductIds = recommendedProduct.map((rp) => rp.id);

    await saveOrder(
      true,
      Object.keys(cart).map((id) => ({
        amount: cart[id].amount,
        id: cart[id].product.id,
      })),
      documentType,
      store,
      customer,
      user,
      coupon ? coupon.coupon.code : null,
      buyType,
      payments.map((p) => ({ payment_type: p.payment_type, amount: p.amount })),
      machineNumber,
      null,
      enqueueSnackbar,
      closeSnackbar,
      dispatch,
      null,
      recommendedProductIds,
    );

    setSubmit(false);
  };

  const showAmountsError = () => {
    const paymentMethods = payments.filter((p) => p.amount === null);
    if (paymentMethods.length > 0) {
      const p = paymentMethods[0];
      return `Falta ingresar un monto de ${p.payment_type}`;
    }

    const paymentsTotal = payments.reduce((p, c) => p + c.amount, 0);
    const difference = paymentsTotal - total;

    if (difference === 0) {
      return null;
    }

    if (difference < 0) {
      return `Faltan $${thousandSeparator(difference * -1, '.')}`;
    }

    if (difference > 0) {
      return `Sobran $${thousandSeparator(difference, '.')}`;
    }

    return null;
  };

  const popoverContent = () => (
    <div className="final-popover-container">
      <div className="popover-info-message">
        <h3>
          Usted tiene aun puntos sin utilizar
        </h3>
        <p> Equivale a un descuento de: </p>
        <h3>
          $
          {' '}
          {thousandSeparator(points, '.')}
        </h3>
        <p> ¿Esta seguro de seguir la compra?</p>
      </div>
      <div className="entry-confirmation-buttons">
        <button
          className="accept-entry-button"
          type="submit"
          onClick={() => handleSubmit()}
        >
          Aceptar
        </button>
        <button
          className="cancel-cart-button"
          type="submit"
          onClick={() => setOpen(false)}
        >
          Cancelar
        </button>
      </div>
    </div>
  );

  const payMethodFlag = () => !payments.some((p) => p.payment_type === PAYMENT_TYPE_METHOD);

  const submitButtonClass = () => {
    if (complete) {
      return 'yellow-button';
    }

    if (submit) {
      return 'yellow-button';
    }

    return 'yellow-button deactivated';
  };

  const cartComplete = () => {
    if (!documentType) {
      return false;
    }

    if (!payMethodFlag()) {
      return false;
    }

    if (showAmountsError()) {
      return false;
    }

    return true;
  };

  const renderSubmitButton = () => {
    dispatch(setPaymentLoading(false));
    if (!submit) {
      return (<p style={{ margin: '0' }}>REALIZAR COMPRA</p>);
    }

    return (
      <div>
        <CircularProgress style={{ color: 'black' }} size={15} />
      </div>
    );
  };

  useEffect(() => {
    const c = cartComplete();
    setComplete(c);
  }, [payments, documentType, total]);

  const pointsMiddleWare = () => {
    const pointPayment = payments.find((p) => p.payment_type !== 'WALLET');
    if (pointPayment && points > 0) setOpen(true);
    else if (!pointPayment && (total > points)) {
      const key = enqueueSnackbar('Puntos insuficientes', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else handleSubmit();
  };

  return (
    <>
      <div className="cart-error-message">
        {!documentType && (<p>Falta elegir tipo de documento</p>)}
        {!payMethodFlag() && (<p>Falta elegir metodo de pago</p>)}
        <p>
          {showAmountsError()}
        </p>
      </div>
      <div className="cart-confirmation-buttons">
        <button
          type="button"
          className={submitButtonClass()}
          onClick={() => pointsMiddleWare()}
          disabled={!complete}
        >
          {renderSubmitButton()}
        </button>
        <button
          disabled={!complete}
          type="button"
          className="save-order-button"
          onClick={() => handleSave()}
        >
          {customer.order_id ? 'Actualizar Pedido' : 'Guardar Pedido'}
        </button>
      </div>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {popoverContent()}
      </Popover>
    </>
  );
};

export default CartSubmit;
