/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode';
import axios from '../../utils/axios';
import { formatTimeLeft } from '../../utils/time';
import { STATUS_APROBADO } from '../../utils/const';
import thousandSeparator from '../../utils/thousandSeparator';
// import {
//   STATUS_APROBADO,
//   STATUS_EXPIRADO,
//   STATUS_PENDIENTE,
//   STATUS_RECHAZADO,
// } from '../../utils/const';

const RedpayPayment = ({
  amount,
  orderId,
  handlePayed,
}) => {
  const [redpayPaymentId, setRedpayPaymentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(1);
  const [fetchingData, setFetchingData] = useState(false);

  const { user } = useSelector((state) => state.customer);

  const cancel = async (
    msg = 'Pago expirado o rechazado. Intentar nuevamente.',
  ) => {
    try {
      setLoading(true);
      if (redpayPaymentId) {
        await axios.post('/api/redpay-payment/revoke', { redpayPaymentId });
        handlePayed(msg);
      }
    } catch {
      handlePayed('Hubo un error, intentar nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  const init = async () => {
    setLoading(true);
    try {
      const redpayPayment = await axios.post('/api/redpay-payments/new', {
        amount,
        orderId,
        customerId: user?.id,
      });

      setLoading(false);

      if (redpayPayment.data.success) {
        // eslint-disable-next-line camelcase
        const { token_url, token_uuid, token_number } = redpayPayment.data.data;
        setRedpayPaymentId(token_uuid);

        // create QR
        // eslint-disable-next-line camelcase
        document.getElementById('QRNumber').innerHTML = `O usa el código: ${token_number}`;
        const canvas = document.getElementById('redpayCanvas');
        QRCode.toCanvas(canvas, token_url, { scale: 4, errorCorrectionLevel: 'H' });
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#ffffff';
        const imgDim = { width: 45, height: 45 };
        ctx.fillRect(canvas.width / 2 - imgDim.width / 2,
          canvas.height / 2 - imgDim.height / 2, imgDim.width, imgDim.height);
        const imageObj = new Image();
        imageObj.src = 'https://storage.googleapis.com/liquidos-public/RP_COLOR_V.svg';
        imageObj.onload = () => {
          ctx.drawImage(imageObj, canvas.width / 2 - imgDim.width / 2,
            canvas.height / 2 - imgDim.height / 2, imgDim.width, imgDim.height);
        };
      } else {
        await cancel();
      }
    } catch {
      await cancel();
    }
  };

  useEffect(() => {
    const getRedpayPaymentByOrderId = async () => {
      if (!fetchingData) {
        try {
          setFetchingData(true);
          const redpayPayment = await axios.get(`/api/redpay-payment/order/${orderId}`);
          const paymentStatus = redpayPayment.data.status;
          if (paymentStatus === STATUS_APROBADO) {
            handlePayed(null);
            setCounter(300);
          } else if (paymentStatus && paymentStatus !== STATUS_APROBADO) {
            cancel('Pago rechazado');
            setCounter(300);
          }
        } catch (err) {
          cancel();
          setCounter(300);
        } finally {
          setFetchingData(false);
        }
      }
    };

    // Set up the interval
    const intervalId = setInterval(() => {
      getRedpayPaymentByOrderId();
      setCounter((prevCounter) => prevCounter + 3);
    }, 3000);

    // Clear the interval if the counter reaches 300 seconds or component unmounts
    if (counter >= 300 && redpayPaymentId) {
      clearInterval(intervalId);
      cancel();
    }

    return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
  }, [counter, redpayPaymentId]);

  useEffect(() => {
    init();
  }, [orderId]);

  return (
    <>
      {loading ? (
        <p style={{ textAlign: 'center' }}>
          <b>Cargando...</b>
        </p>
      ) : (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          <h1>Pago REDPAY</h1>
          <h2>{`$${thousandSeparator(amount, '.')}`}</h2>
          <h3>
            Escanea el QR con tu smartphone
          </h3>
          <h2>
            {formatTimeLeft(301 - counter)}
          </h2>
          <canvas id="redpayCanvas" />
          <p id="QRNumber" style={{ textAlign: 'center', color: 'white' }} />
          <button
            type="button"
            onClick={() => cancel('Pago cancelado')}
            className="reset-qr"
          >
            Cancelar
          </button>
        </div>
      )}
    </>
  );
};

export default RedpayPayment;
