export const SET_STORE = '@session/set-store';
export const SET_MACHINE_NUMBER = '@session/set-machine-number';
export const RESET_MACHINE_NUMBER = '@session/reset-machine-number';
export const SET_EXPRESS_ORDERS = '@session/express-orders';
export const SET_EAN_FROM_CAMERA = '@session/set-ean-from-camera';

export function setStore(storeData) {
  return (dispatch) => dispatch({
    type: SET_STORE,
    payload: {
      storeData,
    },
  });
}

export function setMachineNumber(number) {
  return (dispatch) => dispatch({
    type: SET_MACHINE_NUMBER,
    payload: {
      number,
    },
  });
}

export function resetMachineNumber() {
  return (dispatch) => dispatch({
    type: RESET_MACHINE_NUMBER,
  });
}

export function setExpressOrders(orders) {
  return (dispatch) => dispatch({
    type: SET_EXPRESS_ORDERS,
    payload: {
      orders,
    },
  });
}

export function setEANFromCamera(ean) {
  return (dispatch) => dispatch({
    type: SET_EAN_FROM_CAMERA,
    payload: {
      ean,
    },
  });
}

export function register() {
  return true;
}
