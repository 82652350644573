import React from 'react';
import PaymentForm from '../../../components/Payments/PaymentForm';

const CartPaymentMethod = ({
  points,
  total,
  payments,
  // getPromotions,
}) => {
  const renderPayments = () => payments.map((p) => (
    <PaymentForm
      points={points}
      total={total}
      payment={p}
      payments={payments}
      // getPromotions={getPromotions}
    />
  ));

  return (
    <div className="button-holder">
      {renderPayments()}
    </div>
  );
};

export default CartPaymentMethod;
