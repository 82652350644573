import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import './Payments.css';
import NumberFormat from 'react-number-format';

import { useSnackbar } from 'notistack';
import {
  updatePayment,
  addPayment,
  removePayment,
  resetCoupon,
  resetCouponDiscount,
} from '../../actions/customerActions';

import {
  PAYMENT_TYPE_METHOD,
  PAYMENT_TYPE_EFECTIVO,
  PAYMENT_TYPE_TARJETA_DEBITO,
  PAYMENT_TYPE_TARJETA_CREDITO,
  PAYMENT_TYPE_TRANSFERENCIA,
  PAYMENT_TYPE_WALLET,
  PAYMENT_TYPE_OTRO,
  PAYMENT_TYPE_MACH,
  PAYMENT_TYPE_REDPAY,
  // PAYMENT_TYPE_NC,
} from '../../utils/const';

const maxPaymentAmount = (payments, payment, total) => {
  const otherPayments = payments.filter((p) => p.id !== payment.id);
  const otherTotal = otherPayments.reduce((p, c) => p + c.amount, 0);
  return total - otherTotal;
};

function PaymentForm({
  points,
  total,
  payment,
  payments,
  // getPromotions,
}) {
  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [paymentMethod, setPaymentMethod] = useState(payment.payment_type);
  const [open, setOpen] = useState(false);
  const coupon = useSelector((state) => state.customer.coupon);
  const store = useSelector((state) => state.session.store);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = async () => {
    if (payments.length > 1) {
      // Check if already are a wallet payment
      const walletPayment = payments.find((p) => p.payment_type === PAYMENT_TYPE_WALLET);
      if (walletPayment && paymentMethod === PAYMENT_TYPE_WALLET) {
        const key = enqueueSnackbar('No se puede añadir nuevamente un pago por puntos', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          onClick: () => {
            closeSnackbar(key);
          },
        });
        return;
      }

      if (!walletPayment && paymentMethod !== PAYMENT_TYPE_WALLET) {
        const key = enqueueSnackbar('No se pueden agregar más métodos de pago, salvo pago con puntos', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          onClick: () => closeSnackbar(key),
        });
        return;
      }

      dispatch(updatePayment({
        id: payment.id,
        payment_type: paymentMethod,
        amount: payment.amount,
      }));
    } else if (paymentMethod === PAYMENT_TYPE_WALLET) {
      dispatch(updatePayment({
        id: payment.id,
        payment_type: paymentMethod,
        amount: points - total < 0 ? points : total,
      }));
    } else {
      dispatch(updatePayment({
        id: payment.id,
        payment_type: paymentMethod,
        amount: total,
      }));
    }
    const paymentsNewArray = [];
    paymentsNewArray.push({
      id: payment.id,
      payment_type: paymentMethod,
      amount: total,
    });
    for (let i = 0; i < payments.length; i += 1) {
      if (payments[i].payment_type !== payment.payment_type) {
        paymentsNewArray.push(payments[i]);
      }
    }
    // await getPromotions(paymentsNewArray);
    setOpen(false);
  };

  const handleChange = (valueObj, pay) => {
    const { value } = valueObj;

    let maxPosible = 0;
    if (pay.payment_type === PAYMENT_TYPE_WALLET) {
      maxPosible = Math.min(points, maxPaymentAmount(payments, pay, total));
    } else {
      maxPosible = maxPaymentAmount(payments, pay, total);
    }

    const intValue = parseInt(value, 10);
    const finalValue = Math.min(intValue, maxPosible);

    dispatch(updatePayment({
      id: pay.id,
      payment_type: pay.payment_type,
      amount: finalValue || 0,
    }));
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    // Cuando cambio el medio de pago, se debe resetear el valor del cupón
    dispatch(resetCoupon());
    dispatch(resetCouponDiscount());
    if (coupon) {
      const key = enqueueSnackbar('Se ha borrado el cupón', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  };

  const popoverContent = () => (
    <div className="cart-popover-container">
      <div className="cart-type-container">
        <div className="radio-input-container">
          <label htmlFor="credito">
            <input
              id="credito"
              type="radio"
              value={PAYMENT_TYPE_TARJETA_CREDITO}
              checked={paymentMethod === PAYMENT_TYPE_TARJETA_CREDITO}
              onChange={() => handlePaymentMethodChange(PAYMENT_TYPE_TARJETA_CREDITO)}
            />
            Tarjeta de Credito
          </label>
        </div>
        <div className="radio-input-container">
          <label htmlFor="debito">
            <input
              id="debito"
              type="radio"
              value={PAYMENT_TYPE_TARJETA_DEBITO}
              checked={paymentMethod === PAYMENT_TYPE_TARJETA_DEBITO}
              onChange={() => handlePaymentMethodChange(PAYMENT_TYPE_TARJETA_DEBITO)}
            />
            Tarjeta de Debito
          </label>
        </div>
        <div className="radio-input-container">
          <label htmlFor="efectivo">
            <input
              id="efectivo"
              type="radio"
              value={PAYMENT_TYPE_EFECTIVO}
              checked={paymentMethod === PAYMENT_TYPE_EFECTIVO}
              onChange={() => handlePaymentMethodChange(PAYMENT_TYPE_EFECTIVO)}
            />
            Efectivo
          </label>
        </div>
        <div className="radio-input-container">
          <label htmlFor="transferencia">
            <input
              id="transferencia"
              type="radio"
              value={PAYMENT_TYPE_TRANSFERENCIA}
              checked={paymentMethod === PAYMENT_TYPE_TRANSFERENCIA}
              onChange={() => handlePaymentMethodChange(PAYMENT_TYPE_TRANSFERENCIA)}
            />
            Transferencia Bancaria
          </label>
        </div>
        <div className="radio-input-container">
          <label htmlFor="wallet">
            <input
              id="wallet"
              type="radio"
              value={PAYMENT_TYPE_WALLET}
              checked={paymentMethod === PAYMENT_TYPE_WALLET}
              onChange={() => handlePaymentMethodChange(PAYMENT_TYPE_WALLET)}
            />
            Puntos / Wallet
          </label>
        </div>
        { store?.mach_active && (
        <div className="radio-input-container">
          <label htmlFor="Mach">
            <input
              id="Mach"
              type="radio"
              value={PAYMENT_TYPE_MACH}
              checked={paymentMethod === PAYMENT_TYPE_MACH}
              onChange={() => handlePaymentMethodChange(PAYMENT_TYPE_MACH)}
            />
            MACH
          </label>
        </div>
          )}
        { store?.redpay_active && (
        <div className="radio-input-container">
          <label htmlFor="RedPay">
            <input
              id="RedPay"
              type="radio"
              value={PAYMENT_TYPE_REDPAY}
              checked={paymentMethod === PAYMENT_TYPE_REDPAY}
              onChange={() => handlePaymentMethodChange(PAYMENT_TYPE_REDPAY)}
            />
            RedPay
          </label>
        </div>
          )}
        <div className="radio-input-container">
          <label htmlFor="otro">
            <input
              id="otro"
              type="radio"
              value={PAYMENT_TYPE_OTRO}
              checked={paymentMethod === PAYMENT_TYPE_OTRO}
              onChange={() => handlePaymentMethodChange(PAYMENT_TYPE_OTRO)}
            />
            Otro
          </label>
        </div>
        {/* <div className="radio-input-container">
          <label htmlFor="nota-credito">
            <input
              id="nota-credito"
              type="radio"
              value={PAYMENT_TYPE_NC}
              checked={paymentMethod === PAYMENT_TYPE_NC}
              onChange={() => handlePaymentMethodChange(PAYMENT_TYPE_NC)}
            />
            Nota de crédito
          </label>
        </div> */}
      </div>
      <div className="cart-popup-confirmation-buttons">
        <button
          className="accept-cart-button"
          type="submit"
          onClick={() => handleAccept()}
        >
          Aceptar
        </button>
        <button
          className="cancel-cart-button"
          type="submit"
          onClick={() => handleClose()}
        >
          Cancelar
        </button>
      </div>
    </div>
  );

  const onMethodClick = () => {
    setOpen(true);
  };

  const onAddClick = () => {
    if (payments.length >= 2) {
      const key = enqueueSnackbar('No se pueden agregar más métodos de pago', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        onClick: () => closeSnackbar(key),
      });
      return;
    }
    dispatch(addPayment({
      payment_type: PAYMENT_TYPE_METHOD,
      amount: 0,
    }));
  };

  const onRemoveClick = () => {
    dispatch(removePayment(payment));
  };

  return (
    <div className="payment-row">
      <button
        type="button"
        className="transparent-button"
        onClick={(e) => onMethodClick(e)}
      >
        {payment.payment_type}
      </button>
      {payments.length > 1 && (
        <NumberFormat
          className="money-input"
          decimalSeparator=","
          thousandSeparator="."
          displayType="number"
          value={payment.amount}
          onValueChange={(value) => handleChange(value, payment)}
        />
      )}
      {payment !== 'Metodo de pago' && (
        <button
          type="button"
          className="add-payment-button"
          onClick={(e) => onAddClick(e)}
        >
          +
        </button>
      )}
      {payments.length > 1 && (
        <button
          type="button"
          className="remove-payment-button"
          onClick={(e) => onRemoveClick(e)}
        >
          &times;
        </button>
      )}
      <Popover
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {popoverContent()}
      </Popover>
    </div>
  );
}

export default PaymentForm;
