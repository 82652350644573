import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import axios from '../../../utils/axios';
import './Change.css';

import {
  PAYMENT_TYPE_EFECTIVO,
  PAYMENT_TYPE_TARJETA_DEBITO,
  PAYMENT_TYPE_TARJETA_CREDITO,
  PAYMENT_TYPE_TRANSFERENCIA,
  PAYMENT_TYPE_OTRO,
} from '../../../utils/const';

function ChangePayMethod({ step, payments, setPayments }) {
  const [current, setCurrent] = useState();
  const [open, setOpen] = useState(false);
  const applyBulk = useSelector((state) => state.customer.apply_bulk);

  const acceptChange = async () => {
    if (!current) return;

    const newPayments = [...payments];
    newPayments[step].paymentType = String(current).toUpperCase();
    const result = await axios.patch(`/api/payments/${newPayments[step].id}`, {
      type: newPayments[step].paymentType,
    });

    if (result.status !== 200) return;

    const returnedPayment = result.data?.data?.payment;

    // If the payment returned has a different id, replace de current payment with the new one
    // This occurs when the current payment has an error message
    if (returnedPayment && newPayments[step].id !== returnedPayment.id) {
      newPayments[step] = {
        amount: returnedPayment.amount,
        deposit_id: null,
        id: returnedPayment.id,
        next: false,
        order_id: returnedPayment.order_id,
        payed: false,
        paymentType: returnedPayment.type,
        rdc_transaction_id: returnedPayment.rdc_transaction_id,
        status: returnedPayment.status,
        type: returnedPayment.type,
      };
    }
    setPayments(newPayments);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeMethodClick = () => {
    setOpen(true);
  };

  const noBulkPayments = () => ((applyBulk) ? '' : (
    <>
      <div className="radio-input-container">
        <label htmlFor="credito">
          <input
            id="credito"
            type="radio"
            value={PAYMENT_TYPE_TARJETA_CREDITO}
            checked={current === PAYMENT_TYPE_TARJETA_CREDITO}
            onChange={() => setCurrent(PAYMENT_TYPE_TARJETA_CREDITO)}
          />
          Tarjeta de Credito
        </label>
      </div>
      <div className="radio-input-container">
        <label htmlFor="debito">
          <input
            id="debito"
            type="radio"
            value={PAYMENT_TYPE_TARJETA_DEBITO}
            checked={current === PAYMENT_TYPE_TARJETA_DEBITO}
            onChange={() => setCurrent(PAYMENT_TYPE_TARJETA_DEBITO)}
          />
          Tarjeta de Debito
        </label>
      </div>
      <div className="radio-input-container">
        <label htmlFor="otro">
          <input
            id="otro"
            type="radio"
            value={PAYMENT_TYPE_OTRO}
            checked={current === PAYMENT_TYPE_OTRO}
            onChange={() => setCurrent(PAYMENT_TYPE_OTRO)}
          />
          Otro
        </label>
      </div>
    </>
  ));

  return (
    <>
      <button type="button" className="change-button" onClick={() => changeMethodClick()}>
        Cambiar
      </button>
      <Popover
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div className="cart-popover-container">
          <div className="cart-type-container">
            { noBulkPayments() }
            <div className="radio-input-container">
              <label htmlFor="efectivo">
                <input
                  id="efectivo"
                  type="radio"
                  value={PAYMENT_TYPE_EFECTIVO}
                  checked={current === PAYMENT_TYPE_EFECTIVO}
                  onChange={() => setCurrent(PAYMENT_TYPE_EFECTIVO)}
                />
                Efectivo
              </label>
            </div>
            <div className="radio-input-container">
              <label htmlFor="transferencia">
                <input
                  id="transferencia"
                  type="radio"
                  value={PAYMENT_TYPE_TRANSFERENCIA}
                  checked={current === PAYMENT_TYPE_TRANSFERENCIA}
                  onChange={() => setCurrent(PAYMENT_TYPE_TRANSFERENCIA)}
                />
                Transferencia Bancaria
              </label>
            </div>
          </div>
          <div className="cart-popup-confirmation-buttons">
            <button
              className="accept-cart-button"
              type="submit"
              onClick={() => acceptChange()}
            >
              Aceptar
            </button>
            <button
              className="cancel-cart-button"
              type="submit"
              onClick={() => handleClose()}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Popover>
    </>
  );
}

export default ChangePayMethod;
